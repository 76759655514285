import { gql, useQuery } from '@apollo/client';
import { FRAG_TEMPLATE } from './fragments';

const QUERY_TEMPLATES = gql`
  query templates {
    templates {
      ...TemplateData
    }
  }
  ${FRAG_TEMPLATE}
`;

export const useQueryTemplates = (options = {}) => {
  const { data, loading, ...props } = useQuery(QUERY_TEMPLATES, options);
  return {
    templates: data?.templates,
    isLoading: loading || !data,
    ...props,
  };
};

const QUERY_TEMPLATE = gql`
  query template($id: ID!) {
    template(id: $id) {
      ...TemplateData
    }
  }
  ${FRAG_TEMPLATE}
`;

export const useQueryTemplate = (options = {}) => {
  const { data, loading, ...props } = useQuery(QUERY_TEMPLATE, options);
  return {
    template: data?.template,
    isLoading: loading || !data,
    ...props,
  };
};
