import dayjs from 'dayjs';

const History = ({ slot }: any) => {
  return (
    <>
      <p>
        {dayjs(slot.createdAt).format('le DD MMM YYYY à HH:mm')} : création de
        l'obligation
      </p>
      {(slot?.takeoverHistory?.data || []).map((history: any, i: number) => (
        <p key={i}>
          {history.type === 'request' &&
            `${dayjs(history.when).format('le DD MMM YYYY à HH:mm')} : ${
              history.from
            } a demandé le transfert à ${history.to} - "${history.reason}"`}
          {history.type === 'accept' &&
            `${dayjs(history.when).format('le DD MMM YYYY à HH:mm')} : ${
              history.to
            } a accepté la demande de transfert`}
          {history.type === 'reject' &&
            `${dayjs(history.when).format('le DD MMM YYYY à HH:mm')} : ${
              history.to
            } a rejeté la demande de transfert`}
          {history.type === 'cancel' &&
            `${dayjs(history.when).format('le DD MMM YYYY à HH:mm')} : ${
              history.from
            } a annulé la demande de transfert`}
        </p>
      ))}
    </>
  );
};

export default History;
