import { DeleteOutlined, PlusOutlined, SendOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  TimePicker,
  Typography,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import {
  useMutationAbsenceCancel,
  useMutationAbsenceRequest,
} from '../../gql/mutations/absences';
import { useQueryAbsences } from '../../gql/queries/absences';
import {
  renderTextChoices,
  searchTextChoices,
  sortDate,
  sortText,
} from '../../helpers/table';

const AbsencesStudent = () => {
  // List
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 20,
      showSizeChanger: true,
    },
    filters: {},
    columnKey: 'date',
    order: undefined,
  });
  const handleTableChange = useCallback(
    (pagination: any, filters: any, sorter: any) => {
      setTableParams({
        pagination,
        filters,
        ...sorter,
      });
    },
    [],
  );
  const { isLoading, absences, nAbsences, refetch } = useQueryAbsences({
    variables: {
      pageCurrent: tableParams.pagination.current,
      pageSize: tableParams.pagination.pageSize,
      orderBy: tableParams.columnKey,
      orderSort: tableParams.order,
      filters: tableParams.filters,
    },
  });
  // Create
  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(undefined);
  const [isCreateOpen, setIsCreateOpen] = useState<any>(false);
  const handleCreateOpen = useCallback(() => {
    setCreateLoading(false);
    setCreateError(undefined);
    setIsCreateOpen(true);
  }, []);
  const handleCreateClose = useCallback(() => {
    setCreateLoading(false);
    setCreateError(undefined);
    setIsCreateOpen(false);
  }, []);
  const mutationAbsenceRequest = useMutationAbsenceRequest();
  const handleCreate = useCallback(
    async ({ timelaps, ...values }: any) => {
      try {
        setCreateLoading(true);
        setCreateError(undefined);
        await mutationAbsenceRequest({
          ...values,
          timelaps: `${timelaps[0].format('HH:mm')}-${timelaps[1].format(
            'HH:mm',
          )}`,
        });
        await refetch();
        handleCreateClose();
        message.success("La demande d'absence a été envoyée.");
      } catch (err: any) {
        setCreateError(err.message);
      } finally {
        setCreateLoading(false);
      }
    },
    [handleCreateClose, mutationAbsenceRequest, refetch],
  );
  // Cancel
  const mutationAbsenceCancel = useMutationAbsenceCancel();
  const handleCancel = useCallback(
    async (absence: any) => {
      try {
        await mutationAbsenceCancel({
          id: absence.id,
        });
        await refetch();
        message.success("La demande d'absence a été supprimée.");
      } catch (err) {
        message.error("Une erreur s'est produite. Veuillez réessayer.");
      }
    },
    [mutationAbsenceCancel, refetch],
  );
  // Columns
  const columns = [
    {
      title: 'Date',
      key: 'date',
      render: (o: any) =>
        `${dayjs(o.date).format('DD MMM YYYY')} ${o.moment} (${o.timelaps})`,
      ...sortDate('date'),
    },
    {
      title: 'Justification',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Statut',
      dataIndex: 'status',
      key: 'status',
      ...renderTextChoices([
        { text: 'Demandée', value: 'request' },
        { text: 'Acceptée', value: 'accepted' },
        { text: 'Refusée', value: 'rejected' },
      ]),
      ...searchTextChoices('status', [
        { text: 'Demandée', value: 'request' },
        { text: 'Acceptée', value: 'accepted' },
        { text: 'Refusée', value: 'rejected' },
      ]),
      onFilter: undefined,
      ...sortText('status', 1),
    },
    {
      title: '',
      key: 'options',
      width: 100,
      render: (absence: any) => (
        <Space>
          {absence.status === 'request' && (
            <Popconfirm
              okText="Oui"
              onConfirm={() => handleCancel(absence)}
              title="Dernière chance. Êtes-vous sûr ?"
            >
              <Button size="small" icon={<DeleteOutlined />} danger>
                Annuler
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
  // Render
  return (
    <>
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Absences</Typography.Title>
        </Col>
        <Col>
          <Space wrap>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={handleCreateOpen}
            >
              Demander une absence
            </Button>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Table
        dataSource={absences}
        columns={columns}
        loading={isLoading}
        rowKey="id"
        bordered
        pagination={{
          ...tableParams.pagination,
          total: nAbsences,
        }}
        onChange={handleTableChange}
      />
      {/* Create */}
      <Modal
        title="Demander une absence"
        open={!!isCreateOpen}
        destroyOnClose
        footer={null}
        onCancel={handleCreateClose}
        width={800}
      >
        <Form
          initialValues={{
            date: null,
            moment: 'AM',
            timelaps: [dayjs(), dayjs()],
            reason: '',
          }}
          layout="vertical"
          onFinish={handleCreate}
          disabled={createLoading}
        >
          {createError && (
            <Alert
              type="error"
              message="Une erreur est survenue. Veuillez réessayer."
              banner
              className="error"
            />
          )}
          <Form.Item
            label="Jour"
            name="date"
            rules={[
              { required: true, message: 'Veuillez renseigner un jour.' },
            ]}
          >
            <DatePicker format="DD MMMM YYYY" />
          </Form.Item>
          <Form.Item
            label="Matinée / Après-midi"
            name="moment"
            rules={[
              { required: true, message: 'Veuillez renseigner un moment.' },
            ]}
          >
            <Select
              allowClear
              options={[
                { label: 'Matinée', value: 'AM' },
                { label: 'Après-midi', value: 'PM' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Heures"
            name="timelaps"
            rules={[
              { required: true, message: 'Veuillez renseigner les heures.' },
            ]}
          >
            <TimePicker.RangePicker format="HH:mm" />
          </Form.Item>
          <Form.Item
            label="Justification (pour l'équipe administrative)"
            name="reason"
            rules={[
              {
                required: true,
                message: 'Veuillez renseigner une justification.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Button htmlType="submit" icon={<SendOutlined />} type="primary">
            Envoyer
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default AbsencesStudent;
