import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SendOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  ColorPicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from 'antd';
import { useCallback, useState } from 'react';
import {
  useMutationCategoryCreate,
  useMutationCategoryDelete,
  useMutationCategoryUpdate,
} from '../../gql/mutations/categories';
import { useQueryCategories } from '../../gql/queries/categories';
import { searchText, sortText } from '../../helpers/table';

const Upsert = ({ category = {}, handleSubmit, loading, error }: any) => (
  <Form
    initialValues={{
      id: category.id || undefined,
      name: category.name || '',
      color: category.color || '#000000',
      priority: category.priority || 0,
      displayOrder: category.displayOrder || 0,
    }}
    layout="vertical"
    onFinish={handleSubmit}
    disabled={loading}
  >
    {error === '409' ? (
      <Alert
        type="error"
        message="Une catégorie avec le même nom existe déjà. Veuillez en choisir un autre."
        banner
        className="error"
      />
    ) : (
      error && (
        <Alert
          type="error"
          message="Une erreur est survenue. Veuillez réessayer."
          banner
          className="error"
        />
      )
    )}
    <Form.Item noStyle name="id" />
    <Form.Item
      label="Nom de la catégorie"
      name="name"
      rules={[{ required: true, message: 'Veuillez renseigner un nom.' }]}
    >
      <Input placeholder="URGENCE" />
    </Form.Item>
    <Form.Item
      label="Couleur de la catégorie"
      name="color"
      rules={[{ required: true, message: 'Veuillez renseigner une couleur.' }]}
      getValueFromEvent={(_, color) => color}
    >
      <ColorPicker />
    </Form.Item>
    <Form.Item
      label="Priorité lors de la réalisation des plannings (0 = plus prioritaire)"
      name="priority"
      rules={[
        {
          required: true,
          message: 'Veuillez renseigner un ordre de priorité.',
        },
      ]}
    >
      <InputNumber />
    </Form.Item>
    <Form.Item
      label="Ordre d'affichage dans les plannings (0 = plus haut)"
      name="displayOrder"
      rules={[
        {
          required: true,
          message: "Veuillez renseigner un ordre d'affichage.",
        },
      ]}
    >
      <InputNumber />
    </Form.Item>
    <Button htmlType="submit" icon={<SendOutlined />} type="primary">
      Envoyer
    </Button>
  </Form>
);

const CategoriesList = () => {
  // List
  const { isLoading, categories, refetch } = useQueryCategories();
  // Create
  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(undefined);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const handleCreateOpen = useCallback(() => {
    setCreateLoading(false);
    setCreateError(undefined);
    setIsCreateOpen(true);
  }, []);
  const handleCreateClose = useCallback(() => {
    setCreateLoading(false);
    setCreateError(undefined);
    setIsCreateOpen(false);
  }, []);
  const mutationCategoryCreate = useMutationCategoryCreate();
  const handleCreate = useCallback(
    async (values: any) => {
      try {
        setCreateLoading(true);
        setCreateError(undefined);
        await mutationCategoryCreate(values);
        await refetch();
        handleCreateClose();
        message.success('La catégorie a été créée.');
      } catch (err: any) {
        setCreateError(err.message);
      } finally {
        setCreateLoading(false);
      }
    },
    [handleCreateClose, mutationCategoryCreate, refetch],
  );
  // Update
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState(undefined);
  const [isUpdateOpen, setIsUpdateOpen] = useState(undefined);
  const handleUpdateOpen = useCallback((category: any) => {
    setUpdateLoading(false);
    setUpdateError(undefined);
    setIsUpdateOpen(category);
  }, []);
  const handleUpdateClose = useCallback(() => {
    setUpdateLoading(false);
    setUpdateError(undefined);
    setIsUpdateOpen(undefined);
  }, []);
  const mutationCategoryUpdate = useMutationCategoryUpdate();
  const handleUpdate = useCallback(
    async (values: any) => {
      try {
        setUpdateLoading(true);
        setUpdateError(undefined);
        await mutationCategoryUpdate(values);
        await refetch();
        handleUpdateClose();
        message.success('La catégorie a été mise à jour.');
      } catch (err: any) {
        setUpdateError(err.message);
      } finally {
        setUpdateLoading(false);
      }
    },
    [handleUpdateClose, mutationCategoryUpdate, refetch],
  );
  // Delete
  const mutationCategoryDelete = useMutationCategoryDelete();
  const handleDelete = useCallback(
    async (category: any) => {
      try {
        await mutationCategoryDelete({
          id: category.id,
        });
        await refetch();
        message.success('La catégorie a été supprimée.');
      } catch (err) {
        message.error("Une erreur s'est produite. Veuillez réessayer.");
      }
    },
    [mutationCategoryDelete, refetch],
  );
  // Columns
  const columns = [
    {
      title: 'Couleur',
      key: 'color',
      width: 100,
      render: ({ color }: any) => (
        <Tag color={color}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </Tag>
      ),
    },
    {
      title: 'Nom de la catégorie',
      dataIndex: 'name',
      key: 'name',
      ...sortText('name'),
      ...searchText('name'),
    },
    {
      title: 'Ordre de priorité',
      dataIndex: 'priority',
      key: 'priority',
      ...sortText('priority'),
      ...searchText('priority'),
    },
    {
      title: "Ordre d'affichage",
      dataIndex: 'displayOrder',
      key: 'displayOrder',
      ...sortText('displayOrder'),
      ...searchText('displayOrder'),
    },
    {
      title: '',
      key: 'options',
      width: 100,
      render: (category: any) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleUpdateOpen(category)}
          />
          <Popconfirm
            okText="Oui"
            onConfirm={() => handleDelete(category)}
            title="Dernière chance. Êtes-vous sûr ?"
          >
            <Button icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  // Render
  return (
    <>
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Catégories</Typography.Title>
        </Col>
        <Col>
          <Space wrap>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={handleCreateOpen}
            >
              Ajouter une catégorie
            </Button>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Table
        dataSource={categories}
        columns={columns}
        loading={isLoading}
        pagination={false}
        rowKey="id"
        bordered
      />
      {/* Create */}
      <Modal
        title="Ajouter une catégorie"
        open={isCreateOpen}
        destroyOnClose
        footer={null}
        onCancel={handleCreateClose}
        width={800}
      >
        <Upsert
          handleSubmit={handleCreate}
          loading={createLoading}
          error={createError}
        />
      </Modal>
      {/* Update */}
      <Modal
        title="Modifier une catégorie"
        open={!!isUpdateOpen}
        destroyOnClose
        footer={null}
        onCancel={handleUpdateClose}
        width={800}
      >
        <Upsert
          category={isUpdateOpen}
          handleSubmit={handleUpdate}
          loading={updateLoading}
          error={updateError}
        />
      </Modal>
    </>
  );
};

export default CategoriesList;
