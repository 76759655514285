import { KeyOutlined } from '@ant-design/icons';
import { Breadcrumb, Card, Col, Divider, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';

const SettingsList = () => {
  return (
    <>
      <Breadcrumb items={[{ title: <Link to="/settings">Paramètres</Link> }]} />
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Paramètres</Typography.Title>
        </Col>
      </Row>
      <Divider />
      <Link to="/settings/password">
        <Card
          hoverable
          style={{ width: 300 }}
          cover={<KeyOutlined style={{ marginTop: 32, fontSize: 40 }} />}
        >
          <Card.Meta
            title="Modifier son mot de passe"
            style={{ textAlign: 'center' }}
          />
        </Card>
      </Link>
    </>
  );
};

export default SettingsList;
