import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const MUTATION_ABSENCE_REQUEST = gql`
  mutation AbsenceRequest(
    $date: String!
    $moment: String!
    $timelaps: String!
    $reason: String!
  ) {
    absenceRequest(
      date: $date
      moment: $moment
      timelaps: $timelaps
      reason: $reason
    ) {
      id
    }
  }
`;

export function useMutationAbsenceRequest(options = {}) {
  const [mutation] = useMutation(MUTATION_ABSENCE_REQUEST, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_ABSENCE_ACCEPT = gql`
  mutation AbsenceAccept($id: ID!) {
    absenceAccept(id: $id)
  }
`;

export function useMutationAbsenceAccept(options = {}) {
  const [mutation] = useMutation(MUTATION_ABSENCE_ACCEPT, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_ABSENCE_REJECT = gql`
  mutation AbsenceReject($id: ID!) {
    absenceReject(id: $id)
  }
`;

export function useMutationAbsenceReject(options = {}) {
  const [mutation] = useMutation(MUTATION_ABSENCE_REJECT, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_ABSENCE_CANCEL = gql`
  mutation AbsenceCancel($id: ID!) {
    absenceCancel(id: $id)
  }
`;

export function useMutationAbsenceCancel(options = {}) {
  const [mutation] = useMutation(MUTATION_ABSENCE_CANCEL, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_ABSENCE_DELETE = gql`
  mutation AbsenceDelete($id: ID!) {
    absenceDelete(id: $id)
  }
`;

export function useMutationAbsenceDelete(options = {}) {
  const [mutation] = useMutation(MUTATION_ABSENCE_DELETE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}
