import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const MUTATION_DEPARTMENT_CREATE = gql`
  mutation DepartmentCreate(
    $name: String!
    $code: String!
    $color: String!
    $displayOrder: Int
  ) {
    departmentCreate(
      name: $name
      code: $code
      color: $color
      displayOrder: $displayOrder
    ) {
      id
    }
  }
`;

export function useMutationDepartmentCreate(options = {}) {
  const [mutation] = useMutation(MUTATION_DEPARTMENT_CREATE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_DEPARTMENT_UPDATE = gql`
  mutation DepartmentUpdate(
    $id: ID!
    $name: String
    $code: String
    $color: String
    $displayOrder: Int
  ) {
    departmentUpdate(
      id: $id
      name: $name
      code: $code
      color: $color
      displayOrder: $displayOrder
    ) {
      id
    }
  }
`;

export function useMutationDepartmentUpdate(options = {}) {
  const [mutation] = useMutation(MUTATION_DEPARTMENT_UPDATE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_DEPARTMENT_DELETE = gql`
  mutation DepartmentDelete($id: ID!) {
    departmentDelete(id: $id)
  }
`;

export function useMutationDepartmentDelete(options = {}) {
  const [mutation] = useMutation(MUTATION_DEPARTMENT_DELETE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}
