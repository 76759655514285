import { gql, useQuery } from '@apollo/client';
import { FRAG_ACCOUNT } from './fragments';

const QUERY_ME = gql`
  query me {
    me {
      ...AccountData
    }
  }
  ${FRAG_ACCOUNT}
`;

export const useQueryMe = (options = {}) => {
  const { data, loading, ...props } = useQuery(QUERY_ME, options);
  return {
    me: data?.me,
    isLoading: loading || !data,
    ...props,
  };
};
