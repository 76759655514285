import { SendOutlined } from '@ant-design/icons';
import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Typography,
  message,
} from 'antd';
import { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutationAuthUpdatePassword } from '../../gql/mutations/auth';

const SettingsPassword = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [errorCode, setErrorCode] = useState(undefined);
  const mutationAuthUpdatePassword = useMutationAuthUpdatePassword();
  const navigate = useNavigate();
  const handleUpdate = useCallback(
    async (values: any) => {
      try {
        setSubmitting(true);
        setErrorCode(undefined);
        await mutationAuthUpdatePassword(values);
        message.success('Votre mot de passe a été mis à jour.');
        navigate('/settings');
      } catch (err: any) {
        setErrorCode(err.message);
      } finally {
        setSubmitting(false);
      }
    },
    [mutationAuthUpdatePassword, navigate],
  );
  return (
    <>
      <Breadcrumb
        items={[
          { title: <Link to="/settings">Paramètres</Link> },
          { title: <Link to="/settings/password">Mot de passe</Link> },
        ]}
      />
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Modifier son mot de passe</Typography.Title>
        </Col>
      </Row>
      <Divider />
      <Form
        initialValues={{
          oldPassword: '',
          newPassword: '',
        }}
        layout="vertical"
        onFinish={handleUpdate}
        disabled={isSubmitting}
      >
        {errorCode === '401' ? (
          <Alert
            type="error"
            message="L'ancien mot de passe est incorrect."
            banner
            style={{ marginBottom: 16 }}
          />
        ) : (
          errorCode && (
            <Alert
              type="error"
              message="Une erreur est survenue. Veuillez réessayer."
              banner
              style={{ marginBottom: 16 }}
            />
          )
        )}
        <Form.Item
          label="Ancien mot de passe"
          name="oldPassword"
          rules={[
            {
              required: true,
              message: 'Veuillez renseigner votre ancien mot de passe.',
            },
          ]}
        >
          <Input type="password" />
        </Form.Item>
        <Form.Item
          label="Nouveau mot de passe"
          name="newPassword"
          rules={[
            {
              required: true,
              message: 'Veuillez renseigner votre nouveau mot de passe.',
            },
          ]}
        >
          <Input type="password" />
        </Form.Item>
        <Button htmlType="submit" icon={<SendOutlined />} type="primary">
          Envoyer
        </Button>
      </Form>
    </>
  );
};

export default SettingsPassword;
