import {
  Checkbox,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Tooltip,
} from 'antd';

const text = (props: any) => (
  <Form.Item
    name={[props.prefix, props.name]}
    required={props.required || false}
    label={props.label}
    noStyle={props.plain || !props.label}
    tooltip={props.tooltip}
  >
    <Input disabled={props.disabled} />
  </Form.Item>
);

const number = (props: any) => (
  <Form.Item
    name={[props.prefix, props.name]}
    required={props.required || false}
    label={props.label}
    noStyle={props.plain || !props.label}
    tooltip={props.tooltip}
  >
    <InputNumber disabled={props.disabled} />
  </Form.Item>
);

const textarea = (props: any) => (
  <Form.Item
    name={[props.prefix, props.name]}
    required={props.required || false}
    label={props.label}
    noStyle={props.plain || !props.label}
    tooltip={props.tooltip}
  >
    <Input.TextArea disabled={props.disabled} autoSize />
  </Form.Item>
);

const select = (props: any) => (
  <Form.Item
    name={[props.prefix, props.name]}
    required={props.required || false}
    label={props.label}
    noStyle={props.plain || !props.label}
    tooltip={props.tooltip}
  >
    <Select
      disabled={props.disabled}
      allowClear
      showSearch
      filterOption={(v, opt: any) =>
        opt.label.toLowerCase().includes(v.toLowerCase())
      }
      options={(props.options || '').split(',').map((opt: any) => ({
        label: opt,
        value: opt,
      }))}
    />
  </Form.Item>
);

const radio = (props: any) => (
  <Form.Item
    name={[props.prefix, props.name]}
    required={props.required || false}
    label={props.label}
    noStyle
  >
    <Radio.Group disabled={props.disabled}>
      <Tooltip title={props.tooltip}>
        <Radio value={props.option}>{props.label}</Radio>
      </Tooltip>
    </Radio.Group>
  </Form.Item>
);

const radiogroup = (props: any) => (
  <Form.Item
    name={[props.prefix, props.name]}
    required={props.required || false}
    label={props.label}
    noStyle={props.plain || !props.label}
    tooltip={props.tooltip}
  >
    <Radio.Group disabled={props.disabled}>
      {(props.options || '').split(',').map((opt: any) => (
        <Radio key={opt} value={opt}>
          {opt}
        </Radio>
      ))}
    </Radio.Group>
  </Form.Item>
);

const checkbox = (props: any) => (
  <Form.Item
    name={[props.prefix, props.name]}
    required={props.required || false}
    label={props.label}
    noStyle={props.plain || !props.label}
    tooltip={props.tooltip}
    valuePropName={'checked'}
  >
    <Checkbox disabled={props.disabled} />
  </Form.Item>
);

const theader = (props: any) => (
  <thead>
    <tr>
      <th></th>
      <th>A</th>
      <th>B</th>
      <th>C</th>
      <th>D</th>
      {(props.type === '3' || props.type === '4') && <th>E</th>}
      <th>N/A</th>
      <th>Démo</th>
      <th>Remarque</th>
      {(props.type === '2' || props.type === '4') && (
        <>
          <th>Superviseur</th>
          <th>Date</th>
        </>
      )}
    </tr>
  </thead>
);

const trow = (props: any) => {
  return (
    <tr className={props.highlighted ? 'highlighted' : ''}>
      <td>{props.label}</td>
      <td>
        {!props.empty &&
          radio({
            prefix: props.prefix,
            disabled: props.disabled,
            name: `${props.name}_note`,
            option: 'A',
            tooltip: props.infoA,
          })}
      </td>
      <td>
        {!props.empty &&
          radio({
            prefix: props.prefix,
            disabled: props.disabled,
            name: `${props.name}_note`,
            option: 'B',
            tooltip: props.infoB,
          })}
      </td>
      <td>
        {!props.empty &&
          radio({
            prefix: props.prefix,
            disabled: props.disabled,
            name: `${props.name}_note`,
            option: 'C',
            tooltip: props.infoC,
          })}
      </td>
      <td>
        {!props.empty &&
          radio({
            prefix: props.prefix,
            disabled: props.disabled,
            name: `${props.name}_note`,
            option: 'D',
            tooltip: props.infoD,
          })}
      </td>
      {(props.type === '3' || props.type === '4') && (
        <td>
          {!props.empty &&
            radio({
              prefix: props.prefix,
              disabled: props.disabled,
              name: `${props.name}_note`,
              option: 'E',
              tooltip: props.infoE,
            })}
        </td>
      )}
      <td>
        {!props.empty &&
          radio({
            prefix: props.prefix,
            disabled: props.disabled,
            name: `${props.name}_note`,
            option: 'N/A',
            tooltip: props.infoNA,
          })}
      </td>
      <td>
        {!props.empty &&
          radio({
            prefix: props.prefix,
            disabled: props.disabled,
            name: `${props.name}_note`,
            option: 'Démo',
            tooltip: props.infoDemo,
          })}
      </td>
      <td>
        {!props.empty &&
          text({
            prefix: props.prefix,
            disabled: props.disabled,
            name: `${props.name}_remarque`,
            plain: true,
          })}
      </td>
      {(props.type === '2' || props.type === '4') && (
        <>
          <td>
            {!props.empty &&
              text({
                prefix: props.prefix,
                disabled: props.disabled,
                name: `${props.name}_superviseur`,
                plain: true,
              })}
          </td>
          <td>
            {!props.empty &&
              text({
                prefix: props.prefix,
                disabled: props.disabled,
                name: `${props.name}_date`,
                plain: true,
              })}
          </td>
        </>
      )}
    </tr>
  );
};

const def = {
  text,
  number,
  textarea,
  select,
  radiogroup,
  radio,
  checkbox,
  theader,
  trow,
};

export default def;
