import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const MUTATION_TEMPLATE_CREATE = gql`
  mutation TemplateCreate($name: String!) {
    templateCreate(name: $name) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export function useMutationTemplateCreate(options = {}) {
  const [mutation] = useMutation(MUTATION_TEMPLATE_CREATE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_TEMPLATE_UPDATE = gql`
  mutation TemplateUpdate($id: ID!, $name: String) {
    templateUpdate(id: $id, name: $name) {
      id
    }
  }
`;

export function useMutationTemplateUpdate(options = {}) {
  const [mutation] = useMutation(MUTATION_TEMPLATE_UPDATE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_TEMPLATE_DELETE = gql`
  mutation TemplateDelete($id: ID!) {
    templateDelete(id: $id)
  }
`;

export function useMutationTemplateDelete(options = {}) {
  const [mutation] = useMutation(MUTATION_TEMPLATE_DELETE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}
