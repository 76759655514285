import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const MUTATION_ACCOUNT_CREATE = gql`
  mutation AccountCreate(
    $groupId: String
    $departmentIds: [String!]
    $type: String!
    $noma: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $shouldNotify: Boolean
    $isActive: Boolean
  ) {
    accountCreate(
      groupId: $groupId
      departmentIds: $departmentIds
      type: $type
      noma: $noma
      email: $email
      firstName: $firstName
      lastName: $lastName
      shouldNotify: $shouldNotify
      isActive: $isActive
    ) {
      id
    }
  }
`;

export function useMutationAccountCreate(options = {}) {
  const [mutation] = useMutation(MUTATION_ACCOUNT_CREATE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_ACCOUNT_UPDATE = gql`
  mutation AccountUpdate(
    $id: ID!
    $groupId: String
    $departmentIds: [String!]
    $type: String
    $noma: String
    $email: String
    $firstName: String
    $lastName: String
    $shouldNotify: Boolean
    $isActive: Boolean
  ) {
    accountUpdate(
      id: $id
      groupId: $groupId
      departmentIds: $departmentIds
      type: $type
      noma: $noma
      email: $email
      firstName: $firstName
      lastName: $lastName
      shouldNotify: $shouldNotify
      isActive: $isActive
    ) {
      id
    }
  }
`;

export function useMutationAccountUpdate(options = {}) {
  const [mutation] = useMutation(MUTATION_ACCOUNT_UPDATE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_ACCOUNT_DELETE = gql`
  mutation AccountDelete($id: ID!) {
    accountDelete(id: $id)
  }
`;

export function useMutationAccountDelete(options = {}) {
  const [mutation] = useMutation(MUTATION_ACCOUNT_DELETE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_ACCOUNT_ACTIVATE_MULTIPLE = gql`
  mutation AccountActivateMultiple($ids: [ID!]!) {
    accountActivateMultiple(ids: $ids)
  }
`;

export function useMutationAccountActivateMultiple(options = {}) {
  const [mutation] = useMutation(MUTATION_ACCOUNT_ACTIVATE_MULTIPLE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_ACCOUNT_DEACTIVATE_MULTIPLE = gql`
  mutation AccountDeactivateMultiple($ids: [ID!]!) {
    accountDeactivateMultiple(ids: $ids)
  }
`;

export function useMutationAccountDeactivateMultiple(options = {}) {
  const [mutation] = useMutation(MUTATION_ACCOUNT_DEACTIVATE_MULTIPLE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_ACCOUNT_DELETE_MULTIPLE = gql`
  mutation AccountDeleteMultiple($ids: [ID!]!) {
    accountDeleteMultiple(ids: $ids)
  }
`;

export function useMutationAccountDeleteMultiple(options = {}) {
  const [mutation] = useMutation(MUTATION_ACCOUNT_DELETE_MULTIPLE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}
