import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SendOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Typography,
  Upload,
  message,
} from 'antd';
import { useCallback, useState } from 'react';
import {
  useMutationAccountActivateMultiple,
  useMutationAccountCreate,
  useMutationAccountDeactivateMultiple,
  useMutationAccountDelete,
  useMutationAccountDeleteMultiple,
  useMutationAccountUpdate,
} from '../../gql/mutations/accounts';
import { useQueryAccounts } from '../../gql/queries/accounts';
import { useQueryDepartments } from '../../gql/queries/departments';
import { useQueryGroups } from '../../gql/queries/groups';
import { RAW } from '../../helpers/request';
import {
  renderBool,
  renderTextChoices,
  searchBool,
  searchText,
  searchTextChoices,
  sortBool,
  sortText,
} from '../../helpers/table';

const Upsert = ({
  account = {},
  groups = [],
  departments = [],
  handleSubmit,
  loading,
  error,
}: any) => {
  return (
    <Form
      initialValues={{
        id: account.id || undefined,
        groupId: account.groupId || null,
        departmentIds: account.departmentIds || [],
        type: account.type || 'student',
        noma: account.noma || '',
        email: account.email || '',
        firstName: account.firstName || '',
        lastName: account.lastName || '',
        shouldNotify: account.shouldNotify || false,
        isActive: account.isActive || false,
      }}
      layout="vertical"
      onFinish={handleSubmit}
      disabled={loading}
    >
      {error === '409' ? (
        <Alert
          type="error"
          message="Un utilisateur avec le même NOMA ou adresse email existe déjà. Veuillez en choisir un autre."
          banner
          className="error"
        />
      ) : (
        error && (
          <Alert
            type="error"
            message="Une erreur est survenue. Veuillez réessayer."
            banner
            className="error"
          />
        )
      )}
      <Form.Item noStyle name="id" />
      <Form.Item
        label="Adresse email"
        name="email"
        rules={[
          {
            required: true,
            type: 'email',
            message: 'Veuillez renseigner une adresse email.',
          },
        ]}
      >
        <Input type="email" placeholder="james@bond.com" />
      </Form.Item>
      <Form.Item label="NOMA" name="noma">
        <Input placeholder="007" />
      </Form.Item>
      <Form.Item
        label="Prénom"
        name="firstName"
        rules={[{ required: true, message: 'Veuillez renseigner un prénom.' }]}
      >
        <Input placeholder="James" />
      </Form.Item>
      <Form.Item
        label="Nom de famille"
        name="lastName"
        rules={[
          { required: true, message: 'Veuillez renseigner un nom de famille.' },
        ]}
      >
        <Input placeholder="Bond" />
      </Form.Item>
      <Form.Item label="Type d'utilisateur" name="type">
        <Select
          options={[
            { label: 'Lecteur', value: 'viewer' },
            { label: 'Étudiant', value: 'student' },
            { label: 'Assistant', value: 'assistant' },
            { label: 'Superviseur', value: 'supervisor' },
            { label: 'Administrateur', value: 'admin' },
          ]}
        />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) =>
          getFieldValue('type') === 'admin' && (
            <Form.Item
              label="Notifier l'administrateur des changements d'obligations"
              name="shouldNotify"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item label="Compte actif ?" name="isActive" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item
        label="Groupe auquel l'utilisateur est rattaché pour les obligations"
        name="groupId"
      >
        <Select
          allowClear
          showSearch
          filterOption={(v, opt: any) =>
            opt.label.toLowerCase().includes(v.toLowerCase())
          }
          options={groups.map((group: any) => ({
            label: group.name,
            value: group.id,
          }))}
        />
      </Form.Item>
      <Form.Item
        label="Disciplines auxquels l'utilisateur est rattaché pour les carnets de stage"
        name="departmentIds"
      >
        <Select
          mode="multiple"
          allowClear
          showSearch
          filterOption={(v, opt: any) =>
            opt.label.toLowerCase().includes(v.toLowerCase())
          }
          options={departments.map((department: any) => ({
            label: department.name,
            value: department.id,
          }))}
        />
      </Form.Item>
      <Button htmlType="submit" icon={<SendOutlined />} type="primary">
        Envoyer
      </Button>
    </Form>
  );
};

const AccountsList = () => {
  // List
  const { isLoading, accounts, refetch } = useQueryAccounts();
  const { groups } = useQueryGroups();
  const { departments } = useQueryDepartments();
  // Create
  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(undefined);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const handleCreateOpen = useCallback(() => {
    setCreateLoading(false);
    setCreateError(undefined);
    setIsCreateOpen(true);
  }, []);
  const handleCreateClose = useCallback(() => {
    setCreateLoading(false);
    setCreateError(undefined);
    setIsCreateOpen(false);
  }, []);
  const mutationAccountCreate = useMutationAccountCreate();
  const handleCreate = useCallback(
    async (values: any) => {
      try {
        setCreateLoading(true);
        setCreateError(undefined);
        await mutationAccountCreate(values);
        await refetch();
        handleCreateClose();
        message.success("L'utilisateur a été créé.");
      } catch (err: any) {
        setCreateError(err.message);
      } finally {
        setCreateLoading(false);
      }
    },
    [handleCreateClose, mutationAccountCreate, refetch],
  );
  // Create from file
  const [createFromFileLoading, setCreateFromFileLoading] = useState(false);
  const [createFromFileError, setCreateFromFileError] = useState(undefined);
  const [isCreateFromFileOpen, setIsCreateFromFileOpen] = useState(false);
  const handleCreateFromFileOpen = useCallback(() => {
    setCreateFromFileLoading(false);
    setCreateFromFileError(undefined);
    setIsCreateFromFileOpen(true);
  }, []);
  const handleCreateFromFileClose = useCallback(() => {
    setCreateFromFileLoading(false);
    setCreateFromFileError(undefined);
    setIsCreateFromFileOpen(false);
  }, []);
  const handleCreateFromFile = useCallback(
    async ({ file, groupId }: any) => {
      try {
        setCreateFromFileLoading(true);
        setCreateFromFileError(undefined);
        const data = new FormData();
        data.append('file', file.file);
        data.append('groupId', groupId);
        await RAW(`${process.env.REACT_APP_URL}/api/accounts/from-file`, data);
        await refetch();
        handleCreateFromFileClose();
        message.success('Les utilisateurs ont été créés.');
      } catch (err: any) {
        setCreateFromFileError(err);
      } finally {
        setCreateFromFileLoading(false);
      }
    },
    [handleCreateFromFileClose, refetch],
  );
  // Update
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState(undefined);
  const [isUpdateOpen, setIsUpdateOpen] = useState(undefined);
  const handleUpdateOpen = useCallback((account: any) => {
    setUpdateLoading(false);
    setUpdateError(undefined);
    setIsUpdateOpen(account);
  }, []);
  const handleUpdateClose = useCallback(() => {
    setUpdateLoading(false);
    setUpdateError(undefined);
    setIsUpdateOpen(undefined);
  }, []);
  const mutationAccountUpdate = useMutationAccountUpdate();
  const handleUpdate = useCallback(
    async (values: any) => {
      try {
        setUpdateLoading(true);
        setUpdateError(undefined);
        await mutationAccountUpdate(values);
        await refetch();
        handleUpdateClose();
        message.success("L'utilisateur a été mis à jour.");
      } catch (err: any) {
        setUpdateError(err.message);
      } finally {
        setUpdateLoading(false);
      }
    },
    [handleUpdateClose, mutationAccountUpdate, refetch],
  );
  // Delete
  const mutationAccountDelete = useMutationAccountDelete();
  const handleDelete = useCallback(
    async (account: any) => {
      try {
        await mutationAccountDelete({
          id: account.id,
        });
        await refetch();
        message.success("L'utilisateur a été supprimé.");
      } catch (err) {
        message.error("Une erreur s'est produite. Veuillez réessayer.");
      }
    },
    [mutationAccountDelete, refetch],
  );
  // Selection
  const [selectedIds, setSelectedIds] = useState([]);
  const rowSelection = {
    selectedRowKeys: selectedIds,
    onChange: (n: any) => setSelectedIds(n),
  };
  const mutationAccountActivateMultiple = useMutationAccountActivateMultiple();
  const mutationAccountDeactivateMultiple =
    useMutationAccountDeactivateMultiple();
  const mutationAccountDeleteMultiple = useMutationAccountDeleteMultiple();
  const handleMultiple = useCallback(
    async ({ key }: { key: any }) => {
      try {
        if (key === 'activate') {
          await mutationAccountActivateMultiple({
            ids: selectedIds,
          });
          message.success('Les utilisateurs ont été activés.');
        } else if (key === 'deactivate') {
          await mutationAccountDeactivateMultiple({
            ids: selectedIds,
          });
          message.success('Les utilisateurs ont été déactivés.');
        } else if (key === 'delete') {
          await mutationAccountDeleteMultiple({
            ids: selectedIds,
          });
          message.success('Les utilisateurs ont été supprimés.');
        }
        await refetch();
        setSelectedIds([]);
      } catch (err) {
        message.error("Une erreur s'est produite. Veuillez réessayer.");
      }
    },
    [
      selectedIds,
      mutationAccountActivateMultiple,
      mutationAccountDeactivateMultiple,
      mutationAccountDeleteMultiple,
      refetch,
    ],
  );
  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...searchText('email'),
      ...sortText('email', 4),
    },
    {
      title: 'NOMA',
      dataIndex: 'noma',
      key: 'noma',
      ...searchText('noma'),
      ...sortText('noma', 3),
    },
    {
      title: 'Nom de famille',
      dataIndex: 'lastName',
      key: 'lastName',
      ...searchText('lastName'),
      ...sortText('lastName', 1),
    },
    {
      title: 'Prénom',
      dataIndex: 'firstName',
      key: 'firstName',
      ...searchText('firstName'),
      ...sortText('firstName', 2),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      ...renderTextChoices([
        { text: 'Lecteur', value: 'viewer' },
        { text: 'Étudiant', value: 'student' },
        { text: 'Assistant', value: 'assistant' },
        { text: 'Superviseur', value: 'supervisor' },
        { text: 'Administrateur', value: 'admin' },
      ]),
      ...searchTextChoices('type', [
        { text: 'Lecteur', value: 'viewer' },
        { text: 'Étudiant', value: 'student' },
        { text: 'Assistant', value: 'assistant' },
        { text: 'Superviseur', value: 'supervisor' },
        { text: 'Administrateur', value: 'admin' },
      ]),
      ...sortText('type', 1),
    },
    {
      title: 'Actif ?',
      dataIndex: 'isActive',
      key: 'isActive',
      ...searchBool('isActive'),
      ...renderBool(),
      ...sortBool('isActive', 3),
    },
    {
      title: 'Groupe (obligations)',
      key: 'group',
      filters: (groups || []).map((group: any) => ({
        text: group.name,
        value: group.id,
      })),
      filterMultiple: false,
      onFilter: (value: any, record: any) => record.groupId === value,
      render: ({ group }: any) => group?.name || '',
    },
    {
      title: 'Disciplines (carnet)',
      key: 'departments',
      filters: (departments || []).map((department: any) => ({
        text: department.name,
        value: department.id,
      })),
      filterMultiple: false,
      onFilter: (value: any, record: any) =>
        record.departmentIds.includes(value),
      render: ({ departments }: any) =>
        departments.map((department: any) => department?.name || '').join(', '),
    },
    {
      title: '',
      key: 'options',
      width: 100,
      render: (account: any) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleUpdateOpen(account)}
          />
          <Popconfirm
            okText="Oui"
            onConfirm={() => handleDelete(account)}
            title="Dernière chance. Êtes-vous sûr ?"
          >
            <Button icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  // Render
  return (
    <>
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Utilisateurs</Typography.Title>
        </Col>
        <Col>
          <Space wrap>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={handleCreateOpen}
            >
              Ajouter un utilisateur
            </Button>
            <Button icon={<PlusOutlined />} onClick={handleCreateFromFileOpen}>
              Ajouter des étudiants depuis un fichier
            </Button>
          </Space>
        </Col>
      </Row>
      <Divider />
      {selectedIds.length > 0 && (
        <Space>
          <Dropdown
            menu={{
              items: [
                { key: 'activate', label: <a>Activer les comptes</a> },
                { key: 'deactivate', label: <a>Désactiver les comptes</a> },
                { key: 'delete', label: <a>Supprimer les comptes</a> },
              ] as any,
              onClick: handleMultiple,
            }}
            arrow
          >
            <Button>Actions groupées</Button>
          </Dropdown>
          <p>{selectedIds.length} utilisateurs sélectionnés</p>
        </Space>
      )}
      <Table
        rowKey="id"
        dataSource={accounts}
        columns={columns}
        loading={isLoading}
        rowSelection={rowSelection}
        bordered
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
        }}
      />
      {/* Create */}
      <Modal
        title="Ajouter un utilisateur"
        open={isCreateOpen}
        destroyOnClose
        footer={null}
        onCancel={handleCreateClose}
        width={800}
      >
        <Upsert
          groups={groups}
          departments={departments}
          handleSubmit={handleCreate}
          loading={createLoading}
          error={createError}
        />
      </Modal>
      {/* Create from file */}
      <Modal
        title="Ajouter des étudiants depuis un fichier"
        open={isCreateFromFileOpen}
        destroyOnClose
        footer={null}
        onCancel={handleCreateFromFileClose}
        width={800}
      >
        <Form
          initialValues={{
            groupId: null,
            file: null,
          }}
          layout="vertical"
          onFinish={handleCreateFromFile}
          disabled={createFromFileLoading}
        >
          {createFromFileError && (
            <Alert
              type="error"
              message="Une erreur est survenue. Veuillez réessayer."
              banner
              className="error"
            />
          )}
          <Alert
            type="info"
            message="Vous pouvez utiliser un fichier Excel afin de créer plusieurs étudiants à la fois. Le fichier ne doit pas contenir de ligne d'entête. Les colonnes sont les suivantes : NOMA, email, prénom et nom. Veillez à bien respecter l'ordre des colonnes."
            banner
            className="error"
          />
          <Form.Item
            label="Groupe auquel les utilisateurs seront rattachés"
            name="groupId"
          >
            <Select
              allowClear
              showSearch
              filterOption={(v, opt: any) =>
                opt.label.toLowerCase().includes(v.toLowerCase())
              }
              options={(groups || []).map((group: any) => ({
                label: group.name,
                value: group.id,
              }))}
            />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ setFieldValue }) => (
              <Form.Item
                label="Fichier"
                name="file"
                rules={[
                  { required: true, message: 'Veuillez choisir un fichier.' },
                ]}
              >
                <Upload
                  accept=".xlsx"
                  maxCount={1}
                  beforeUpload={(file) => {
                    setFieldValue('file', file);
                    return false;
                  }}
                >
                  <Button icon={<UploadOutlined />}>
                    Selectionner un fichier
                  </Button>
                </Upload>
              </Form.Item>
            )}
          </Form.Item>
          <Button htmlType="submit" icon={<SendOutlined />} type="primary">
            Envoyer
          </Button>
        </Form>
      </Modal>
      {/* Update */}
      <Modal
        title="Modifier un utilisateur"
        open={!!isUpdateOpen}
        destroyOnClose
        footer={null}
        onCancel={handleUpdateClose}
        width={800}
      >
        <Upsert
          account={isUpdateOpen}
          groups={groups}
          departments={departments}
          handleSubmit={handleUpdate}
          loading={updateLoading}
          error={updateError}
        />
      </Modal>
    </>
  );
};

export default AccountsList;
