import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SendOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  ColorPicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from 'antd';
import { useCallback, useState } from 'react';
import {
  useMutationDepartmentCreate,
  useMutationDepartmentDelete,
  useMutationDepartmentUpdate,
} from '../../gql/mutations/departments';
import { useQueryDepartments } from '../../gql/queries/departments';
import { searchText, sortText } from '../../helpers/table';

const Upsert = ({ department = {}, handleSubmit, loading, error }: any) => (
  <Form
    initialValues={{
      id: department.id || undefined,
      name: department.name || '',
      code: department.code || '',
      color: department.color || '#000000',
      displayOrder: department.displayOrder || 0,
    }}
    layout="vertical"
    onFinish={handleSubmit}
    disabled={loading}
  >
    {error === '409' ? (
      <Alert
        type="error"
        message="Une discipline avec le même nom existe déjà. Veuillez en choisir un autre."
        banner
        className="error"
      />
    ) : (
      error && (
        <Alert
          type="error"
          message="Une erreur est survenue. Veuillez réessayer."
          banner
          className="error"
        />
      )
    )}
    <Form.Item noStyle name="id" />
    <Form.Item
      label="Nom de la discipline"
      name="name"
      rules={[{ required: true, message: 'Veuillez renseigner un nom.' }]}
    >
      <Input placeholder="Parodontologie" />
    </Form.Item>
    <Form.Item
      label="Code de la discipline"
      name="code"
      rules={[{ required: true, message: 'Veuillez renseigner un code.' }]}
    >
      <Input placeholder="PARO" />
    </Form.Item>
    <Form.Item
      label="Couleur de la discipline"
      name="color"
      rules={[{ required: true, message: 'Veuillez renseigner une couleur.' }]}
      getValueFromEvent={(_, color) => color}
    >
      <ColorPicker />
    </Form.Item>
    <Form.Item
      label="Ordre d'affichage dans la liste des fiches (0 = plus haut)"
      name="displayOrder"
      rules={[
        {
          required: true,
          message: "Veuillez renseigner un ordre d'affichage.",
        },
      ]}
    >
      <InputNumber />
    </Form.Item>
    <Button htmlType="submit" icon={<SendOutlined />} type="primary">
      Envoyer
    </Button>
  </Form>
);

const SheetsList = () => {
  // List
  const { isLoading, departments, refetch } = useQueryDepartments();
  // Create
  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(undefined);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const handleCreateOpen = useCallback(() => {
    setCreateLoading(false);
    setCreateError(undefined);
    setIsCreateOpen(true);
  }, []);
  const handleCreateClose = useCallback(() => {
    setCreateLoading(false);
    setCreateError(undefined);
    setIsCreateOpen(false);
  }, []);
  const mutationDepartmentCreate = useMutationDepartmentCreate();
  const handleCreate = useCallback(
    async (values: any) => {
      try {
        setCreateLoading(true);
        setCreateError(undefined);
        await mutationDepartmentCreate(values);
        await refetch();
        handleCreateClose();
        message.success('La discipline a été créée.');
      } catch (err: any) {
        setCreateError(err.message);
      } finally {
        setCreateLoading(false);
      }
    },
    [handleCreateClose, mutationDepartmentCreate, refetch],
  );
  // Update
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState(undefined);
  const [isUpdateOpen, setIsUpdateOpen] = useState(undefined);
  const handleUpdateOpen = useCallback((department: any) => {
    setUpdateLoading(false);
    setUpdateError(undefined);
    setIsUpdateOpen(department);
  }, []);
  const handleUpdateClose = useCallback(() => {
    setUpdateLoading(false);
    setUpdateError(undefined);
    setIsUpdateOpen(undefined);
  }, []);
  const mutationDepartmentUpdate = useMutationDepartmentUpdate();
  const handleUpdate = useCallback(
    async (values: any) => {
      try {
        setUpdateLoading(true);
        setUpdateError(undefined);
        await mutationDepartmentUpdate(values);
        await refetch();
        handleUpdateClose();
        message.success('La discipline a été mise à jour.');
      } catch (err: any) {
        setUpdateError(err.message);
      } finally {
        setUpdateLoading(false);
      }
    },
    [handleUpdateClose, mutationDepartmentUpdate, refetch],
  );
  // Delete
  const mutationDepartmentDelete = useMutationDepartmentDelete();
  const handleDelete = useCallback(
    async (department: any) => {
      try {
        await mutationDepartmentDelete({
          id: department.id,
        });
        await refetch();
        message.success('La discipline a été supprimée.');
      } catch (err) {
        message.error("Une erreur s'est produite. Veuillez réessayer.");
      }
    },
    [mutationDepartmentDelete, refetch],
  );
  // Columns
  const columns = [
    {
      title: 'Couleur',
      key: 'color',
      width: 100,
      render: ({ color }: any) => (
        <Tag color={color}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </Tag>
      ),
    },
    {
      title: 'Nom de la discipline',
      dataIndex: 'name',
      key: 'name',
      ...sortText('name'),
      ...searchText('name'),
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      ...sortText('code'),
      ...searchText('code'),
    },
    {
      title: "Ordre d'affichage",
      dataIndex: 'displayOrder',
      key: 'displayOrder',
      ...sortText('displayOrder'),
      ...searchText('displayOrder'),
    },
    {
      title: '',
      key: 'options',
      width: 100,
      render: (department: any) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleUpdateOpen(department)}
          />
          {/* <Popconfirm
            okText="Oui"
            onConfirm={() => handleDelete(department)}
            title="Dernière chance. Êtes-vous sûr ?"
          >
            <Button icon={<DeleteOutlined />} danger />
          </Popconfirm> */}
        </Space>
      ),
    },
  ];
  // Render
  return (
    <>
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Disciplines</Typography.Title>
        </Col>
        <Col>
          <Space wrap>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={handleCreateOpen}
            >
              Ajouter une discipline
            </Button>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Table
        dataSource={departments}
        columns={columns}
        loading={isLoading}
        pagination={false}
        rowKey="id"
        bordered
      />
      {/* Create */}
      <Modal
        title="Ajouter une discipline"
        open={isCreateOpen}
        destroyOnClose
        footer={null}
        onCancel={handleCreateClose}
        width={800}
      >
        <Upsert
          handleSubmit={handleCreate}
          loading={createLoading}
          error={createError}
        />
      </Modal>
      {/* Update */}
      <Modal
        title="Modifier une discipline"
        open={!!isUpdateOpen}
        destroyOnClose
        footer={null}
        onCancel={handleUpdateClose}
        width={800}
      >
        <Upsert
          department={isUpdateOpen}
          handleSubmit={handleUpdate}
          loading={updateLoading}
          error={updateError}
        />
      </Modal>
    </>
  );
};

export default SheetsList;
