import { CarryOutOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Row, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useQueryMe } from '../../gql/queries/me';

const HomeViewer = () => {
  const { me } = useQueryMe();
  const name = me ? me.firstName : '';
  return (
    <>
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Bonjour {name} !</Typography.Title>
        </Col>
      </Row>
      <Divider />
      <Typography.Title level={2}>Obligations</Typography.Title>
      <Space wrap>
        <Link to="/obligations">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<CarryOutOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Obligations"
              description="Voir les obligations"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
      </Space>
    </>
  );
};

export default HomeViewer;
