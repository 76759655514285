import { gql, useQuery } from '@apollo/client';
import { FRAG_DEPARTMENT, FRAG_SHEETTEMPLATE } from './fragments';

const QUERY_SHEETTEMPLATES = gql`
  query sheetTemplates {
    sheetTemplates {
      ...SheetTemplateData
      department {
        ...DepartmentData
      }
    }
  }
  ${FRAG_SHEETTEMPLATE}
  ${FRAG_DEPARTMENT}
`;

export const useQuerySheetTemplates = (options = {}) => {
  const { data, loading, ...props } = useQuery(QUERY_SHEETTEMPLATES, options);
  return {
    sheetTemplates: data?.sheetTemplates,
    isLoading: loading || !data,
    ...props,
  };
};

const QUERY_SHEETTEMPLATE = gql`
  query sheetTemplate($id: ID!) {
    sheetTemplate(id: $id) {
      ...SheetTemplateData
      department {
        ...DepartmentData
      }
    }
  }
  ${FRAG_SHEETTEMPLATE}
  ${FRAG_DEPARTMENT}
`;

export const useQuerySheetTemplate = (options = {}) => {
  const { data, loading, ...props } = useQuery(QUERY_SHEETTEMPLATE, options);
  return {
    sheetTemplate: data?.sheetTemplate,
    isLoading: loading || !data,
    ...props,
  };
};
