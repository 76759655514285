import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  HistoryOutlined,
  LeftOutlined,
  PlusOutlined,
  RetweetOutlined,
  RightOutlined,
  SendOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { useCallback, useState } from 'react';
import {
  useMutationObligationCreate,
  useMutationObligationDelete,
  useMutationObligationReroll,
  useMutationObligationUpdate,
} from '../../gql/mutations/obligations';
import { useQueryAbsences } from '../../gql/queries/absences';
import { useQueryStudents } from '../../gql/queries/accounts';
import { useQueryCategories } from '../../gql/queries/categories';
import { useQueryObligations } from '../../gql/queries/obligations';
import { GET } from '../../helpers/request';
import History from './History';
import { FormatTable, useFormatTable } from './formatTable';

const Slot = ({
  slot,
  handleUpdate,
  handleDelete,
  handleHistory,
  handleReroll,
}: any) => (
  <div
    style={{
      borderColor: slot.category?.color || 'black',
    }}
    className="slot"
  >
    <p className="slot-category">{slot.category?.name || 'NA'}</p>
    <p className="slot-specifier">{slot.specifier}</p>
    <p className="slot-student">
      {slot.account
        ? `${slot.account.firstName} ${slot.account.lastName}`
        : 'VIDE'}
    </p>
    {slot.absent && <p className="slot-absent">ABSENT</p>}
    {slot.takeoverAccount && (
      <p className="slot-takeover">
        {`Proposé à ${slot.takeoverAccount.firstName} ${slot.takeoverAccount.lastName}`}
      </p>
    )}
    {!slot.isAbsence && (
      <div className="slot-actions">
        <Dropdown
          menu={{
            items: [
              {
                key: 'edit',
                label: 'Modifier',
                icon: <EditOutlined />,
                onClick: () => handleUpdate(slot),
              },
              {
                key: 'history',
                label: 'Historique',
                icon: <HistoryOutlined />,
                onClick: () => handleHistory(slot),
              },
              {
                key: 'reroll',
                label: (
                  <Popconfirm
                    okText="Oui"
                    onConfirm={() => handleReroll(slot)}
                    title="Dernière chance. Êtes-vous sûr ?"
                  >
                    Réaffecter
                  </Popconfirm>
                ),
                icon: <RetweetOutlined />,
                danger: true,
              },
              {
                key: 'delete',
                label: (
                  <Popconfirm
                    okText="Oui"
                    onConfirm={() => handleDelete(slot)}
                    title="Dernière chance. Êtes-vous sûr ?"
                  >
                    Supprimer
                  </Popconfirm>
                ),
                icon: <DeleteOutlined />,
                danger: true,
              },
            ],
          }}
          placement="bottomRight"
          arrow
        >
          <Button icon={<SettingOutlined />} size="small" />
        </Dropdown>
      </div>
    )}
  </div>
);

const Upsert = ({
  obligation = {},
  categories = [],
  students = [],
  handleSubmit,
  loading,
  error,
}: any) => (
  <Form
    initialValues={{
      id: obligation.id || undefined,
      categoryId: obligation.categoryId || null,
      accountId: obligation.accountId || null,
      takeoverAccountId: obligation.takeoverAccountId || null,
      specifier: obligation.specifier || '',
      date: dayjs(obligation.date) || null,
      moment: obligation.moment || 'AM',
      absent: obligation.absent || false,
    }}
    layout="vertical"
    onFinish={handleSubmit}
    disabled={loading}
  >
    {error && (
      <Alert
        type="error"
        message="Une erreur est survenue. Veuillez réessayer."
        banner
        className="error"
      />
    )}
    <Form.Item noStyle name="id" />
    <Form.Item
      label="Catégorie"
      name="categoryId"
      rules={[
        { required: true, message: 'Veuillez renseigner une catégorie.' },
      ]}
    >
      <Select
        options={categories.map((category: any) => ({
          label: category.name,
          value: category.id,
        }))}
      />
    </Form.Item>
    <Form.Item label="Étudiant assigné à l'obligation" name="accountId">
      <Select
        showSearch
        filterOption={(v, opt: any) =>
          opt.label.toLowerCase().includes(v.toLowerCase())
        }
        options={[
          { label: 'VIDE', value: null },
          ...students.map((student: any) => ({
            label: `${student.firstName} ${student.lastName}`,
            value: student.id,
          })),
        ]}
      />
    </Form.Item>
    <Form.Item
      label="Étudiant auquel le transfert est proposé"
      name="takeoverAccountId"
    >
      <Select
        showSearch
        filterOption={(v, opt: any) =>
          opt.label.toLowerCase().includes(v.toLowerCase())
        }
        options={[
          { label: 'VIDE', value: null },
          ...students.map((student: any) => ({
            label: `${student.firstName} ${student.lastName}`,
            value: student.id,
          })),
        ]}
      />
    </Form.Item>
    <Form.Item label="Détails" name="specifier">
      <Input placeholder="CONS DENT 1" />
    </Form.Item>
    <Form.Item
      label="Jour"
      name="date"
      rules={[{ required: true, message: 'Veuillez renseigner un jour.' }]}
    >
      <DatePicker format="DD MMMM YYYY" />
    </Form.Item>
    <Form.Item
      label="Matinée / Après-midi"
      name="moment"
      rules={[{ required: true, message: 'Veuillez renseigner un moment.' }]}
    >
      <Select
        allowClear
        options={[
          { label: 'Matinée', value: 'AM' },
          { label: 'Après-midi', value: 'PM' },
        ]}
      />
    </Form.Item>
    <Form.Item label="Absence" name="absent" valuePropName="checked">
      <Checkbox>
        Cochez la case afin d'indiquer que l'étudiant était absent
      </Checkbox>
    </Form.Item>
    <Button htmlType="submit" icon={<SendOutlined />} type="primary">
      Envoyer
    </Button>
  </Form>
);

const ObligationsAdmin = () => {
  // Parameters
  const [date, setDate] = useState<any>(() => dayjs());
  const [category, setCategory] = useState(null);
  const [student, setStudent] = useState(undefined);
  // List
  const { isLoading, obligations, refetch } = useQueryObligations({
    variables: {
      date: date.format('YYYY-MM-DD'),
    },
  });
  const { absences } = useQueryAbsences({
    variables: {
      date: date.format('YYYY-MM-DD'),
    },
  });
  const { categories } = useQueryCategories();
  const { students } = useQueryStudents();
  // Create
  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(undefined);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const handleCreateOpen = useCallback(() => {
    setCreateLoading(false);
    setCreateError(undefined);
    setIsCreateOpen(true);
  }, []);
  const handleCreateClose = useCallback(() => {
    setCreateLoading(false);
    setCreateError(undefined);
    setIsCreateOpen(false);
  }, []);
  const mutationObligationCreate = useMutationObligationCreate();
  const handleCreate = useCallback(
    async ({ date, ...values }: any) => {
      try {
        setCreateLoading(true);
        setCreateError(undefined);
        await mutationObligationCreate({
          ...values,
          date: date.format('YYYY-MM-DD'),
        });
        await refetch();
        handleCreateClose();
        message.success("L'obligation a été créée.");
      } catch (err: any) {
        setCreateError(err.message);
      } finally {
        setCreateLoading(false);
      }
    },
    [handleCreateClose, mutationObligationCreate, refetch],
  );
  // Update
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState(undefined);
  const [isUpdateOpen, setIsUpdateOpen] = useState(undefined);
  const handleUpdateOpen = useCallback((obligation: any) => {
    setUpdateLoading(false);
    setUpdateError(undefined);
    setIsUpdateOpen(obligation);
  }, []);
  const handleUpdateClose = useCallback(() => {
    setUpdateLoading(false);
    setUpdateError(undefined);
    setIsUpdateOpen(undefined);
  }, []);
  const mutationObligationUpdate = useMutationObligationUpdate();
  const handleUpdate = useCallback(
    async ({ date, ...values }: any) => {
      try {
        setUpdateLoading(true);
        setUpdateError(undefined);
        await mutationObligationUpdate({
          ...values,
          date: date.format('YYYY-MM-DD'),
        });
        await refetch();
        handleUpdateClose();
        message.success("L'obligation a été mise à jour.");
      } catch (err: any) {
        setUpdateError(err.message);
      } finally {
        setUpdateLoading(false);
      }
    },
    [handleUpdateClose, mutationObligationUpdate, refetch],
  );
  // Reroll
  const mutationObligationReroll = useMutationObligationReroll();
  const handleReroll = useCallback(
    async (obligation: any) => {
      try {
        await mutationObligationReroll({
          id: obligation.id,
        });
        await refetch();
        message.success("L'obligation a été réaffectée.");
      } catch (err) {
        message.error("Une erreur s'est produite. Veuillez réessayer.");
      }
    },
    [mutationObligationReroll, refetch],
  );
  // Delete
  const mutationObligationDelete = useMutationObligationDelete();
  const handleDelete = useCallback(
    async (obligation: any) => {
      try {
        await mutationObligationDelete({
          id: obligation.id,
        });
        await refetch();
        message.success("L'obligation a été supprimée.");
      } catch (err) {
        message.error("Une erreur s'est produite. Veuillez réessayer.");
      }
    },
    [mutationObligationDelete, refetch],
  );
  // Download
  const [isDownloadOpen, setIsDownloadOpen] = useState<any>(undefined);
  const handleDownloadOpen = useCallback((obligation: any) => {
    setIsDownloadOpen(obligation);
  }, []);
  const handleDownloadClose = useCallback(() => {
    setIsDownloadOpen(undefined);
  }, []);
  const handleDownload = useCallback(
    async ({ from, to }: any) => {
      try {
        const blob = await GET(
          `${process.env.REACT_APP_URL}/api/obligations/extract`,
          {
            from: from.format('YYYY-MM-DD'),
            to: to.format('YYYY-MM-DD'),
          },
          'blob',
        );
        saveAs(blob, 'extraction.xlsx');
        handleDownloadClose();
      } catch (err: any) {
        //
      }
    },
    [handleDownloadClose],
  );
  // History
  const [isHistoryOpen, setIsHistoryOpen] = useState<any>(undefined);
  const handleHistoryOpen = useCallback((obligation: any) => {
    setIsHistoryOpen(obligation);
  }, []);
  const handleHistoryClose = useCallback(() => {
    setIsHistoryOpen(undefined);
  }, []);
  // Table
  const table = useFormatTable({
    obligations,
    absences,
    date,
    categories,
    category,
    student,
  });
  // Render
  return (
    <>
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Obligations</Typography.Title>
        </Col>
        <Col>
          <Space wrap>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={handleCreateOpen}
            >
              Ajouter une obligation
            </Button>
            <Button icon={<DownloadOutlined />} onClick={handleDownloadOpen}>
              Extraire
            </Button>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Space wrap>
        <Button onClick={() => setDate(dayjs())}>Aujourd'hui</Button>
        <Space>
          <Button
            onClick={() => setDate(date.subtract(1, 'week'))}
            icon={<LeftOutlined />}
          />
          <DatePicker
            picker="week"
            value={date}
            onChange={(v) => setDate(v)}
            format={(value) =>
              `${dayjs(value).startOf('week').format('DD MMMM YYYY')} ~ ${dayjs(
                value,
              )
                .endOf('week')
                .format('DD MMMM YYYY')}`
            }
          />
          <Button
            onClick={() => setDate(date.add(1, 'week'))}
            icon={<RightOutlined />}
          />
        </Space>
        <Select
          style={{ width: 200 }}
          allowClear
          options={[
            ...(categories || []).map((category: any) => ({
              label: category.name,
              value: category.id,
            })),
            {
              label: 'ABSENCE',
              value: 'ABSENCE',
            },
          ]}
          value={category}
          onChange={(v) => setCategory(v)}
          placeholder="Filter par catégorie"
        />
        <Select
          style={{ width: 200 }}
          allowClear
          options={[
            { label: 'VIDE', value: null },
            ...(students || []).map((student: any) => ({
              label: `${student.firstName} ${student.lastName}`,
              value: student.id,
            })),
          ]}
          value={student}
          onChange={(v) => setStudent(v)}
          placeholder="Filter par étudiant"
        />
      </Space>
      <Divider />
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <FormatTable
          table={table}
          as={Slot}
          handleDelete={handleDelete}
          handleReroll={handleReroll}
          handleUpdate={handleUpdateOpen}
          handleHistory={handleHistoryOpen}
        />
      )}
      {/* Create */}
      <Modal
        title="Ajouter une obligation"
        open={isCreateOpen}
        destroyOnClose
        footer={null}
        onCancel={handleCreateClose}
        width={800}
      >
        <Upsert
          categories={categories}
          students={students}
          handleSubmit={handleCreate}
          loading={createLoading}
          error={createError}
        />
      </Modal>
      {/* Update */}
      <Modal
        title="Modifier une obligation"
        open={!!isUpdateOpen}
        destroyOnClose
        footer={null}
        onCancel={handleUpdateClose}
        width={800}
      >
        <Upsert
          obligation={isUpdateOpen}
          categories={categories}
          students={students}
          handleSubmit={handleUpdate}
          loading={updateLoading}
          error={updateError}
        />
      </Modal>
      {/* History */}
      <Modal
        title="Historique"
        open={!!isHistoryOpen}
        destroyOnClose
        footer={null}
        onCancel={handleHistoryClose}
        width={800}
      >
        <History slot={isHistoryOpen} />
      </Modal>
      {/* Download */}
      <Modal
        title="Extraction"
        open={!!isDownloadOpen}
        destroyOnClose
        footer={null}
        onCancel={handleDownloadClose}
        width={800}
      >
        <Form
          initialValues={{
            from: dayjs() || null,
            to: dayjs() || null,
          }}
          layout="vertical"
          onFinish={handleDownload}
        >
          <Form.Item
            label="Date de début"
            name="from"
            rules={[
              {
                required: true,
                message: 'Veuillez renseigner une date de début.',
              },
            ]}
          >
            <DatePicker
              picker="week"
              format={(value) =>
                `${dayjs(value)
                  .startOf('week')
                  .format('DD MMMM YYYY')} ~ ${dayjs(value)
                  .endOf('week')
                  .format('DD MMMM YYYY')}`
              }
            />
          </Form.Item>
          <Form.Item
            label="Date de fin"
            name="to"
            rules={[
              {
                required: true,
                message: 'Veuillez renseigner une date de fin.',
              },
            ]}
          >
            <DatePicker
              picker="week"
              format={(value) =>
                `${dayjs(value)
                  .startOf('week')
                  .format('DD MMMM YYYY')} ~ ${dayjs(value)
                  .endOf('week')
                  .format('DD MMMM YYYY')}`
              }
            />
          </Form.Item>
          <Button htmlType="submit" icon={<SendOutlined />} type="primary">
            Envoyer
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default ObligationsAdmin;
