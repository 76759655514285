import { makeVar } from '@apollo/client';

const appVar = makeVar({
  hasToken: !!window.localStorage.getItem('token'),
  isAuthenticated: false,
});

export const appVarSetToken = (token: string) => {
  window.localStorage.setItem('token', token);
  appVar({
    hasToken: true,
    isAuthenticated: false,
  });
};

export const appVarLogin = () => {
  appVar({
    hasToken: true,
    isAuthenticated: true,
  });
};

export const appVarLogout = () => {
  window.localStorage.removeItem('token');
  appVar({
    hasToken: false,
    isAuthenticated: false,
  });
};

export default appVar;
