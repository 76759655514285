import { CheckOutlined, DeleteOutlined, StopOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import {
  useMutationAbsenceAccept,
  useMutationAbsenceDelete,
  useMutationAbsenceReject,
} from '../../gql/mutations/absences';
import { useQueryAbsences } from '../../gql/queries/absences';
import {
  renderTextChoices,
  searchTextChoices,
  sortDate,
  sortText,
} from '../../helpers/table';

const AbsencesAdmin = () => {
  // List
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 20,
      showSizeChanger: true,
    },
    filters: {},
    columnKey: 'date',
    order: undefined,
  });
  const handleTableChange = useCallback(
    (pagination: any, filters: any, sorter: any) => {
      setTableParams({
        pagination,
        filters,
        ...sorter,
      });
    },
    [],
  );
  const { isLoading, absences, nAbsences, refetch } = useQueryAbsences({
    variables: {
      pageCurrent: tableParams.pagination.current,
      pageSize: tableParams.pagination.pageSize,
      orderBy: tableParams.columnKey,
      orderSort: tableParams.order,
      filters: tableParams.filters,
    },
  });
  // Accept
  const mutationAbsenceAccept = useMutationAbsenceAccept();
  const handleAccept = useCallback(
    async (absence: any) => {
      try {
        await mutationAbsenceAccept({
          id: absence.id,
        });
        await refetch();
        message.success("La demande d'absence a été acceptée.");
      } catch (err) {
        message.error("Une erreur s'est produite. Veuillez réessayer.");
      }
    },
    [mutationAbsenceAccept, refetch],
  );
  // Reject
  const mutationAbsenceReject = useMutationAbsenceReject();
  const handleReject = useCallback(
    async (absence: any) => {
      try {
        await mutationAbsenceReject({
          id: absence.id,
        });
        await refetch();
        message.success("La demande d'absence a été refusée.");
      } catch (err) {
        message.error("Une erreur s'est produite. Veuillez réessayer.");
      }
    },
    [mutationAbsenceReject, refetch],
  );
  // Delete
  const mutationAbsenceDelete = useMutationAbsenceDelete();
  const handleDelete = useCallback(
    async (absence: any) => {
      try {
        await mutationAbsenceDelete({
          id: absence.id,
        });
        await refetch();
        message.success("La demande d'absence a été supprimée.");
      } catch (err) {
        message.error("Une erreur s'est produite. Veuillez réessayer.");
      }
    },
    [mutationAbsenceDelete, refetch],
  );
  // Columns
  const columns = [
    {
      title: 'Qui',
      key: 'who',
      render: (o: any) =>
        o.account ? `${o.account.firstName} ${o.account.lastName}` : 'VIDE',
    },
    {
      title: 'Date',
      key: 'date',
      render: (o: any) =>
        `${dayjs(o.date).format('DD MMM YYYY')} ${o.moment} (${o.timelaps})`,
      ...sortDate('date'),
    },
    {
      title: 'Justification',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Statut',
      dataIndex: 'status',
      key: 'status',
      ...renderTextChoices([
        { text: 'Demandée', value: 'request' },
        { text: 'Acceptée', value: 'accepted' },
        { text: 'Refusée', value: 'rejected' },
      ]),
      ...searchTextChoices('status', [
        { text: 'Demandée', value: 'request' },
        { text: 'Acceptée', value: 'accepted' },
        { text: 'Refusée', value: 'rejected' },
      ]),
      onFilter: undefined,
      ...sortText('status', 1),
    },
    {
      title: '',
      key: 'options',
      width: 100,
      render: (absence: any) => (
        <Space>
          {absence.status === 'request' && (
            <>
              <Popconfirm
                okText="Oui"
                onConfirm={() => handleAccept(absence)}
                title="Dernière chance. Êtes-vous sûr ?"
              >
                <Button size="small" icon={<CheckOutlined />} type="primary">
                  Accepter
                </Button>
              </Popconfirm>
              <Popconfirm
                okText="Oui"
                onConfirm={() => handleReject(absence)}
                title="Dernière chance. Êtes-vous sûr ?"
              >
                <Button size="small" icon={<StopOutlined />} danger>
                  Refuser
                </Button>
              </Popconfirm>
            </>
          )}
          <Popconfirm
            okText="Oui"
            onConfirm={() => handleDelete(absence)}
            title="Dernière chance. Êtes-vous sûr ?"
          >
            <Button size="small" icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  // Render
  return (
    <>
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Absences</Typography.Title>
        </Col>
      </Row>
      <Divider />
      <Table
        dataSource={absences}
        columns={columns}
        loading={isLoading}
        rowKey="id"
        bordered
        pagination={{
          ...tableParams.pagination,
          total: nAbsences,
        }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default AbsencesAdmin;
