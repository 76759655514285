import { CarryOutOutlined, ContainerOutlined } from '@ant-design/icons';
import { Alert, Card, Col, Divider, Row, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useQueryMe } from '../../gql/queries/me';
import { useQuerySheetsCountOpen } from '../../gql/queries/sheets';

const HomeAssistant = () => {
  const { me } = useQueryMe();
  const name = me ? me.firstName : '';
  const { sheetStatisticsCountOpen } = useQuerySheetsCountOpen();
  const stats = sheetStatisticsCountOpen || { newer: 0, older: 0 };
  return (
    <>
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Bonjour {name} !</Typography.Title>
        </Col>
      </Row>
      <Divider />
      {stats.newer > 0 && (
        <Alert
          description={
            <>
              Vous avez <b>{stats.newer} fiche(s)</b> de carnet de stage à
              valider.
            </>
          }
          type="info"
          showIcon
          action={<Link to="/sheets">Voir les fiches</Link>}
          style={{ marginBottom: 8 }}
        />
      )}
      {stats.older > 0 && (
        <Alert
          description={
            <>
              Vous avez <b>{stats.older} anciennes fiche(s)</b> de carnet de
              stage à valider.
            </>
          }
          type="warning"
          showIcon
          action={<Link to="/sheets">Voir les fiches</Link>}
        />
      )}
      <Typography.Title level={2}>Obligations</Typography.Title>
      <Space wrap>
        <Link to="/obligations">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<CarryOutOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Obligations"
              description="Voir les obligations"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
      </Space>
      <Typography.Title level={2}>Carnet de stage</Typography.Title>
      <Space wrap>
        <Link to="/sheets">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={
              <ContainerOutlined style={{ marginTop: 32, fontSize: 40 }} />
            }
          >
            <Card.Meta
              title="Fiches"
              description="Voir les fiches de stage"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
      </Space>
    </>
  );
};

export default HomeAssistant;
