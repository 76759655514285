import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  HistoryOutlined,
  SendOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Typography,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import FormSheetRenderer2 from '../../components/FormSheetRenderer2';
import {
  useMutationSheetDelete,
  useMutationSheetUpdate,
} from '../../gql/mutations/sheets';
import { useQueryAssistants } from '../../gql/queries/accounts';
import { useQuerySheets } from '../../gql/queries/sheets';
import {
  renderBool,
  renderTextChoices,
  searchBool,
  searchText,
  searchTextChoices,
  sortBool,
  sortDate,
  sortText,
} from '../../helpers/table';

const actions: { [key: string]: string } = {
  create: 'a créé la fiche',
  update: 'a mis à jour la fiche',
  'for-supervisor': 'a envoyé la fiche au superviseur',
  'for-student': "a renvoyé la fiche à l'étudiant",
  done: 'a validé la fiche',
};

const Upsert = ({
  sheet = {},
  assistants = [],
  handleSubmit,
  loading,
  error,
}: any) => {
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        id: sheet.id || undefined,
        assistantId: sheet.assistantId || undefined,
        status: sheet.status || 'draft',
        reference: sheet.reference || '',
        isExam: sheet.isExam || false,
        values: sheet.values || {},
        values2: sheet.values2 || {},
      }}
      onFinish={handleSubmit}
      disabled={loading}
    >
      <div className="sheet">
        {error && (
          <Alert
            type="error"
            message="Une erreur est survenue. Veuillez réessayer."
            banner
            className="error"
          />
        )}
        <Form.Item noStyle name="id" />
        <Form.Item noStyle name="status" />
        <div className="box gray">
          <p>
            <b>Étudiant :</b> {sheet.account.lastName},{' '}
            {sheet.account.firstName}
          </p>
          <p>
            <b>Date :</b>{' '}
            {sheet.date ? dayjs(sheet.date).format('DD MM YYYY') : ''}
          </p>
          {sheet.sheetTemplate.referenceType === 'numero_admin' && (
            <Form.Item
              label="Numéro administratif"
              name="reference"
              rules={[
                {
                  required: true,
                  message: 'Veuillez renseigner un numéro administratif.',
                },
              ]}
            >
              <Input placeholder="Numéro administratif" />
            </Form.Item>
          )}
          <Form.Item
            label="Assistant/superviseur assigné à la fiche"
            name="assistantId"
            rules={[
              {
                required: true,
                message: 'Veuillez renseigner un assistant/superviseur.',
              },
            ]}
          >
            <Select
              showSearch
              filterOption={(v, opt: any) =>
                opt.label.toLowerCase().includes(v.toLowerCase())
              }
              options={assistants.map((assistant: any) => ({
                label: `${assistant.firstName} ${assistant.lastName}`,
                value: assistant.id,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Marquer comme examen"
            name="isExam"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </div>
        <div className="box blue">
          <FormSheetRenderer2
            prefix="values"
            sheetTemplate={sheet.sheetTemplate.content}
          />
        </div>
        {sheet.status !== 'draft' && (
          <div className="box green">
            <FormSheetRenderer2
              prefix="values2"
              sheetTemplate={sheet.sheetTemplate.content2}
            />
          </div>
        )}
        {sheet.status !== 'draft' && sheet.sheetTemplate.computeGrade && (
          <div className="box">
            <b>Note calculée automatiquement : </b>
            {sheet.grade} / 20
          </div>
        )}
        <Space>
          {handleSubmit && (
            <>
              <Button
                icon={<SendOutlined />}
                type="primary"
                onClick={() => {
                  form.setFieldValue('status', 'done');
                  form.submit();
                }}
              >
                Valider
              </Button>
              <Button
                icon={<SendOutlined />}
                onClick={() => {
                  form.setFieldValue('status', 'for-student');
                  form.submit();
                }}
              >
                Renvoyer à l'étudiant
              </Button>
              <Button
                icon={<SendOutlined />}
                onClick={() => {
                  form.submit();
                }}
              >
                Sauvegarder sans valider
              </Button>
            </>
          )}
          <Popover
            content={sheet.history.map((h: any, i: number) => (
              <p key={i}>
                {h.byFirstName} {h.byLastName} {actions[h.action]} à{' '}
                {dayjs(h.date).format('DD MMM YYYY, HH:mm')}
              </p>
            ))}
            title="Historique"
          >
            <Button icon={<HistoryOutlined />}>Historique</Button>
          </Popover>
        </Space>
      </div>
    </Form>
  );
};

const SheetsSupervisor = () => {
  // List
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 20,
      showSizeChanger: true,
    },
    filters: {},
    columnKey: 'date',
    order: undefined,
  });
  const handleTableChange = useCallback(
    (pagination: any, filters: any, sorter: any) => {
      setTableParams({
        pagination,
        filters,
        ...sorter,
      });
    },
    [],
  );
  const [activeTab, setActiveTab] = useState('for-me');
  const handleTabChange = useCallback((tab: string) => {
    setActiveTab(tab);
    setTableParams((params: any) => ({
      ...params,
      pagination: {
        ...params.pagination,
        current: 1,
      },
    }));
  }, []);
  const {
    isLoading: isLoadingSheets,
    sheets,
    nSheets,
    refetch,
  } = useQuerySheets({
    variables: {
      pageCurrent: tableParams.pagination.current,
      pageSize: tableParams.pagination.pageSize,
      orderBy: tableParams.columnKey,
      orderSort: tableParams.order,
      filters: {
        ...tableParams.filters,
        status: activeTab,
      },
    },
  });
  const { assistants } = useQueryAssistants();
  // View
  const [isViewOpen, setIsViewOpen] = useState<any>(undefined);
  const handleViewOpen = useCallback((sheet: any) => {
    setIsViewOpen(sheet);
  }, []);
  const handleViewClose = useCallback(() => {
    setIsViewOpen(undefined);
  }, []);
  // Update
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState(undefined);
  const [isUpdateOpen, setIsUpdateOpen] = useState<any>(undefined);
  const handleUpdateOpen = useCallback((sheet: any) => {
    setUpdateLoading(false);
    setUpdateError(undefined);
    setIsUpdateOpen(sheet);
  }, []);
  const handleUpdateClose = useCallback(() => {
    setUpdateLoading(false);
    setUpdateError(undefined);
    setIsUpdateOpen(undefined);
  }, []);
  const mutationSheetUpdate = useMutationSheetUpdate();
  const handleUpdate = useCallback(
    async (values: any) => {
      try {
        setUpdateLoading(true);
        setUpdateError(undefined);
        await mutationSheetUpdate(values);
        await refetch();
        handleUpdateClose();
        message.success('La fiche a été mise à jour.');
      } catch (err: any) {
        setUpdateError(err.message);
      } finally {
        setUpdateLoading(false);
      }
    },
    [handleUpdateClose, mutationSheetUpdate, refetch],
  );
  // Delete
  const mutationSheetDelete = useMutationSheetDelete();
  const handleDelete = useCallback(
    async (sheet: any) => {
      try {
        await mutationSheetDelete({
          id: sheet.id,
        });
        await refetch();
        message.success('La fiche a été supprimée.');
      } catch (err) {
        message.error("Une erreur s'est produite. Veuillez réessayer.");
      }
    },
    [mutationSheetDelete, refetch],
  );
  // Columns
  const columns = [
    {
      title: 'Étudiant',
      key: 'student',
      render: ({ account }: any) => `${account.lastName}, ${account.firstName}`,
    },
    {
      title: 'Assistant/superviseur',
      key: 'assistant',
      render: ({ assistant }: any) =>
        assistant ? `${assistant.lastName}, ${assistant.firstName}` : '',
    },
    {
      title: 'Date du traitement',
      key: 'date',
      render: ({ date }: any) =>
        date ? dayjs(date).format('DD MMM YYYY') : '',
      ...sortDate('date', 1),
    },
    {
      title: 'Numéro administratif',
      dataIndex: 'reference',
      key: 'reference',
      ...sortText('reference', 2),
      ...searchText('reference'),
      onFilter: undefined,
    },
    {
      title: 'Modèle',
      key: 'sheetTemplate',
      render: ({ sheetTemplate }: any) =>
        `${sheetTemplate.department.name} - ${sheetTemplate.name}`,
    },
    {
      title: 'Statut',
      dataIndex: 'status',
      key: 'status',
      ...renderTextChoices([
        { text: 'Brouillon', value: 'draft' },
        { text: 'À voir par un superviseur', value: 'for-supervisor' },
        { text: "À compléter par l'étudiant", value: 'for-student' },
        { text: 'Validée', value: 'done' },
      ]),
      ...searchTextChoices('status', [
        { text: 'Brouillon', value: 'draft' },
        { text: 'À voir par un superviseur', value: 'for-supervisor' },
        { text: "À compléter par l'étudiant", value: 'for-student' },
        { text: 'Validée', value: 'done' },
      ]),
      onFilter: undefined,
      ...sortText('status', 3),
    },
    {
      title: 'Examen ?',
      dataIndex: 'isExam',
      key: 'isExam',
      ...renderBool(),
      ...sortBool('isExam', 4),
      ...searchBool('isExam'),
      onFilter: undefined,
    },
    {
      title: 'Note',
      key: 'grade',
      render: ({ values2 }: any) => values2.appreciation_globale_note || '',
    },
    {
      title: '',
      key: 'options',
      width: 100,
      render: (sheet: any) => (
        <Space>
          <Button
            icon={<EyeOutlined />}
            onClick={() => handleViewOpen(sheet)}
          />
          <Button
            icon={<EditOutlined />}
            onClick={() => handleUpdateOpen(sheet)}
            type="primary"
          />
          <Popconfirm
            okText="Oui"
            onConfirm={() => handleDelete(sheet)}
            title="Dernière chance. Êtes-vous sûr ?"
          >
            <Button icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  // Render
  return (
    <>
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Fiches</Typography.Title>
        </Col>
      </Row>
      <Divider />
      <Tabs
        activeKey={activeTab}
        onChange={handleTabChange}
        items={[
          {
            key: 'for-me',
            label: 'Fiches à voir (vous)',
            children: null,
          },
          {
            key: 'for-supervisor',
            label: 'Fiches à voir (assistants)',
            children: null,
          },
          {
            key: 'for-student',
            label: 'Fiches ouvertes',
            children: null,
          },
          {
            key: 'done',
            label: 'Fiches validées',
            children: null,
          },
        ]}
      />
      <Table
        dataSource={sheets}
        columns={columns}
        loading={isLoadingSheets}
        rowKey="id"
        bordered
        pagination={{
          ...tableParams.pagination,
          total: nSheets,
        }}
        onChange={handleTableChange}
      />
      {/* View */}
      <Modal
        title={
          isViewOpen
            ? `${isViewOpen.sheetTemplate.department.name} - ${isViewOpen.sheetTemplate.name}`
            : ''
        }
        open={!!isViewOpen}
        destroyOnClose
        footer={null}
        onCancel={handleViewClose}
        width="100%"
      >
        <Upsert sheet={isViewOpen} assistants={assistants} />
      </Modal>
      {/* Update */}
      <Modal
        title={
          isUpdateOpen
            ? `${isUpdateOpen.sheetTemplate.department.name} - ${isUpdateOpen.sheetTemplate.name}`
            : ''
        }
        open={!!isUpdateOpen}
        destroyOnClose
        footer={null}
        onCancel={handleUpdateClose}
        width="100%"
      >
        <Upsert
          sheet={isUpdateOpen}
          assistants={assistants}
          handleSubmit={handleUpdate}
          loading={updateLoading}
          error={updateError}
        />
      </Modal>
    </>
  );
};

export default SheetsSupervisor;
