import { useCallback, useEffect, useState } from 'react';

const MOBILE_MAX_SIZE = 900;

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_MAX_SIZE);
  const handleResize = useCallback(() => {
    if (window.innerWidth < MOBILE_MAX_SIZE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });
  return isMobile;
};

export default useIsMobile;
