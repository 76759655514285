import { gql, useQuery } from '@apollo/client';
import {
  FRAG_ACCOUNT,
  FRAG_DEPARTMENT,
  FRAG_SHEET,
  FRAG_SHEETTEMPLATE,
} from './fragments';

const QUERY_SHEETS = gql`
  query Sheets(
    $pageCurrent: Int
    $pageSize: Int
    $orderBy: String
    $orderSort: String
    $filters: JSON
  ) {
    sheets(
      pageCurrent: $pageCurrent
      pageSize: $pageSize
      orderBy: $orderBy
      orderSort: $orderSort
      filters: $filters
    ) {
      ...SheetData
      account {
        ...AccountData
      }
      assistant {
        ...AccountData
      }
      sheetTemplate {
        ...SheetTemplateData
        department {
          ...DepartmentData
        }
      }
    }
  }
  ${FRAG_SHEET}
  ${FRAG_ACCOUNT}
  ${FRAG_SHEETTEMPLATE}
  ${FRAG_DEPARTMENT}
`;

export const useQuerySheets = (options = {}) => {
  const { data, loading, ...props } = useQuery(QUERY_SHEETS, options);
  return {
    sheets: data?.sheets,
    nSheets: data?.sheets && data.sheets.length > 0 ? data.sheets[0].total : 0,
    isLoading: loading || !data,
    ...props,
  };
};

const QUERY_SHEET = gql`
  query sheet($id: ID!) {
    sheet(id: $id) {
      ...SheetData
      account {
        ...AccountData
      }
      assistant {
        ...AccountData
      }
      sheetTemplate {
        ...SheetTemplateData
        department {
          ...DepartmentData
        }
      }
    }
  }
  ${FRAG_SHEET}
  ${FRAG_ACCOUNT}
  ${FRAG_SHEETTEMPLATE}
  ${FRAG_DEPARTMENT}
`;

export const useQuerySheet = (options = {}) => {
  const { data, loading, ...props } = useQuery(QUERY_SHEET, options);
  return {
    sheet: data?.sheet,
    isLoading: loading || !data,
    ...props,
  };
};

const QUERY_SHEETS_STATISTICS_COUNT_OPEN = gql`
  query sheetStatisticsCountOpen {
    sheetStatisticsCountOpen
  }
`;

export const useQuerySheetsCountOpen = (options = {}) => {
  const { data, loading, ...props } = useQuery(
    QUERY_SHEETS_STATISTICS_COUNT_OPEN,
    options,
  );
  return {
    sheetStatisticsCountOpen: data?.sheetStatisticsCountOpen,
    isLoading: loading || !data,
    ...props,
  };
};

const QUERY_SHEETS_STATISTICS_ALL = gql`
  {
    sheetStatisticsAll
  }
`;

export const useQuerySheetsStatisticsAll = (options = {}) => {
  const { data, loading, ...props } = useQuery(
    QUERY_SHEETS_STATISTICS_ALL,
    options,
  );
  return {
    sheetStatisticsAll: data?.sheetStatisticsAll,
    isLoading: loading || !data,
    ...props,
  };
};

const QUERY_SHEETS_STATISTICS_GRADES = gql`
  {
    sheetStatisticsGrades
  }
`;

export const useQuerySheetsStatisticsGrades = (options = {}) => {
  const { data, loading, ...props } = useQuery(
    QUERY_SHEETS_STATISTICS_GRADES,
    options,
  );
  return {
    sheetStatisticsGrades: data?.sheetStatisticsGrades,
    isLoading: loading || !data,
    ...props,
  };
};

const QUERY_SHEET_STUDENT_STATS = gql`
  query sheetStudentStats($studentId: ID, $departmentId: ID) {
    sheetStudentStats(studentId: $studentId, departmentId: $departmentId)
  }
`;

export const useQuerySheetStudentStats = (options = {}) => {
  const { data, loading, ...props } = useQuery(
    QUERY_SHEET_STUDENT_STATS,
    options,
  );
  return {
    sheetStudentStats: data?.sheetStudentStats,
    isLoading: loading || !data,
    ...props,
  };
};
