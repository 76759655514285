import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const MUTATION_SHEETTEMPLATE_CREATE = gql`
  mutation SheetTemplateCreate(
    $departmentId: String!
    $name: String!
    $referenceType: String!
    $content: String!
    $content2: String!
    $instructions: String
    $displayOrder: Int
    $computeGrade: Boolean
    $gradeNotes: [Int!]
  ) {
    sheetTemplateCreate(
      departmentId: $departmentId
      name: $name
      referenceType: $referenceType
      content: $content
      content2: $content2
      instructions: $instructions
      displayOrder: $displayOrder
      computeGrade: $computeGrade
      gradeNotes: $gradeNotes
    ) {
      id
    }
  }
`;

export function useMutationSheetTemplateCreate(options = {}) {
  const [mutation] = useMutation(MUTATION_SHEETTEMPLATE_CREATE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_SHEETTEMPLATE_UPDATE = gql`
  mutation SheetTemplateUpdate(
    $id: ID!
    $departmentId: String
    $name: String
    $referenceType: String
    $content: String
    $content2: String
    $instructions: String
    $displayOrder: Int
    $computeGrade: Boolean
    $gradeNotes: [Int!]
  ) {
    sheetTemplateUpdate(
      id: $id
      departmentId: $departmentId
      name: $name
      referenceType: $referenceType
      content: $content
      content2: $content2
      instructions: $instructions
      displayOrder: $displayOrder
      computeGrade: $computeGrade
      gradeNotes: $gradeNotes
    ) {
      id
    }
  }
`;

export function useMutationSheetTemplateUpdate(options = {}) {
  const [mutation] = useMutation(MUTATION_SHEETTEMPLATE_UPDATE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_SHEETTEMPLATE_DELETE = gql`
  mutation SheetTemplateDelete($id: ID!) {
    sheetTemplateDelete(id: $id)
  }
`;

export function useMutationSheetTemplateDelete(options = {}) {
  const [mutation] = useMutation(MUTATION_SHEETTEMPLATE_DELETE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}
