import { gql, useQuery } from '@apollo/client';
import { FRAG_DEPARTMENT } from './fragments';

const QUERY_DEPARTMENTS = gql`
  query departments {
    departments {
      ...DepartmentData
    }
  }
  ${FRAG_DEPARTMENT}
`;

export const useQueryDepartments = (options = {}) => {
  const { data, loading, ...props } = useQuery(QUERY_DEPARTMENTS, options);
  return {
    departments: data?.departments,
    isLoading: loading || !data,
    ...props,
  };
};

const QUERY_DEPARTMENT = gql`
  query department($id: ID!) {
    department(id: $id) {
      ...DepartmentData
    }
  }
  ${FRAG_DEPARTMENT}
`;

export const useQueryDepartment = (options = {}) => {
  const { data, loading, ...props } = useQuery(QUERY_DEPARTMENT, options);
  return {
    department: data?.department,
    isLoading: loading || !data,
    ...props,
  };
};
