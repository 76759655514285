import Markdown from 'markdown-to-jsx';
import maker from './_maker';

const FormSheetRenderer2 = ({
  prefix = 'values',
  sheetTemplate = '',
  disabled = false,
}: any) => {
  // Custom components
  const overrides = {
    text(props: any) {
      return maker.text({ prefix, disabled, ...props });
    },
    number(props: any) {
      return maker.number({ prefix, disabled, ...props });
    },
    textarea(props: any) {
      return maker.textarea({ prefix, disabled, ...props });
    },
    select(props: any) {
      return maker.select({ prefix, disabled, ...props });
    },
    radiogroup(props: any) {
      return maker.radiogroup({ prefix, disabled, ...props });
    },
    radio(props: any) {
      return maker.radio({ prefix, disabled, ...props });
    },
    checkbox(props: any) {
      return maker.checkbox({ prefix, disabled, ...props });
    },
    table(props: any) {
      return <table>{props.children}</table>;
    },
    theader(props: any) {
      return maker.theader({ prefix, disabled, ...props });
    },
    trow(props: any) {
      return maker.trow({ prefix, disabled, ...props });
    },
  };
  return <Markdown options={{ overrides }}>{sheetTemplate}</Markdown>;
};

export default FormSheetRenderer2;
