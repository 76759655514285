import { DownloadOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Row,
  Space,
  Tabs,
  Typography,
} from 'antd';
import * as d3 from 'd3';
import { saveAs } from 'file-saver';
import { useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  useQuerySheetsStatisticsAll,
  useQuerySheetsStatisticsGrades,
} from '../../gql/queries/sheets';
import { GET } from '../../helpers/request';

const margin = {
  bottom: 100,
  left: 270,
  right: 0,
  top: 200,
};

const colorMapping: { [key: string]: string } = {
  A: 'green',
  B: 'lightgreen',
  C: 'yellow',
  D: 'orange',
  E: 'red',
  NA: 'blue',
  '': 'gray',
};

const All = () => {
  const { isLoading, sheetStatisticsAll } = useQuerySheetsStatisticsAll();
  const ref = useRef(null);
  useEffect(() => {
    if (!ref || !ref.current || isLoading || !sheetStatisticsAll) {
      return;
    }
    const width =
      sheetStatisticsAll.sheetTemplates.length * 30 +
      margin.left +
      margin.right;
    const height =
      sheetStatisticsAll.students.length * 30 + margin.top + margin.bottom;
    // SVG
    const svg = d3
      .select(ref.current)
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height]);
    svg.selectAll('*').remove();
    // Axis
    const xAxis = d3
      .scaleBand()
      .domain(sheetStatisticsAll.sheetTemplates.map((c: any) => c.id))
      .range([margin.left, width - margin.right])
      .paddingInner(0.15);
    const yAxis = d3
      .scaleBand()
      .domain(sheetStatisticsAll.students.map((s: any) => s.id))
      .range([margin.top, height - margin.bottom])
      .paddingInner(0.15);
    // Categories
    svg
      .append('g')
      .attr('transform', `translate(0, ${margin.top}) rotate(-90)`)
      .selectAll('text')
      .data(sheetStatisticsAll.sheetTemplates)
      .join('text')
      .attr('class', 'matrix-label')
      .attr('x', 5)
      .attr('y', (c: any) => (xAxis(c.id) || 0) + xAxis.bandwidth() / 2)
      .text((s: any) => s.name)
      .attr('dy', '0.35em');
    // Students
    svg
      .append('g')
      .selectAll('text')
      .data(sheetStatisticsAll.students)
      .join('text')
      .attr('class', 'matrix-label')
      .attr('x', margin.left - 5)
      .attr('y', (s: any) => (yAxis(s.id) || 0) + yAxis.bandwidth() / 2)
      .text((c: any) => `${c.firstName} ${c.lastName}`)
      .attr('dy', '0.35em')
      .attr('text-anchor', 'end');
    // Dots
    svg
      .append('g')
      .selectAll('g')
      .data(sheetStatisticsAll.sheets)
      .join('g')
      .each(function makeCell(data) {
        d3.select(this)
          .append('rect')
          .attr('class', 'matrix-cell')
          .attr('x', (o: any) => xAxis(o.sheetTemplateId) || 0)
          .attr('y', (o: any) => yAxis(o.accountId) || 0)
          .attr('width', xAxis.bandwidth())
          .attr('height', yAxis.bandwidth())
          .attr('rx', 5)
          .attr('fill', 'lightblue');
        d3.select(this)
          .append('text')
          .attr('class', 'matrix-text')
          .attr(
            'x',
            (o: any) => (xAxis(o.sheetTemplateId) || 0) + xAxis.bandwidth() / 2,
          )
          .attr(
            'y',
            (o: any) => (yAxis(o.accountId) || 0) + yAxis.bandwidth() / 2 + 4,
          )
          .attr('text-anchor', 'middle')
          .text((o: any) => o.count);
      });
  }, [ref, ref.current, isLoading, sheetStatisticsAll]);
  return (
    <div className="matrix">
      <svg ref={ref} />
    </div>
  );
};

const Grades = () => {
  const { isLoading, sheetStatisticsGrades } = useQuerySheetsStatisticsGrades();
  const ref = useRef(null);
  useEffect(() => {
    if (!ref || !ref.current || isLoading || !sheetStatisticsGrades) {
      return;
    }
    const width =
      sheetStatisticsGrades.grades.length * 30 + margin.left + margin.right;
    const height =
      sheetStatisticsGrades.students.length * 30 + margin.top + margin.bottom;
    // SVG
    const svg = d3
      .select(ref.current)
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height]);
    svg.selectAll('*').remove();
    // Axis
    const xAxis = d3
      .scaleBand()
      .domain(sheetStatisticsGrades.grades)
      .range([margin.left, width - margin.right])
      .paddingInner(0.15);
    const yAxis = d3
      .scaleBand()
      .domain(sheetStatisticsGrades.students.map((s: any) => s.id))
      .range([margin.top, height - margin.bottom])
      .paddingInner(0.15);
    // Categories
    svg
      .append('g')
      .attr('transform', `translate(0, ${margin.top}) rotate(-90)`)
      .selectAll('text')
      .data(sheetStatisticsGrades.grades)
      .join('text')
      .attr('class', 'matrix-label')
      .attr('x', 5)
      .attr('y', (c: any) => (xAxis(c) || 0) + xAxis.bandwidth() / 2)
      .text((s: any) => s)
      .attr('dy', '0.35em');
    // Students
    svg
      .append('g')
      .selectAll('text')
      .data(sheetStatisticsGrades.students)
      .join('text')
      .attr('class', 'matrix-label')
      .attr('x', margin.left - 5)
      .attr('y', (s: any) => (yAxis(s.id) || 0) + yAxis.bandwidth() / 2)
      .text((c: any) => `${c.firstName} ${c.lastName}`)
      .attr('dy', '0.35em')
      .attr('text-anchor', 'end');
    // Dots
    svg
      .append('g')
      .selectAll('g')
      .data(sheetStatisticsGrades.sheets)
      .join('g')
      .each(function makeCell(data) {
        d3.select(this)
          .append('rect')
          .attr('class', 'matrix-cell')
          .attr('x', (o: any) => xAxis(o.grade) || 0)
          .attr('y', (o: any) => yAxis(o.accountId) || 0)
          .attr('width', xAxis.bandwidth())
          .attr('height', yAxis.bandwidth())
          .attr('rx', 5)
          .attr('fill', (o: any) => colorMapping[o.grade] || 'gray');
        d3.select(this)
          .append('text')
          .attr('class', 'matrix-text')
          .attr('x', (o: any) => (xAxis(o.grade) || 0) + xAxis.bandwidth() / 2)
          .attr(
            'y',
            (o: any) => (yAxis(o.accountId) || 0) + yAxis.bandwidth() / 2 + 4,
          )
          .attr('text-anchor', 'middle')
          .text((o: any) => o.count);
      });
  }, [ref, ref.current, isLoading, sheetStatisticsGrades]);
  return (
    <div className="matrix">
      <svg ref={ref} />
    </div>
  );
};

const SheetsStatistics = () => {
  // Download
  const handleDownload = useCallback(async () => {
    try {
      const blob = await GET(
        `${process.env.REACT_APP_URL}/api/sheets/extract`,
        {},
        'blob',
      );
      saveAs(blob, 'fiches.xlsx');
    } catch (err: any) {
      //
    }
  }, []);
  return (
    <>
      <Breadcrumb
        items={[
          { title: <Link to="/statistics">Statistiques</Link> },
          { title: <Link to="/statistics/sheets">Fiches</Link> },
        ]}
      />
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Statistiques sur les fiches</Typography.Title>
        </Col>
        <Col>
          <Space wrap>
            <Button icon={<DownloadOutlined />} onClick={handleDownload}>
              Extraire
            </Button>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Tabs
        defaultActiveKey="all"
        items={[
          {
            key: 'all',
            label: 'Quota',
            children: <All />,
          },
          {
            key: 'grades',
            label: 'Notes',
            children: <Grades />,
          },
        ]}
      />
    </>
  );
};

export default SheetsStatistics;
