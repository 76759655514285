import { Divider, Table, Tabs, Typography } from 'antd';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { searchText, sortNumber, sortText } from '../../helpers/table';

function displayABCDNADemo(stat: any) {
  return `(A : ${stat.A}, B : ${stat.B}, C : ${stat.C}, D : ${stat.D}, N/A : ${stat['N/A']}, Démo : ${stat.Démo})`;
}

function displayABCDDemo(stat: any) {
  return `(A : ${stat.A}, B : ${stat.B}, C : ${stat.C}, D : ${stat.D}, Démo : ${stat.Démo})`;
}

function displayABCD(stat: any) {
  return `(A : ${stat.A}, B : ${stat.B}, C : ${stat.C}, D : ${stat.D})`;
}

const Stats = ({ isLoading, sheetStudentStats }: any) => {
  const columnsGrades = [
    {
      title: 'Type de fiche',
      dataIndex: 'templateName',
      key: 'templateName',
      ...sortText('templateName'),
      ...searchText('templateName'),
    },
    {
      title: 'A',
      dataIndex: 'grade_A',
      key: 'grade_A',
      ...sortNumber('grade_A'),
    },
    {
      title: 'B',
      dataIndex: 'grade_B',
      key: 'grade_B',
      ...sortNumber('grade_B'),
    },
    {
      title: 'C',
      dataIndex: 'grade_C',
      key: 'grade_C',
      ...sortNumber('grade_C'),
    },
    {
      title: 'D',
      dataIndex: 'grade_D',
      key: 'grade_D',
      ...sortNumber('grade_D'),
    },
    {
      title: 'E',
      dataIndex: 'grade_E',
      key: 'grade_E',
      ...sortNumber('grade_E'),
    },
    {
      title: 'N/A',
      dataIndex: 'grade_N/A',
      key: 'grade_N/A',
      ...sortNumber('grade_N/A'),
    },
    {
      title: 'Démo',
      dataIndex: 'grade_Démo',
      key: 'grade_Démo',
      ...sortNumber('grade_Démo'),
    },
    {
      title: 'Sans note',
      dataIndex: 'grade_',
      key: 'grade_',
      ...sortNumber('grade_'),
    },
  ];
  return (
    <>
      <Typography.Title level={3}>Notes</Typography.Title>
      <Table
        dataSource={sheetStudentStats?.grades}
        columns={columnsGrades}
        loading={isLoading}
        pagination={false}
        rowKey="id"
        bordered
        summary={(d) => {
          let total_A = 0;
          let total_B = 0;
          let total_C = 0;
          let total_D = 0;
          let total_E = 0;
          let total_NA = 0;
          let total_Demo = 0;
          let total_ = 0;
          d.forEach((c) => {
            total_A += c.grade_A;
            total_B += c.grade_B;
            total_C += c.grade_C;
            total_D += c.grade_D;
            total_E += c.grade_E;
            total_NA += c['grade_N/A'];
            total_Demo += c['grade_Démo'];
            total_ += c.grade_;
          });
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <b>Total</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <b>{total_A}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                <b>{total_B}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <b>{total_C}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                <b>{total_D}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <b>{total_E}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}>
                <b>{total_NA}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                <b>{total_Demo}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8}>
                <b>{total_}</b>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
      <Divider />
      <Typography.Title level={3}>Évolution</Typography.Title>
      {!isLoading && (
        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            width={500}
            height={300}
            data={sheetStudentStats.graphs}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" label="Mois" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="grade_A" stackId="a" name="A" fill="#619b8a" />
            <Bar dataKey="grade_B" stackId="a" name="B" fill="#a1c181" />
            <Bar dataKey="grade_C" stackId="a" name="C" fill="#fcca46" />
            <Bar dataKey="grade_D" stackId="a" name="D" fill="#fe7f2d" />
            <Bar dataKey="grade_E" stackId="a" name="E" fill="#f94144" />
            <Bar dataKey="grade_N/A" stackId="a" name="N/A" fill="#233d4d" />
            <Bar dataKey="grade_Démo" stackId="a" name="Démo" fill="#6c584c" />
            <Bar dataKey="grade_" stackId="a" name="?" fill="#878787" />
          </BarChart>
        </ResponsiveContainer>
      )}
      <Divider />
      <Typography.Title level={3}>Carnets</Typography.Title>
      {!isLoading && (
        <Tabs
          items={[
            {
              key: '1',
              label: 'DO',
              children: (
                <div className="res-table">
                  <table>
                    <tr>
                      <th>Fiche</th>
                      <th>Total</th>
                      <th>Autonome / Non validé</th>
                      <th>Note</th>
                    </tr>
                    {Object.entries(sheetStudentStats.summary.DO2).map(
                      ([name, res]: [string, any]) => (
                        <tr key={name}>
                          <td>{name}</td>
                          <td>{res.total}</td>
                          <td>
                            {!res.examen && res.good} / {!res.examen && res.bad}
                          </td>
                          <td>{res.examen && res.note !== -1 && res.note}</td>
                        </tr>
                      ),
                    )}
                  </table>
                </div>
              ),
            },
            // {
            //   key: '1',
            //   label: 'DO',
            //   children: (
            //     <div className="res-table">
            //       <table>
            //         {Object.entries(sheetStudentStats.summary.DO).map(
            //           ([name, res]: [string, any]) => (
            //             <tr key={name}>
            //               <td>{name}</td>
            //               <td>
            //                 {res.total} {res.needed > 0 && `/ ${res.needed}`}
            //               </td>
            //               <td>{!res.noDetails && displayABCDDemo(res)}</td>
            //             </tr>
            //           ),
            //         )}
            //       </table>
            //     </div>
            //   ),
            // },
            {
              key: '2',
              label: 'PEDO',
              children: (
                <>
                  <div className="res-table">
                    <table>
                      <tr>
                        <th>Fiche</th>
                        <th>Total</th>
                        <th>Autonome / Non validé</th>
                        <th>Note</th>
                      </tr>
                      {Object.entries(sheetStudentStats.summary.PEDO2)
                        .filter(([name, res]: any) => 'good' in res)
                        .map(([name, res]: [string, any]) => (
                          <tr key={name}>
                            <td>{name}</td>
                            <td>{res.total}</td>
                            <td>
                              {!res.examen && res.good} /{' '}
                              {!res.examen && res.bad}
                            </td>
                            <td>{res.examen && res.note !== -1 && res.note}</td>
                          </tr>
                        ))}
                    </table>
                  </div>
                  <div className="res-table">
                    <table>
                      {Object.entries(sheetStudentStats.summary.PEDO2)
                        .filter(([name, res]: any) => 'A' in res)
                        .map(([name, res]: [string, any]) => (
                          <tr key={name}>
                            <td>{name}</td>
                            <td>
                              {res.total} {res.needed > 0 && `/ ${res.needed}`}
                            </td>
                            <td>{!res.noDetails && displayABCD(res)}</td>
                          </tr>
                        ))}
                    </table>
                  </div>
                </>
              ),
            },
            {
              key: '3',
              label: 'PARO',
              children: (
                <div className="res-table">
                  <table>
                    {Object.entries(sheetStudentStats.summary.PARO).map(
                      ([name, res]: [string, any]) => (
                        <tr key={name}>
                          <td>{name}</td>
                          <td>
                            {res.total} {res.needed > 0 && `/ ${res.needed}`}
                          </td>
                          <td>{!res.noDetails && displayABCD(res)}</td>
                        </tr>
                      ),
                    )}
                  </table>
                </div>
              ),
            },
            {
              key: '4',
              label: 'ORTHO',
              children: (
                <div className="res-table">
                  <table>
                    {Object.entries(sheetStudentStats.summary.ORTHO).map(
                      ([name, res]: [string, any]) => (
                        <tr key={name}>
                          <td>{name}</td>
                          <td>
                            {res.total} {res.needed > 0 && `/ ${res.needed}`}
                          </td>
                          <td>{!res.noDetails && displayABCD(res)}</td>
                        </tr>
                      ),
                    )}
                  </table>
                </div>
              ),
            },
            {
              key: '5',
              label: 'STOM',
              children: (
                <div className="res-table">
                  <table>
                    <tr>
                      <td>Obligations et assistances EDMS SL</td>
                      <td>
                        {
                          sheetStudentStats.summary.STOM[
                            'Obligations et assistances EDMS SL (1)'
                          ].total
                        }
                      </td>
                      <td>
                        <p>
                          Technique
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              'Obligations et assistances EDMS SL (1)'
                            ],
                          )}
                        </p>
                        <p>
                          Asepsie
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              'Obligations et assistances EDMS SL (2)'
                            ],
                          )}
                        </p>
                        <p>
                          Comportement
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              'Obligations et assistances EDMS SL (3)'
                            ],
                          )}
                        </p>
                        <p>
                          Appréciations globales
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              'Obligations et assistances EDMS SL (4)'
                            ],
                          )}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>Obligations et assistances EDMS étage et urgence</td>
                      <td>
                        {
                          sheetStudentStats.summary.STOM[
                            'Obligations et assistances EDMS étage et urgence (1)'
                          ].total
                        }
                      </td>
                      <td>
                        <p>
                          Technique
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              'Obligations et assistances EDMS étage et urgence (1)'
                            ],
                          )}
                        </p>
                        <p>
                          Asepsie
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              'Obligations et assistances EDMS étage et urgence (2)'
                            ],
                          )}
                        </p>
                        <p>
                          Comportement
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              'Obligations et assistances EDMS étage et urgence (3)'
                            ],
                          )}
                        </p>
                        <p>
                          Appréciations globales
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              'Obligations et assistances EDMS étage et urgence (4)'
                            ],
                          )}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>Obligations et consultations</td>
                      <td>
                        {
                          sheetStudentStats.summary.STOM[
                            'Obligations et consultations (1)'
                          ].total
                        }
                      </td>
                      <td>
                        <p>
                          Technique
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              'Obligations et consultations (1)'
                            ],
                          )}
                        </p>
                        <p>
                          Asepsie
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              'Obligations et consultations (2)'
                            ],
                          )}
                        </p>
                        <p>
                          Comportement
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              'Obligations et consultations (3)'
                            ],
                          )}
                        </p>
                        <p>
                          Appréciations globales
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              'Obligations et consultations (4)'
                            ],
                          )}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>Obligations et consultations</td>
                      <td>
                        {
                          sheetStudentStats.summary.STOM[
                            'Obligations et consultations (1)'
                          ].total
                        }
                      </td>
                      <td>
                        <p>
                          Technique
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              'Obligations et consultations (1)'
                            ],
                          )}
                        </p>
                        <p>
                          Asepsie
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              'Obligations et consultations (2)'
                            ],
                          )}
                        </p>
                        <p>
                          Comportement
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              'Obligations et consultations (3)'
                            ],
                          )}
                        </p>
                        <p>
                          Appréciations globales
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              'Obligations et consultations (4)'
                            ],
                          )}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>Obligations et salle d'op</td>
                      <td>
                        {
                          sheetStudentStats.summary.STOM[
                            "Obligations et salle d'op (1)"
                          ].total
                        }
                      </td>
                      <td>
                        <p>
                          Technique
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              "Obligations et salle d'op (1)"
                            ],
                          )}
                        </p>
                        <p>
                          Asepsie
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              "Obligations et salle d'op (2)"
                            ],
                          )}
                        </p>
                        <p>
                          Comportement
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              "Obligations et salle d'op (3)"
                            ],
                          )}
                        </p>
                        <p>
                          Appréciations globales
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              "Obligations et salle d'op (4)"
                            ],
                          )}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>Séances d'extraction</td>
                      <td>
                        {
                          sheetStudentStats.summary.STOM[
                            "Séances d'extraction (1)"
                          ].total
                        }
                      </td>
                      <td>
                        <p>
                          Technique
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              "Séances d'extraction (1)"
                            ],
                          )}
                        </p>
                        <p>
                          Asepsie
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              "Séances d'extraction (2)"
                            ],
                          )}
                        </p>
                        <p>
                          Comportement
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              "Séances d'extraction (3)"
                            ],
                          )}
                        </p>
                        <p>
                          Appréciations globales
                          <br />
                          {displayABCDNADemo(
                            sheetStudentStats.summary.STOM[
                              "Séances d'extraction (4)"
                            ],
                          )}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>Dents extraites</td>
                      <td>
                        {
                          sheetStudentStats.summary.STOM['Dents extraites']
                            .total
                        }
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Attitudes non professionnelles</td>
                      <td>
                        {
                          sheetStudentStats.summary.STOM[
                            'Attitudes non professionnelles'
                          ].total
                        }
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Absences non justifiées</td>
                      <td>
                        {
                          sheetStudentStats.summary.STOM[
                            'Absences non justifiées'
                          ].total
                        }
                      </td>
                      <td></td>
                    </tr>
                  </table>
                </div>
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default Stats;
