import {
  CarryOutOutlined,
  ContainerOutlined,
  RobotOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Card, Col, Divider, Row, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';

const StatisticsHome = () => {
  return (
    <>
      <Breadcrumb
        items={[{ title: <Link to="/statistics">Statistiques</Link> }]}
      />
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Statistiques</Typography.Title>
        </Col>
      </Row>
      <Divider />
      <Space wrap>
        <Link to="/statistics/obligations">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<CarryOutOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Obligations"
              description="Statistiques sur les obligations"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
        <Link to="/statistics/takeovers">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<SwapOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Transferts"
              description="Statistiques sur les transferts"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
        <Link to="/statistics/absences">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<RobotOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Absences"
              description="Statistiques sur les absences"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
        <Link to="/statistics/sheets">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={
              <ContainerOutlined style={{ marginTop: 32, fontSize: 40 }} />
            }
          >
            <Card.Meta
              title="Fiches"
              description="Statistiques sur les fiches"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
      </Space>
    </>
  );
};

export default StatisticsHome;
