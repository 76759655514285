import { Alert, Button, Form, Input, Typography } from 'antd';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { appVarSetToken } from '../../gql/appVar';
import { useMutationAuthLogin } from '../../gql/mutations/auth';

const Login = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [errorCode, setErrorCode] = useState(undefined);
  const mutationAuthLogin = useMutationAuthLogin();
  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        setSubmitting(true);
        setErrorCode(undefined);
        const response = await mutationAuthLogin(values);
        appVarSetToken(response.data.authLogin);
      } catch (err: any) {
        setErrorCode(err.message);
      } finally {
        setSubmitting(false);
      }
    },
    [mutationAuthLogin],
  );
  return (
    <Form
      className="container-small"
      layout="vertical"
      initialValues={{
        email: '',
        password: '',
      }}
      onFinish={handleSubmit}
      disabled={isSubmitting}
    >
      <Typography.Title className="title">
        Accès au portail de l'EMDS
      </Typography.Title>
      {errorCode === '404' ? (
        <Alert
          type="error"
          message="L'adresse email renseignée n'est liée à aucun compte."
          banner
          className="error"
        />
      ) : errorCode === '401' ? (
        <Alert
          type="error"
          message="La combinaison adresse email/mot de passe est incorrecte."
          banner
          className="error"
        />
      ) : (
        errorCode && (
          <Alert
            type="error"
            message="Une erreur est survenue. Veuillez réessayer."
            banner
            className="error"
          />
        )
      )}
      <Form.Item
        label="Adresse email"
        name="email"
        rules={[
          {
            required: true,
            type: 'email',
            message: 'Veuillez renseigner une adresse email.',
          },
        ]}
      >
        <Input type="email" placeholder="Adresse email" />
      </Form.Item>
      <Form.Item
        label="Mot de passe"
        name="password"
        rules={[
          { required: true, message: 'Veuillez renseigner un mot de passe.' },
        ]}
      >
        <Input type="password" placeholder="Mot de passe" />
      </Form.Item>
      <div className="login-recover">
        <Link to="/recover-password">Mot de passe oublié</Link>
      </div>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Se connecter
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Login;
