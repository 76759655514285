import { Col, Divider, Row, Select, Space, Typography } from 'antd';
import { useState } from 'react';
import { useQueryDepartments } from '../../gql/queries/departments';
import { useQuerySheetStudentStats } from '../../gql/queries/sheets';
import Stats from './_Stats';

const ProgressStudent = () => {
  const [departmentId, setDepartmentId] = useState(undefined);
  const { departments } = useQueryDepartments();
  const { isLoading, sheetStudentStats } = useQuerySheetStudentStats({
    variables: {
      departmentId,
    },
  });
  return (
    <>
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Avancement</Typography.Title>
        </Col>
      </Row>
      <Divider />
      <Space wrap>
        <b>Sélectionnez une discipline</b>
        <Select
          style={{ width: 200 }}
          allowClear
          options={(departments || []).map((department: any) => ({
            label: department.name,
            value: department.id,
          }))}
          value={departmentId}
          onChange={(v) => setDepartmentId(v)}
          placeholder="Discipline"
        />
      </Space>
      <Divider />
      <Stats isLoading={isLoading} sheetStudentStats={sheetStudentStats} />
    </>
  );
};

export default ProgressStudent;
