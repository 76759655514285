import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Input, Space, Tag } from 'antd';
import dayjs from 'dayjs';

// SORT

export const sortText = (dataIndex: string, multiple = 1) => ({
  sorter: {
    compare: (a: any, b: any) =>
      a[dataIndex].localeCompare(b[dataIndex], 'en', {
        sensitivity: 'base',
        ignorePunctuation: true,
        numeric: true,
      }),
  },
});

export const sortNumber = (dataIndex: string, multiple = 1) => ({
  sorter: {
    compare: (a: any, b: any) => a[dataIndex] - b[dataIndex],
  },
});

export const sortDate = (dataIndex: string, multiple = 1) => ({
  sorter: {
    compare: (a: any, b: any) =>
      new Date(a[dataIndex]).getTime() - new Date(b[dataIndex]).getTime(),
  },
});

export const sortBool = (dataIndex: string, multiple = 1) => ({
  sorter: {
    compare: (a: any, b: any) =>
      (a[dataIndex] ? 1 : 0) - (b[dataIndex] ? 1 : 0),
  },
});

// Search

export const searchText = (dataIndex: string) => ({
  filterDropdown: ({
    clearFilters,
    confirm,
    selectedKeys,
    setSelectedKeys,
  }: any) => (
    <div
      style={{
        padding: 8,
      }}
    >
      <Input
        placeholder="..."
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={confirm}
        style={{
          width: 210,
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          icon={<SearchOutlined />}
          onClick={confirm}
          size="small"
          style={{
            width: 101,
          }}
          type="primary"
        >
          Chercher
        </Button>
        <Button
          onClick={clearFilters}
          size="small"
          style={{
            width: 101,
          }}
        >
          Effacer
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: any) => (
    <SearchOutlined
      style={{
        color: filtered ? '#1890ff' : undefined,
      }}
    />
  ),
  onFilter: (value: any, record: any) =>
    record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : '',
});

export const searchTextChoices = (dataIndex: string, choices: any[]) => ({
  filters: choices,
  filterMultiple: false,
  onFilter: (value: any, record: any) => record[dataIndex] === value,
});

export const searchTextArray = (dataIndex: string) => ({
  filterDropdown: ({
    clearFilters,
    confirm,
    selectedKeys,
    setSelectedKeys,
  }: any) => (
    <div
      style={{
        padding: 8,
      }}
    >
      <Input
        placeholder="..."
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={confirm}
        style={{
          width: 188,
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          icon={<SearchOutlined />}
          onClick={confirm}
          size="small"
          style={{
            width: 90,
          }}
          type="primary"
        >
          Search
        </Button>
        <Button
          onClick={clearFilters}
          size="small"
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: any) => (
    <SearchOutlined
      style={{
        color: filtered ? '#1890ff' : undefined,
      }}
    />
  ),
  onFilter: (value: any, record: any) =>
    record[dataIndex]
      ? record[dataIndex].some((v: any) =>
          v.toString().toLowerCase().includes(value.toLowerCase()),
        )
      : '',
});

export const searchBool = (dataIndex: string) => ({
  filters: [
    {
      text: 'Oui',
      value: 'y',
    },
    {
      text: 'Non',
      value: 'n',
    },
  ],
  filterMultiple: false,
  onFilter: (value: any, record: any) =>
    (value === 'y' && record[dataIndex]) ||
    (value === 'n' && !record[dataIndex]),
});

// Render

export const renderTextChoices = (choices: any[]) => ({
  render: (choice: string) =>
    choices.find((c) => c.value === choice)?.text || choice,
});

export const renderTextArray = () => ({
  render: (arr: string[]) => arr.map((a, index) => <Tag key={index}>{a}</Tag>),
});

export const renderDate = () => ({
  render: (d: Date) => dayjs(d).format('YYYY-MM-DD'),
});

export const renderBool = () => ({
  render: (b: Boolean) =>
    b ? (
      <Tag icon={<CheckCircleOutlined />} color="success">
        Oui
      </Tag>
    ) : (
      <Tag icon={<CloseCircleOutlined />} color="error">
        Non
      </Tag>
    ),
});
