import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SendOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from 'antd';
import { useCallback, useState } from 'react';
import {
  useMutationGroupCreate,
  useMutationGroupDelete,
  useMutationGroupUpdate,
} from '../../gql/mutations/groups';
import { useQueryCategories } from '../../gql/queries/categories';
import { useQueryGroups } from '../../gql/queries/groups';
import { searchText, sortText } from '../../helpers/table';

const AVAILABILITY_LABELS = [
  'Lun AM',
  'Lun PM',
  'Mar AM',
  'Mar PM',
  'Mer AM',
  'Mer PM',
  'Jeu AM',
  'Jeu PM',
  'Ven AM',
  'Ven PM',
  'Sam AM',
  'Sam PM',
  'Dim AM',
  'Dim PM',
];

const Upsert = ({
  group = {},
  categories = [],
  handleSubmit,
  loading,
  error,
}: any) => {
  return (
    <Form
      initialValues={{
        id: group.id || undefined,
        name: group.name || '',
        categoryIds: group.categoryIds || [],
        availability: group.availability || [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
        ],
      }}
      layout="vertical"
      onFinish={handleSubmit}
      disabled={loading}
    >
      {error === '409' ? (
        <Alert
          type="error"
          message="Un groupe avec le même nom existe déjà. Veuillez en choisir un autre."
          banner
          className="error"
        />
      ) : (
        error && (
          <Alert
            type="error"
            message="Une erreur est survenue. Veuillez réessayer."
            banner
            className="error"
          />
        )
      )}
      <Form.Item noStyle name="id" />
      <Form.Item
        label="Nom du groupe"
        name="name"
        rules={[{ required: true, message: 'Veuillez renseigner un nom.' }]}
      >
        <Input placeholder="MA1 sans TEC - 2023-2024" />
      </Form.Item>
      <Form.Item
        label="Catégories pour lesquelles les utilisateurs peuvent pratiquer"
        name="categoryIds"
      >
        <Select
          mode="multiple"
          allowClear
          showSearch
          filterOption={(v, opt: any) =>
            opt.label.toLowerCase().includes(v.toLowerCase())
          }
          options={categories.map((category: any) => ({
            label: category.name,
            value: category.id,
          }))}
        />
      </Form.Item>
      <Form.Item label="Disponibilité">
        <Form.List name="availability">
          {(availability) =>
            availability.map((field, i) => (
              <Form.Item {...field} valuePropName="checked" noStyle>
                <Checkbox>{AVAILABILITY_LABELS[i]}</Checkbox>
              </Form.Item>
            ))
          }
        </Form.List>
      </Form.Item>
      <Button htmlType="submit" icon={<SendOutlined />} type="primary">
        Envoyer
      </Button>
    </Form>
  );
};

const GroupsList = () => {
  // List
  const { isLoading, groups, refetch } = useQueryGroups();
  const { categories } = useQueryCategories();
  // Create
  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(undefined);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const handleCreateOpen = useCallback(() => {
    setCreateLoading(false);
    setCreateError(undefined);
    setIsCreateOpen(true);
  }, []);
  const handleCreateClose = useCallback(() => {
    setCreateLoading(false);
    setCreateError(undefined);
    setIsCreateOpen(false);
  }, []);
  const mutationGroupCreate = useMutationGroupCreate();
  const handleCreate = useCallback(
    async (values: any) => {
      try {
        setCreateLoading(true);
        setCreateError(undefined);
        await mutationGroupCreate(values);
        await refetch();
        handleCreateClose();
        message.success('Le groupe a été créé.');
      } catch (err: any) {
        setCreateError(err.message);
      } finally {
        setCreateLoading(false);
      }
    },
    [handleCreateClose, mutationGroupCreate, refetch],
  );
  // Update
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState(undefined);
  const [isUpdateOpen, setIsUpdateOpen] = useState(undefined);
  const handleUpdateOpen = useCallback((group: any) => {
    setUpdateLoading(false);
    setUpdateError(undefined);
    setIsUpdateOpen(group);
  }, []);
  const handleUpdateClose = useCallback(() => {
    setUpdateLoading(false);
    setUpdateError(undefined);
    setIsUpdateOpen(undefined);
  }, []);
  const mutationGroupUpdate = useMutationGroupUpdate();
  const handleUpdate = useCallback(
    async (values: any) => {
      try {
        setUpdateLoading(true);
        setUpdateError(undefined);
        await mutationGroupUpdate(values);
        await refetch();
        handleUpdateClose();
        message.success('Le groupe a été mis à jour.');
      } catch (err: any) {
        setUpdateError(err.message);
      } finally {
        setUpdateLoading(false);
      }
    },
    [handleUpdateClose, mutationGroupUpdate, refetch],
  );
  // Delete
  const mutationGroupDelete = useMutationGroupDelete();
  const handleDelete = useCallback(
    async (group: any) => {
      try {
        await mutationGroupDelete({
          id: group.id,
        });
        await refetch();
        message.success('Le groupe a été supprimé.');
      } catch (err) {
        message.error("Une erreur s'est produite. Veuillez réessayer.");
      }
    },
    [mutationGroupDelete, refetch],
  );
  // Columns
  const columns = [
    {
      title: 'Nom du groupe',
      dataIndex: 'name',
      key: 'name',
      ...sortText('name'),
      ...searchText('name'),
    },
    {
      title: 'Catégories',
      key: 'categories',
      render: ({ categories }: any) =>
        categories.map((category: any) => (
          <Tag key={category.id} color={category.color}>
            {category.name}
          </Tag>
        )),
    },
    {
      title: 'Disponibilités',
      key: 'availabilities',
      render: ({ availability }: any) =>
        availability
          .map((a: any, i: any) => (a ? AVAILABILITY_LABELS[i] : ''))
          .filter((v: any) => !!v)
          .join(', '),
    },
    {
      title: '',
      key: 'options',
      width: 100,
      render: (group: any) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleUpdateOpen(group)}
          />
          <Popconfirm
            okText="Oui"
            onConfirm={() => handleDelete(group)}
            title="Dernière chance. Êtes-vous sûr ?"
          >
            <Button icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  // Render
  return (
    <>
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Groupes</Typography.Title>
        </Col>
        <Col>
          <Space wrap>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={handleCreateOpen}
            >
              Ajouter un groupe
            </Button>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Table
        dataSource={groups}
        columns={columns}
        loading={isLoading}
        rowKey="id"
        pagination={false}
        bordered
      />
      {/* Create */}
      <Modal
        title="Ajouter un groupe"
        open={isCreateOpen}
        destroyOnClose
        footer={null}
        onCancel={handleCreateClose}
        width={800}
      >
        <Upsert
          categories={categories}
          handleSubmit={handleCreate}
          loading={createLoading}
          error={createError}
        />
      </Modal>
      {/* Update */}
      <Modal
        title="Modifier un groupe"
        open={!!isUpdateOpen}
        destroyOnClose
        footer={null}
        onCancel={handleUpdateClose}
        width={800}
      >
        <Upsert
          group={isUpdateOpen}
          categories={categories}
          handleSubmit={handleUpdate}
          loading={updateLoading}
          error={updateError}
        />
      </Modal>
    </>
  );
};

export default GroupsList;
