import { SendOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Result,
  Row,
  Typography,
  Upload,
} from 'antd';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { RAW } from '../../helpers/request';

const PlannerStep3 = () => {
  // Submit
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [submitDone, setSubmitDone] = useState(false);
  const handleSubmit = useCallback(async ({ file, startDate }: any) => {
    try {
      setSubmitLoading(true);
      setSubmitError('');
      const data = new FormData();
      data.append('file', file.file);
      data.append('startDate', startDate.format('YYYY-MM-DD'));
      await RAW(`${process.env.REACT_APP_URL}/api/obligations/step3`, data);
      setSubmitDone(true);
    } catch (err: any) {
      console.log(err);
      if (err.message.startsWith('Category::')) {
        const [, category] = err.message.split('::');
        setSubmitError(
          `Le planning fait référence à la catégorie "${category}" qui n'existe pas. Vous devez l'ajouter à l'application ou retirer la ligne du planning afin de continuer.`,
        );
      } else if (err.message.startsWith('Student::')) {
        const [, student] = err.message.split('::');
        setSubmitError(
          `Le planning fait référence à l'étudiant "${student}" qui n'existe pas. Vous devez l'ajouter à l'application ou retirer la ligne du planning afin de continuer.`,
        );
      } else if (err.message.startsWith('Conflict')) {
        const [, week, halfday, student] = err.message.split('::');
        const day = [
          'Lundi AM',
          'Lundi PM',
          'Mardi AM',
          'Mardi PM',
          'Mercredi AM',
          'Mercredi PM',
          'Jeudi AM',
          'Jeudi PM',
          'Vendredi AM',
          'Vendredi PM',
        ].at(halfday);
        setSubmitError(
          `Il y a un conflit horaire dans le planning. En semaine ${week}, le ${day}, l'étudiant ${student} a deux obligations.`,
        );
      } else {
        setSubmitError("Une erreur s'est produite. Veuillez réessayer.");
      }
    } finally {
      setSubmitLoading(false);
    }
  }, []);
  return (
    <>
      <Breadcrumb
        items={[
          { title: <Link to="/planner">Planification</Link> },
          { title: <Link to="/planner/step3">Valider un planning</Link> },
        ]}
      />
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Valider un planning</Typography.Title>
        </Col>
      </Row>
      <Divider />
      {submitDone ? (
        <Result
          status="success"
          title="Planning mis en ligne !"
          subTitle="Le planning a été mis en ligne et les obligations ont été créées."
        />
      ) : (
        <Form
          initialValues={{
            file: null,
            startDate: null,
          }}
          layout="vertical"
          onFinish={handleSubmit}
          disabled={submitLoading}
        >
          <Alert
            type="info"
            message="Information"
            description="La troisième étape est la mise en ligne du planning définitif. S'il existe des conflits horaires le planning sera rejeté."
            showIcon
            className="error"
          />
          {submitError && (
            <Alert
              type="error"
              message={submitError}
              banner
              className="error"
            />
          )}
          <Form.Item noStyle shouldUpdate>
            {({ setFieldValue }) => (
              <Form.Item
                label="Planning définitif"
                name="file"
                rules={[
                  {
                    required: true,
                    message: 'Veuillez choisir un planning définitif.',
                  },
                ]}
              >
                <Upload
                  accept=".xlsx"
                  maxCount={1}
                  beforeUpload={(file) => {
                    setFieldValue('file', file);
                    return false;
                  }}
                >
                  <Button icon={<UploadOutlined />}>
                    Selectionner un planning définitif
                  </Button>
                </Upload>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            label="Première semaine du planning"
            name="startDate"
            rules={[
              {
                required: true,
                message: 'Veuillez choisir une semaine.',
              },
            ]}
          >
            <DatePicker
              picker="week"
              format={(value) =>
                `${dayjs
                  .utc(value)
                  .startOf('week')
                  .format('DD MMMM YYYY')} ~ ${dayjs
                  .utc(value)
                  .endOf('week')
                  .format('DD MMMM YYYY')}`
              }
            />
          </Form.Item>
          <Button htmlType="submit" icon={<SendOutlined />} type="primary">
            Envoyer
          </Button>
        </Form>
      )}
    </>
  );
};

export default PlannerStep3;
