import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const MUTATION_AUTH_LOGIN = gql`
  mutation AuthLogin($email: String!, $password: String!) {
    authLogin(email: $email, password: $password)
  }
`;

export function useMutationAuthLogin(options = {}) {
  const [mutation] = useMutation(MUTATION_AUTH_LOGIN, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_AUTH_RECOVER_PASSWORD = gql`
  mutation AuthRecoverPassword($email: String!) {
    authRecoverPassword(email: $email)
  }
`;

export function useMutationAuthRecoverPassword(options = {}) {
  const [mutation] = useMutation(MUTATION_AUTH_RECOVER_PASSWORD, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_AUTH_RESET_PASSWORD = gql`
  mutation AuthResetPassword(
    $email: String!
    $recoveryToken: String!
    $password: String!
  ) {
    authResetPassword(
      email: $email
      recoveryToken: $recoveryToken
      password: $password
    )
  }
`;

export function useMutationAuthResetPassword(options = {}) {
  const [mutation] = useMutation(MUTATION_AUTH_RESET_PASSWORD, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_AUTH_UPDATE_PASSWORD = gql`
  mutation AuthUpdatePassword($oldPassword: String!, $newPassword: String!) {
    authUpdatePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

export function useMutationAuthUpdatePassword(options = {}) {
  const [mutation] = useMutation(MUTATION_AUTH_UPDATE_PASSWORD, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}
