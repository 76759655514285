import {
  CarryOutOutlined,
  ContainerOutlined,
  LineChartOutlined,
  PieChartOutlined,
  RobotOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { Alert, Card, Col, Divider, Row, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useQueryMe } from '../../gql/queries/me';
import { useQuerySheetsCountOpen } from '../../gql/queries/sheets';

const HomeSupervisor = () => {
  const { me } = useQueryMe();
  const name = me ? me.firstName : '';
  const { sheetStatisticsCountOpen } = useQuerySheetsCountOpen();
  const stats = sheetStatisticsCountOpen || { me: 0, department: 0 };
  return (
    <>
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Bonjour {name} !</Typography.Title>
        </Col>
      </Row>
      <Divider />
      {stats.me > 0 && (
        <Alert
          description={
            <>
              Vous avez <b>{stats.me} fiche(s)</b> de carnet de stage à valider.
            </>
          }
          type="info"
          showIcon
          action={<Link to="/sheets">Voir les fiches</Link>}
          style={{ marginBottom: 8 }}
        />
      )}
      {stats.department > 0 && (
        <Alert
          description={
            <>
              Vos assistants doivent valider <b>{stats.department} fiche(s)</b>{' '}
              de carnet de stage.
            </>
          }
          type="info"
          showIcon
          action={<Link to="/sheets">Voir les fiches</Link>}
        />
      )}
      <Typography.Title level={2}>Obligations</Typography.Title>
      <Space wrap>
        <Link to="/obligations">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<CarryOutOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Obligations"
              description="Voir les obligations des étudiants"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
        <Link to="/takeovers">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<SwapOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Transferts"
              description="Voir mes demandes de transfert"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
        <Link to="/absences">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<RobotOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Absences"
              description="Gérer les absences"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
      </Space>
      <Typography.Title level={2}>Carnet de stage</Typography.Title>
      <Space wrap>
        <Link to="/sheets">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={
              <ContainerOutlined style={{ marginTop: 32, fontSize: 40 }} />
            }
          >
            <Card.Meta
              title="Fiches"
              description="Voir les fiches de stage"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
        <Link to="/progress">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={
              <LineChartOutlined style={{ marginTop: 32, fontSize: 40 }} />
            }
          >
            <Card.Meta
              title="Avancement"
              description="Voir l'avancement de mon stage"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
      </Space>
      <Typography.Title level={2}>Autres</Typography.Title>
      <Space wrap>
        <Link to="/statistics">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<PieChartOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta title="Statistiques" style={{ textAlign: 'center' }} />
          </Card>
        </Link>
      </Space>
    </>
  );
};

export default HomeSupervisor;
