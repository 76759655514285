import { gql, useQuery } from '@apollo/client';
import { FRAG_ABSENCE, FRAG_ACCOUNT } from './fragments';

const QUERY_ABSENCES = gql`
  query Absences(
    $pageCurrent: Int
    $pageSize: Int
    $orderBy: String
    $orderSort: String
    $filters: JSON
    $date: String
  ) {
    absences(
      pageCurrent: $pageCurrent
      pageSize: $pageSize
      orderBy: $orderBy
      orderSort: $orderSort
      filters: $filters
      date: $date
    ) {
      ...AbsenceData
      account {
        ...AccountData
      }
    }
  }
  ${FRAG_ABSENCE}
  ${FRAG_ACCOUNT}
`;

export const useQueryAbsences = (options = {}) => {
  const { data, loading, ...props } = useQuery(QUERY_ABSENCES, options);
  return {
    absences: data?.absences,
    nAbsences:
      data?.absences && data.absences.length > 0 ? data.absences[0].total : 0,
    isLoading: loading || !data,
    ...props,
  };
};

const QUERY_ABSENCE = gql`
  query Absence($id: ID!) {
    absence(id: $id) {
      ...AbsenceData
      account {
        ...AccountData
      }
    }
  }
  ${FRAG_ABSENCE}
  ${FRAG_ACCOUNT}
`;

export const useQueryAbsence = (options = {}) => {
  const { data, loading, ...props } = useQuery(QUERY_ABSENCE, options);
  return {
    absence: data?.absence,
    isLoading: loading || !data,
    ...props,
  };
};

const QUERY_ABSENCES_STATISTICS_ALL = gql`
  {
    absenceStatisticsAll
  }
`;

export const useQueryAbsencesStatisticsAll = (options = {}) => {
  const { data, loading, ...props } = useQuery(
    QUERY_ABSENCES_STATISTICS_ALL,
    options,
  );
  return {
    absenceStatisticsAll: data?.absenceStatisticsAll,
    isLoading: loading || !data,
    ...props,
  };
};
