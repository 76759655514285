import {
  BookOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  ContainerOutlined,
  DeploymentUnitOutlined,
  FormOutlined,
  HomeOutlined,
  LineChartOutlined,
  LockOutlined,
  LoginOutlined,
  LogoutOutlined,
  MenuOutlined,
  PieChartOutlined,
  RobotOutlined,
  SettingOutlined,
  SwapOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { useReactiveVar } from '@apollo/client';
import { Dropdown, Layout, Menu, Spin, theme } from 'antd';
import { useCallback } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import appVar, { appVarLogin, appVarLogout } from '../gql/appVar';
import { useQueryMe } from '../gql/queries/me';
import useIsMobile from '../helpers/useMobile';
import logo from '../images/logo.png';
import AbsencesAdmin from './Absences/Admin';
import AbsencesStudent from './Absences/Student';
import AbsencesSupervisor from './Absences/Supervisor';
import AccountsList from './Accounts/List';
import AuthLogin from './Auth/Login';
import AuthLogout from './Auth/Logout';
import AuthRecoverPassword from './Auth/RecoverPassword';
import AuthResetPassword from './Auth/ResetPassword';
import CategoriesList from './Categories/List';
import DepartmentsList from './Departments/List';
import GroupsList from './Groups/List';
import HomeAdmin from './Home/Admin';
import HomeAssistant from './Home/Assistant';
import HomeStudent from './Home/Student';
import HomeSupervisor from './Home/Supervisor';
import HomeViewer from './Home/Viewer';
import ObligationsAdmin from './Obligations/Admin';
import ObligationsStudent from './Obligations/Student';
import ObligationsSupervisor from './Obligations/Supervisor';
import ObligationsViewer from './Obligations/Viewer';
import PlannerHome from './Planner/Home';
import PlannerTemplatesList from './Planner/PlannerTemplatesList';
import PlannerStep1 from './Planner/Step1';
import PlannerStep2 from './Planner/Step2';
import PlannerStep3 from './Planner/Step3';
import ProgressAdmin from './Progress/Admin';
import ProgressStudent from './Progress/Student';
import SettingsList from './Settings/List';
import SettingsPassword from './Settings/Password';
import SheetTemplatesList from './SheetTemplates/List';
import SheetsAdmin from './Sheets/Admin';
import SheetsAssistant from './Sheets/Assistant';
import SheetsStudent from './Sheets/Student';
import SheetsSupervisor from './Sheets/Supervisor';
import AbsencesStatistics from './Statistics/Absences';
import StatisticsHome from './Statistics/Home';
import ObligationsStatistics from './Statistics/Obligations';
import SheetsStatistics from './Statistics/Sheets';
import TakeoversStatistics from './Statistics/Takeovers';
import TakeoversAdmin from './Takeovers/Admin';
import TakeoversStudent from './Takeovers/Student';

const ANONYMOUS_ROUTES = [
  {
    key: 'login',
    icon: <LoginOutlined />,
    label: 'Connexion',
    element: <AuthLogin />,
  },
  {
    key: 'recover-password',
    icon: <LockOutlined />,
    label: 'Mot de passe oublié',
    element: <AuthRecoverPassword />,
  },
  { key: 'reset-password', element: <AuthResetPassword /> },
];
const ADMIN_ROUTES = [
  {
    key: '',
    icon: <HomeOutlined />,
    label: 'Accueil',
    element: <HomeAdmin />,
  },
  {
    key: 'separator-1',
    label: 'separator',
    type: 'divider',
  },
  {
    key: 'accounts',
    icon: <UserOutlined />,
    label: 'Utilisateurs',
    element: <AccountsList />,
  },
  {
    key: 'separator-2',
    label: 'separator',
    type: 'divider',
  },
  {
    key: 'categories',
    icon: <BookOutlined />,
    label: 'Catégories',
    element: <CategoriesList />,
  },
  {
    key: 'groups',
    icon: <UsergroupAddOutlined />,
    label: 'Groupes',
    element: <GroupsList />,
  },
  {
    key: 'planner',
    icon: <CalendarOutlined />,
    label: 'Planification',
    element: <PlannerHome />,
  },
  { key: 'planner/templates', element: <PlannerTemplatesList /> },
  { key: 'planner/step1', element: <PlannerStep1 /> },
  { key: 'planner/step2', element: <PlannerStep2 /> },
  { key: 'planner/step3', element: <PlannerStep3 /> },
  {
    key: 'obligations',
    icon: <CarryOutOutlined />,
    label: 'Obligations',
    element: <ObligationsAdmin />,
  },
  {
    key: 'takeovers',
    icon: <SwapOutlined />,
    label: 'Transferts',
    element: <TakeoversAdmin />,
  },
  {
    key: 'absences',
    icon: <RobotOutlined />,
    label: 'Absences',
    element: <AbsencesAdmin />,
  },
  {
    key: 'separator-3',
    label: 'separator',
    type: 'divider',
  },
  {
    key: 'departments',
    icon: <DeploymentUnitOutlined />,
    label: 'Disciplines',
    element: <DepartmentsList />,
  },
  {
    key: 'sheettemplates',
    icon: <FormOutlined />,
    label: 'Modèles',
    element: <SheetTemplatesList />,
  },
  {
    key: 'sheets',
    icon: <ContainerOutlined />,
    label: 'Fiches',
    element: <SheetsAdmin />,
  },
  {
    key: 'progress',
    icon: <LineChartOutlined />,
    label: 'Avancement',
    element: <ProgressAdmin />,
  },
  {
    key: 'separator-4',
    label: 'separator',
    type: 'divider',
  },
  {
    key: 'statistics',
    icon: <PieChartOutlined />,
    label: 'Statistiques',
    element: <StatisticsHome />,
  },
  { key: 'statistics/obligations', element: <ObligationsStatistics /> },
  { key: 'statistics/takeovers', element: <TakeoversStatistics /> },
  { key: 'statistics/absences', element: <AbsencesStatistics /> },
  { key: 'statistics/sheets', element: <SheetsStatistics /> },
];
const SUPERVISOR_ROUTES: any[] = [
  {
    key: '',
    icon: <HomeOutlined />,
    label: 'Accueil',
    element: <HomeSupervisor />,
  },
  {
    key: 'separator-1',
    label: 'separator',
    type: 'divider',
  },
  {
    key: 'obligations',
    icon: <CarryOutOutlined />,
    label: 'Obligations',
    element: <ObligationsSupervisor />,
  },
  {
    key: 'takeovers',
    icon: <SwapOutlined />,
    label: 'Transferts',
    element: <TakeoversAdmin />,
  },
  {
    key: 'absences',
    icon: <RobotOutlined />,
    label: 'Absences',
    element: <AbsencesSupervisor />,
  },
  {
    key: 'separator-2',
    label: 'separator',
    type: 'divider',
  },
  {
    key: 'sheets',
    icon: <ContainerOutlined />,
    label: 'Fiches',
    element: <SheetsSupervisor />,
  },
  {
    key: 'progress',
    icon: <LineChartOutlined />,
    label: 'Avancement',
    element: <ProgressAdmin />,
  },
  {
    key: 'separator-3',
    label: 'separator',
    type: 'divider',
  },
  {
    key: 'statistics',
    icon: <PieChartOutlined />,
    label: 'Statistiques',
    element: <StatisticsHome />,
  },
  { key: 'statistics/obligations', element: <ObligationsStatistics /> },
  { key: 'statistics/takeovers', element: <TakeoversStatistics /> },
  { key: 'statistics/absences', element: <AbsencesStatistics /> },
  { key: 'statistics/sheets', element: <SheetsStatistics /> },
];
const ASSISTANT_ROUTES: any[] = [
  {
    key: '',
    icon: <HomeOutlined />,
    label: 'Accueil',
    element: <HomeAssistant />,
  },
  {
    key: 'separator-1',
    label: 'separator',
    type: 'divider',
  },
  {
    key: 'obligations',
    icon: <CarryOutOutlined />,
    label: 'Obligations',
    element: <ObligationsViewer />,
  },
  {
    key: 'separator-2',
    label: 'separator',
    type: 'divider',
  },
  {
    key: 'sheets',
    icon: <ContainerOutlined />,
    label: 'Fiches',
    element: <SheetsAssistant />,
  },
];
const STUDENT_ROUTES: any[] = [
  {
    key: '',
    icon: <HomeOutlined />,
    label: 'Accueil',
    element: <HomeStudent />,
  },
  {
    key: 'separator-1',
    label: 'separator',
    type: 'divider',
  },
  {
    key: 'obligations',
    icon: <CarryOutOutlined />,
    label: 'Obligations',
    element: <ObligationsStudent />,
  },
  {
    key: 'takeovers',
    icon: <SwapOutlined />,
    label: 'Transferts',
    element: <TakeoversStudent />,
  },
  {
    key: 'absences',
    icon: <RobotOutlined />,
    label: 'Absences',
    element: <AbsencesStudent />,
  },
  {
    key: 'separator-2',
    label: 'separator',
    type: 'divider',
  },
  {
    key: 'sheets',
    icon: <ContainerOutlined />,
    label: 'Fiches',
    element: <SheetsStudent />,
  },
  {
    key: 'progress',
    icon: <LineChartOutlined />,
    label: 'Avancement',
    element: <ProgressStudent />,
  },
];
const VIEWER_ROUTES: any[] = [
  {
    key: '',
    icon: <HomeOutlined />,
    label: 'Accueil',
    element: <HomeViewer />,
  },
  {
    key: 'separator-1',
    label: 'separator',
    type: 'divider',
  },
  {
    key: 'obligations',
    icon: <CarryOutOutlined />,
    label: 'Obligations',
    element: <ObligationsViewer />,
  },
];
const USER_ROUTES = [
  {
    key: 'separator-99',
    label: 'separator',
    type: 'divider',
  },
  {
    key: 'settings',
    icon: <SettingOutlined />,
    label: 'Paramètres',
    element: <SettingsList />,
  },
  {
    key: 'settings/password',
    element: <SettingsPassword />,
  },
  {
    key: 'logout',
    icon: <LogoutOutlined />,
    label: 'Se déconnecter',
    element: <AuthLogout />,
  },
];

const App = () => {
  const { hasToken, isAuthenticated } = useReactiveVar(appVar);
  const { me, isLoading: isLoadingMe } = useQueryMe({
    skip: !hasToken,
    onCompleted: () => appVarLogin(),
    onError: () => appVarLogout(),
  });
  const isLoading = hasToken && isLoadingMe;
  const routes = isLoading
    ? []
    : isAuthenticated && me.type === 'admin'
    ? [...ADMIN_ROUTES, ...USER_ROUTES]
    : isAuthenticated && me.type === 'supervisor'
    ? [...SUPERVISOR_ROUTES, ...USER_ROUTES]
    : isAuthenticated && me.type === 'assistant'
    ? [...ASSISTANT_ROUTES, ...USER_ROUTES]
    : isAuthenticated && me.type === 'student'
    ? [...STUDENT_ROUTES, ...USER_ROUTES]
    : isAuthenticated && me.type === 'viewer'
    ? [...VIEWER_ROUTES, ...USER_ROUTES]
    : isAuthenticated
    ? USER_ROUTES
    : ANONYMOUS_ROUTES;
  // Layout
  const { pathname } = useLocation();
  const [, selectedKey] = pathname.split('/');
  const navigate = useNavigate();
  const handleNavigate = useCallback(
    ({ key }: { key: string }) => {
      navigate(key);
    },
    [navigate],
  );
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const isMobile = useIsMobile();
  return isMobile ? (
    <Layout>
      <Layout.Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          maxWidth: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#7e1e66',
        }}
      >
        <div className="app-logo-small">EMDS</div>
        <Dropdown
          placement="bottomRight"
          menu={{
            selectedKeys: [selectedKey],
            onClick: handleNavigate,
            items: routes.filter((route) => route.label),
          }}
        >
          <MenuOutlined
            style={{
              color: '#fff',
              fontSize: 24,
              cursor: 'pointer',
              padding: 5,
            }}
          />
        </Dropdown>
      </Layout.Header>
      <Layout.Content
        className="app-content"
        style={{ background: colorBgContainer }}
      >
        {isLoading ? (
          <Spin size="large" />
        ) : (
          <Routes>
            {routes
              .filter((r) => r.element)
              .map((route) => (
                <Route
                  key={route.key}
                  path={route.key}
                  element={route.element}
                />
              ))}
            <Route
              path="*"
              element={
                <Navigate to={isAuthenticated ? '/' : '/login'} replace />
              }
            />
          </Routes>
        )}
      </Layout.Content>
    </Layout>
  ) : (
    <Layout>
      <Layout.Sider>
        <div className="app-logo">
          <img src={logo} alt="Logo EMDS" />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[selectedKey]}
          onClick={handleNavigate}
          items={routes.filter((route) => route.label)}
        />
      </Layout.Sider>
      <Layout>
        <Layout.Content
          className="app-content"
          style={{ background: colorBgContainer }}
        >
          {isLoading ? (
            <Spin size="large" />
          ) : (
            <Routes>
              {routes.map((route) => (
                <Route
                  key={route.key}
                  path={route.key}
                  element={route.element}
                />
              ))}
              <Route
                path="*"
                element={
                  <Navigate to={isAuthenticated ? '/' : '/login'} replace />
                }
              />
            </Routes>
          )}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default App;
