import {
  LeftOutlined,
  RightOutlined,
  SendOutlined,
  SettingOutlined,
  StopOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import {
  useMutationObligationTakeoverCancel,
  useMutationObligationTakeoverRequest,
} from '../../gql/mutations/obligations';
import { useQueryAbsences } from '../../gql/queries/absences';
import { useQueryStudents } from '../../gql/queries/accounts';
import { useQueryCategories } from '../../gql/queries/categories';
import { useQueryMe } from '../../gql/queries/me';
import { useQueryObligations } from '../../gql/queries/obligations';
import { FormatTable, useFormatTable } from './formatTable';

const Slot = ({ slot, handleTakeover, handleTakeoverCancel, me = {} }: any) => (
  <div
    style={{
      borderColor: slot.category?.color || 'black',
      backgroundColor: slot.accountId === me.id ? '#eafeff' : 'white',
    }}
    className="slot"
  >
    <p className="slot-category">{slot.category?.name || 'NA'}</p>
    <p className="slot-specifier">{slot.specifier}</p>
    <p className="slot-student">
      {slot.account
        ? `${slot.account.firstName} ${slot.account.lastName}`
        : 'VIDE'}
    </p>
    {slot.absent && <p className="slot-absent">ABSENT</p>}
    {slot.takeoverAccount && (
      <p className="slot-takeover">
        {`Proposé à ${slot.takeoverAccount.firstName} ${slot.takeoverAccount.lastName}`}
      </p>
    )}
    {!slot.isAbsence && slot.accountId === me.id && (
      <div className="slot-actions">
        <Dropdown
          menu={{
            items: [
              {
                key: 'takeover',
                label: "Transférer l'obligation",
                icon: <SwapOutlined />,
                onClick: () => handleTakeover(slot),
                disabled:
                  dayjs().isAfter(slot.date, 'day') || slot.takeoverAccountId,
              },
              {
                key: 'takeoverCancel',
                label: (
                  <Popconfirm
                    okText="Oui"
                    onConfirm={() => handleTakeoverCancel(slot)}
                    title="Dernière chance. Êtes-vous sûr ?"
                  >
                    Annuler le transfert
                  </Popconfirm>
                ),
                icon: <StopOutlined />,
                danger: true,
                disabled: !slot.takeoverAccountId,
              },
            ],
          }}
          placement="bottomRight"
          arrow
        >
          <Button icon={<SettingOutlined />} size="small" />
        </Dropdown>
      </div>
    )}
  </div>
);

const ObligationsStudent = () => {
  // Parameters
  const [date, setDate] = useState<any>(() => dayjs());
  const [category, setCategory] = useState(null);
  const [student, setStudent] = useState(undefined);
  // List
  const { isLoading, obligations, refetch } = useQueryObligations({
    variables: {
      date: date.format('YYYY-MM-DD'),
    },
  });
  const { absences } = useQueryAbsences({
    variables: {
      date: date.format('YYYY-MM-DD'),
    },
  });
  const { categories } = useQueryCategories();
  const { students } = useQueryStudents();
  const { me } = useQueryMe();
  // Takeover
  const [takeoverLoading, setTakeoverLoading] = useState(false);
  const [takeoverError, setTakeoverError] = useState(undefined);
  const [isTakeoverOpen, setIsTakeoverOpen] = useState<any>(undefined);
  const handleTakeoverOpen = useCallback((obligation: any) => {
    setTakeoverLoading(false);
    setTakeoverError(undefined);
    setIsTakeoverOpen(obligation);
  }, []);
  const handleTakeoverClose = useCallback(() => {
    setTakeoverLoading(false);
    setTakeoverError(undefined);
    setIsTakeoverOpen(undefined);
  }, []);
  const mutationObligationTakeoverRequest =
    useMutationObligationTakeoverRequest();
  const handleTakeover = useCallback(
    async (values: any) => {
      try {
        setTakeoverLoading(true);
        setTakeoverError(undefined);
        await mutationObligationTakeoverRequest(values);
        await refetch();
        handleTakeoverClose();
        message.success('La demande de transfert a été envoyée.');
      } catch (err: any) {
        setTakeoverError(err.message);
      } finally {
        setTakeoverLoading(false);
      }
    },
    [handleTakeoverClose, mutationObligationTakeoverRequest, refetch],
  );
  // Takeover Cancel
  const mutationObligationTakeoverCancel =
    useMutationObligationTakeoverCancel();
  const handleTakeoverCancel = useCallback(
    async (obligation: any) => {
      try {
        await mutationObligationTakeoverCancel({
          id: obligation.id,
        });
        await refetch();
        message.success('La demande de transfert a été supprimée.');
      } catch (err) {
        message.error("Une erreur s'est produite. Veuillez réessayer.");
      }
    },
    [mutationObligationTakeoverCancel, refetch],
  );
  // Table
  const table = useFormatTable({
    obligations,
    absences,
    date,
    categories,
    category,
    student,
  });
  const takeoverBackup = useMemo(() => {
    if (isTakeoverOpen) {
      const o = (obligations || []).find(
        (slot: any) =>
          slot.category &&
          slot.category.name === 'BACKUP' &&
          slot.account &&
          slot.date === isTakeoverOpen.date &&
          slot.moment === isTakeoverOpen.moment,
      );
      return o?.account;
    }
    return undefined;
  }, [obligations, isTakeoverOpen]);
  // Render
  return (
    <>
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Obligations</Typography.Title>
        </Col>
      </Row>
      <Divider />
      <Space wrap>
        <Button onClick={() => setDate(dayjs())}>Aujourd'hui</Button>
        <Space>
          <Button
            onClick={() => setDate(date.subtract(1, 'week'))}
            icon={<LeftOutlined />}
          />
          <DatePicker
            picker="week"
            value={date}
            onChange={(v) => setDate(v)}
            format={(value) =>
              `${dayjs(value).startOf('week').format('DD MMMM YYYY')} ~ ${dayjs(
                value,
              )
                .endOf('week')
                .format('DD MMMM YYYY')}`
            }
          />
          <Button
            onClick={() => setDate(date.add(1, 'week'))}
            icon={<RightOutlined />}
          />
        </Space>
        <Select
          style={{ width: 200 }}
          allowClear
          options={[
            ...(categories || []).map((category: any) => ({
              label: category.name,
              value: category.id,
            })),
            {
              label: 'ABSENCE',
              value: 'ABSENCE',
            },
          ]}
          value={category}
          onChange={(v) => setCategory(v)}
          placeholder="Filter par catégorie"
        />
        <Checkbox
          onChange={(e) => {
            setStudent(e.target.checked ? me?.id : undefined);
          }}
          checked={!!student}
        >
          Afficher uniquement mes obligations
        </Checkbox>
      </Space>
      <Divider />
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <FormatTable
          table={table}
          as={Slot}
          handleTakeover={handleTakeoverOpen}
          handleTakeoverCancel={handleTakeoverCancel}
          me={me}
        />
      )}
      {/* Takeover */}
      <Modal
        title="Transférer une obligation"
        open={!!isTakeoverOpen}
        destroyOnClose
        footer={null}
        onCancel={handleTakeoverClose}
        width={800}
      >
        {takeoverBackup && (
          <Alert
            message={`En cas d'imprévu (max 24h à l'avance) vous pouvez transférer votre obligation à la personne en BACKUP qui est ${takeoverBackup.firstName} ${takeoverBackup.lastName}`}
            className="error"
          />
        )}
        <Form
          initialValues={{
            id: isTakeoverOpen?.id || '',
            takeoverAccountId: null,
            reason: '',
          }}
          layout="vertical"
          onFinish={handleTakeover}
          disabled={takeoverLoading}
        >
          {takeoverError && (
            <Alert
              type="error"
              message="Une erreur est survenue. Veuillez réessayer."
              banner
              className="error"
            />
          )}
          <Form.Item noStyle name="id" />
          <Form.Item
            label="Étudiant"
            name="takeoverAccountId"
            rules={[
              { required: true, message: 'Veuillez renseigner un étudiant.' },
            ]}
          >
            <Select
              allowClear
              showSearch
              filterOption={(v, opt: any) =>
                opt.label.toLowerCase().includes(v.toLowerCase())
              }
              options={(students || []).map((student: any) => ({
                label: `${student.firstName} ${student.lastName}`,
                value: student.id,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Justification (pour l'équipe administrative)"
            name="reason"
            rules={[
              {
                required: true,
                message: 'Veuillez renseigner une justification.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Button htmlType="submit" icon={<SendOutlined />} type="primary">
            Envoyer
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default ObligationsStudent;
