import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';
import frFR from 'antd/locale/fr_FR';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import apolloClient from './gql/client';
import './styles/index.scss';
import App from './views/App';

dayjs.locale('fr');
dayjs.extend(utc);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <ConfigProvider
          locale={frFR}
          theme={{
            token: {
              colorPrimary: '#7e1e66',
            },
          }}
        >
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
);
