import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const MUTATION_GROUP_CREATE = gql`
  mutation GroupCreate(
    $name: String!
    $categoryIds: [String!]
    $availability: [Boolean!]!
  ) {
    groupCreate(
      name: $name
      categoryIds: $categoryIds
      availability: $availability
    ) {
      id
    }
  }
`;

export function useMutationGroupCreate(options = {}) {
  const [mutation] = useMutation(MUTATION_GROUP_CREATE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_GROUP_UPDATE = gql`
  mutation GroupUpdate(
    $id: ID!
    $name: String
    $categoryIds: [String!]
    $availability: [Boolean!]
  ) {
    groupUpdate(
      id: $id
      name: $name
      categoryIds: $categoryIds
      availability: $availability
    ) {
      id
    }
  }
`;

export function useMutationGroupUpdate(options = {}) {
  const [mutation] = useMutation(MUTATION_GROUP_UPDATE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_GROUP_DELETE = gql`
  mutation GroupDelete($id: ID!) {
    groupDelete(id: $id)
  }
`;

export function useMutationGroupDelete(options = {}) {
  const [mutation] = useMutation(MUTATION_GROUP_DELETE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}
