import { gql, useQuery } from '@apollo/client';
import { FRAG_ACCOUNT, FRAG_DEPARTMENT, FRAG_GROUP } from './fragments';

const QUERY_ACCOUNTS = gql`
  query accounts {
    accounts {
      ...AccountData
      group {
        ...GroupData
      }
      departments {
        ...DepartmentData
      }
    }
  }
  ${FRAG_ACCOUNT}
  ${FRAG_GROUP}
  ${FRAG_DEPARTMENT}
`;

export const useQueryAccounts = (options = {}) => {
  const { data, loading, ...props } = useQuery(QUERY_ACCOUNTS, options);
  return {
    accounts: data?.accounts,
    isLoading: loading || !data,
    ...props,
  };
};

const QUERY_ACCOUNT = gql`
  query account($id: ID!) {
    account(id: $id) {
      ...AccountData
      group {
        ...GroupData
      }
      departments {
        ...DepartmentData
      }
    }
  }
  ${FRAG_ACCOUNT}
  ${FRAG_GROUP}
  ${FRAG_DEPARTMENT}
`;

export const useQueryAccount = (options = {}) => {
  const { data, loading, ...props } = useQuery(QUERY_ACCOUNT, options);
  return {
    account: data?.account,
    isLoading: loading || !data,
    ...props,
  };
};

const QUERY_STUDENTS = gql`
  query students {
    students {
      ...AccountData
      group {
        ...GroupData
      }
    }
  }
  ${FRAG_ACCOUNT}
  ${FRAG_GROUP}
`;

export const useQueryStudents = (options = {}) => {
  const { data, loading, ...props } = useQuery(QUERY_STUDENTS, options);
  return {
    students: data?.students,
    isLoading: loading || !data,
    ...props,
  };
};

const QUERY_ASSISTANTS = gql`
  query assistants {
    assistants {
      ...AccountData
    }
  }
  ${FRAG_ACCOUNT}
`;

export const useQueryAssistants = (options = {}) => {
  const { data, loading, ...props } = useQuery(QUERY_ASSISTANTS, options);
  return {
    assistants: data?.assistants,
    isLoading: loading || !data,
    ...props,
  };
};
