import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  concat,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'Apollo-Require-Preflight': 'true',
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : null,
    },
  });
  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(
    authMiddleware,
    createUploadLink({
      uri: `${process.env.REACT_APP_URL}/graphql`,
    }),
  ),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

export default client;
