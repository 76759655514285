const fetchURL = async (
  url: string,
  method: string,
  payload = null,
  responseType = 'json',
) => {
  const fetchOptions: any = {
    mode: 'cors',
    credentials: 'omit',
    headers: {
      Accept: 'application/json',
    },
  };
  const token = window.localStorage.getItem('token');
  if (token) {
    fetchOptions.headers.Authorization = `Bearer ${token}`;
  }
  if (method === 'POST' || method === 'PUT') {
    fetchOptions.method = method;
    fetchOptions.url = url;
    fetchOptions.headers['Content-Type'] = 'application/json;charset=UTF-8';
    fetchOptions.body = JSON.stringify(payload || {});
  } else if (method === 'GET' || method === 'DELETE') {
    const querystring = new URLSearchParams(payload || {});
    fetchOptions.method = method;
    fetchOptions.url = `${url}?${querystring.toString()}`;
  } else if (method === 'RAW') {
    fetchOptions.method = 'POST';
    fetchOptions.url = url;
    fetchOptions.body = payload;
  } else {
    throw new Error(`Request unknown method "${method}".`);
  }
  const response = await fetch(fetchOptions.url, fetchOptions);
  if (!response || !response.ok) {
    const err = await response.json();
    throw err;
  }
  if (responseType === 'blob') {
    return response.blob();
  }
  const jsonResponse = await response.json();
  return jsonResponse.data;
};

export const GET = (url: any, querystring: any = {}, responseType = 'json') =>
  fetchURL(url, 'GET', querystring, responseType);

export const POST = (url: any, body: any = {}, responseType = 'json') =>
  fetchURL(url, 'POST', body, responseType);

export const PUT = (url: any, body: any = {}, responseType = 'json') =>
  fetchURL(url, 'PUT', body, responseType);

export const DELETE = (
  url: any,
  querystring: any = {},
  responseType = 'json',
) => fetchURL(url, 'DELETE', querystring, responseType);

export const RAW = (url: any, payload: any, responseType = 'json') =>
  fetchURL(url, 'RAW', payload, responseType);
