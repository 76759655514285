import { gql } from '@apollo/client';

export const FRAG_ACCOUNT = gql`
  fragment AccountData on AccountEntity {
    id
    key: id
    groupId
    departmentIds
    type
    noma
    email
    firstName
    lastName
    shouldNotify
    isActive
    createdAt
    updatedAt
  }
`;

export const FRAG_CATEGORY = gql`
  fragment CategoryData on CategoryEntity {
    id
    key: id
    name
    color
    priority
    displayOrder
    createdAt
    updatedAt
  }
`;

export const FRAG_GROUP = gql`
  fragment GroupData on GroupEntity {
    id
    key: id
    name
    categoryIds
    availability
    createdAt
    updatedAt
  }
`;

export const FRAG_OBLIATION = gql`
  fragment ObligationData on ObligationEntity {
    id
    key: id
    categoryId
    accountId
    specifier
    date
    moment
    takeoverAccountId
    takeoverHistory
    absent
    createdAt
    updatedAt
  }
`;

export const FRAG_OBLIATION_TAKEOVER = gql`
  fragment ObligationTakeoverData on ObligationTakeoverEntity {
    id
    key: id
    obligationId
    fromAccountId
    toAccountId
    action
    reason
    createdAt
    updatedAt
  }
`;

export const FRAG_TEMPLATE = gql`
  fragment TemplateData on TemplateEntity {
    id
    key: id
    name
    createdAt
    updatedAt
  }
`;

export const FRAG_ABSENCE = gql`
  fragment AbsenceData on AbsenceEntity {
    id
    key: id
    accountId
    date
    moment
    timelaps
    reason
    status
    createdAt
    updatedAt
    total
  }
`;

export const FRAG_DEPARTMENT = gql`
  fragment DepartmentData on DepartmentEntity {
    id
    key: id
    name
    code
    color
    displayOrder
    createdAt
    updatedAt
  }
`;

export const FRAG_SHEETTEMPLATE = gql`
  fragment SheetTemplateData on SheetTemplateEntity {
    id
    key: id
    departmentId
    name
    referenceType
    content
    content2
    instructions
    displayOrder
    computeGrade
    gradeNotes
    createdAt
    updatedAt
  }
`;

export const FRAG_SHEET = gql`
  fragment SheetData on SheetEntity {
    id
    key: id
    sheetTemplateId
    accountId
    assistantId
    status
    date
    reference
    isExam
    values
    values2
    history
    grade
    createdAt
    updatedAt
    total
  }
`;
