import { Breadcrumb, Col, Divider, Row, Tabs, Typography } from 'antd';
import * as d3 from 'd3';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useQueryObligationsStatisticsAbsence,
  useQueryObligationsStatisticsAll,
  useQueryObligationsStatisticsPast,
} from '../../gql/queries/obligations';

const margin = {
  bottom: 100,
  left: 270,
  right: 0,
  top: 200,
};

const ObligationsStatistics = () => {
  const { isLoading: isLoadingAll, obligationStatisticsAll } =
    useQueryObligationsStatisticsAll();
  const { isLoading: isLoadingPast, obligationStatisticsPast } =
    useQueryObligationsStatisticsPast();
  const { isLoading: isLoadingAbsence, obligationStatisticsAbsence } =
    useQueryObligationsStatisticsAbsence();
  const ref = useRef(null);
  const [tab, setTab] = useState('all');
  useEffect(() => {
    if (
      !ref ||
      !ref.current ||
      isLoadingAll ||
      !obligationStatisticsAll ||
      isLoadingPast ||
      !obligationStatisticsPast ||
      isLoadingAbsence ||
      !obligationStatisticsAbsence
    ) {
      return;
    }
    const obligationStatistics =
      tab === 'all'
        ? obligationStatisticsAll
        : tab === 'past'
        ? obligationStatisticsPast
        : tab === 'absence'
        ? obligationStatisticsAbsence
        : obligationStatisticsPast;
    const width =
      obligationStatistics.categories.length * 30 + margin.left + margin.right;
    const height =
      obligationStatistics.students.length * 30 + margin.top + margin.bottom;
    const maxByCategory = Object.fromEntries(
      obligationStatistics.categories.map((c: any) => [
        c.id,
        Math.max(
          ...obligationStatistics.obligations
            .filter((o: any) => o.categoryId === c.id)
            .map((o: any) => o.count),
        ),
      ]),
    );
    // SVG
    const svg = d3
      .select(ref.current)
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height]);
    svg.selectAll('*').remove();
    // Axis
    const xAxis = d3
      .scaleBand()
      .domain(obligationStatistics.categories.map((c: any) => c.id))
      .range([margin.left, width - margin.right])
      .paddingInner(0.15);
    const yAxis = d3
      .scaleBand()
      .domain(obligationStatistics.students.map((s: any) => s.id))
      .range([margin.top, height - margin.bottom])
      .paddingInner(0.15);
    // Categories
    svg
      .append('g')
      .attr('transform', `translate(0, ${margin.top}) rotate(-90)`)
      .selectAll('text')
      .data(obligationStatistics.categories)
      .join('text')
      .attr('class', 'matrix-label')
      .attr('x', 5)
      .attr('y', (c: any) => (xAxis(c.id) || 0) + xAxis.bandwidth() / 2)
      .text((s: any) => s.name)
      .attr('dy', '0.35em');
    // Students
    svg
      .append('g')
      .selectAll('text')
      .data(obligationStatistics.students)
      .join('text')
      .attr('class', 'matrix-label')
      .attr('x', margin.left - 5)
      .attr('y', (s: any) => (yAxis(s.id) || 0) + yAxis.bandwidth() / 2)
      .text((c: any) => `${c.firstName} ${c.lastName}`)
      .attr('dy', '0.35em')
      .attr('text-anchor', 'end');
    // Dots
    svg
      .append('g')
      .selectAll('g')
      .data(obligationStatistics.obligations)
      .join('g')
      .each(function makeCell(data) {
        d3.select(this)
          .append('rect')
          .attr('class', 'matrix-cell')
          .attr('x', (o: any) => xAxis(o.categoryId) || 0)
          .attr('y', (o: any) => yAxis(o.accountId) || 0)
          .attr('width', xAxis.bandwidth())
          .attr('height', yAxis.bandwidth())
          .attr('rx', 5)
          .attr('fill', (o: any) =>
            d3.interpolateViridis(o.count / maxByCategory[o.categoryId]),
          );
        d3.select(this)
          .append('text')
          .attr('class', 'matrix-text')
          .attr(
            'x',
            (o: any) => (xAxis(o.categoryId) || 0) + xAxis.bandwidth() / 2,
          )
          .attr(
            'y',
            (o: any) => (yAxis(o.accountId) || 0) + yAxis.bandwidth() / 2 + 4,
          )
          .attr('text-anchor', 'middle')
          .text((o: any) => o.count);
      });
  }, [
    ref,
    ref.current,
    tab,
    isLoadingAll,
    obligationStatisticsAll,
    isLoadingPast,
    obligationStatisticsPast,
    isLoadingAbsence,
    obligationStatisticsAbsence,
  ]);
  return (
    <>
      <Breadcrumb
        items={[
          { title: <Link to="/statistics">Statistiques</Link> },
          { title: <Link to="/statistics/obligations">Obligations</Link> },
        ]}
      />
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Statistiques sur les obligations</Typography.Title>
        </Col>
      </Row>
      <Divider />
      <Tabs
        defaultActiveKey="all"
        items={[
          {
            key: 'all',
            label: 'Statistiques passées et futures',
            children: '',
          },
          {
            key: 'past',
            label: 'Statistiques passées',
            children: '',
          },
          {
            key: 'absence',
            label: 'Absences non-justifiées',
            children: '',
          },
        ]}
        onChange={setTab}
      />
      <div className="matrix">
        <svg ref={ref} />
      </div>
    </>
  );
};

export default ObligationsStatistics;
