import { Spin } from 'antd';
import { useEffect } from 'react';
import { appVarLogout } from '../../gql/appVar';

const Logout = () => {
  useEffect(() => {
    appVarLogout();
  }, []);
  return <Spin size="large" />;
};

export default Logout;
