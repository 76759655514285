import { Collapse } from 'antd';
import cx from 'classnames';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import useIsMobile from '../../helpers/useMobile';

const KEYS = [
  'd0_AM',
  'd0_PM',
  'd1_AM',
  'd1_PM',
  'd2_AM',
  'd2_PM',
  'd3_AM',
  'd3_PM',
  'd4_AM',
  'd4_PM',
  'd5_AM',
  'd5_PM',
  'd6_AM',
  'd6_PM',
];

export const useFormatTable = ({
  obligations = [],
  absences = [],
  date,
  categories,
  category,
  student,
}: any) =>
  useMemo(() => {
    // Filter obligations by category and student
    const filteredObligations = (obligations || [])
      .filter((slot: any) => !category || slot.categoryId === category)
      .filter(
        (slot: any) => student === undefined || slot.accountId === student,
      );
    const filteredAbsences = absences
      .filter((slot: any) => !category || category === 'ABSENCE')
      .filter(
        (slot: any) => student === undefined || slot.accountId === student,
      );
    // Filter and sort  categories
    const filteredCategories = (categories || [])
      .filter((c: any) => !category || c.id === category)
      .sort((a: any, b: any) => a.displayOrder - b.displayOrder);
    // Find the maximum number of obligations per category in a day
    const maxPerCategory = Object.fromEntries(
      filteredCategories.map((c: any) => [
        c.id,
        Math.max(
          ...KEYS.map(
            (key) =>
              filteredObligations.filter(
                (o: any) =>
                  o.categoryId === c.id &&
                  key ===
                    `d${dayjs.utc(o.date).day()}_${o.moment.toUpperCase()}`,
              ).length,
          ),
        ),
      ]),
    );
    // Construct the table
    const table = {
      d0_AM: [] as any[],
      d0_PM: [] as any[],
      d1_AM: [] as any[],
      d1_PM: [] as any[],
      d2_AM: [] as any[],
      d2_PM: [] as any[],
      d3_AM: [] as any[],
      d3_PM: [] as any[],
      d4_AM: [] as any[],
      d4_PM: [] as any[],
      d5_AM: [] as any[],
      d5_PM: [] as any[],
      d6_AM: [] as any[],
      d6_PM: [] as any[],
    };
    filteredCategories.forEach((c: any) => {
      KEYS.forEach((key: any) => {
        const obligs = filteredObligations.filter(
          (o: any) =>
            o.categoryId === c.id &&
            key === `d${dayjs.utc(o.date).day()}_${o.moment.toUpperCase()}`,
        );
        const fills = Array(maxPerCategory[c.id] - obligs.length).fill('empty');
        table[key as keyof typeof table].push(...obligs);
        table[key as keyof typeof table].push(...fills);
        if (maxPerCategory[c.id] !== 0) {
          table[key as keyof typeof table].push('divider');
        }
      });
    });
    KEYS.forEach((key: any) => {
      const obligs = filteredAbsences
        .filter(
          (o: any) =>
            key === `d${dayjs.utc(o.date).day()}_${o.moment.toUpperCase()}`,
        )
        .map((o: any) => ({
          ...o,
          category: {
            id: '0',
            name: 'ABSENCE',
            color: '#DDD',
          },
          isAbsence: true,
        }));
      table[key as keyof typeof table].push(...obligs);
    });
    const startOfWeek = dayjs.utc(date).startOf('week');
    const columns = [
      {
        key: 'd1_AM',
        label: 'Lun AM',
        isToday: dayjs.utc().isSame(startOfWeek.add(0, 'day'), 'day'),
        items: table['d1_AM'],
      },
      {
        key: 'd1_PM',
        label: 'Lun PM',
        isToday: dayjs.utc().isSame(startOfWeek.add(0, 'day'), 'day'),
        items: table['d1_PM'],
      },
      {
        key: 'd2_AM',
        label: 'Mar AM',
        isToday: dayjs.utc().isSame(startOfWeek.add(1, 'day'), 'day'),
        items: table['d2_AM'],
      },
      {
        key: 'd2_PM',
        label: 'Mar PM',
        isToday: dayjs.utc().isSame(startOfWeek.add(1, 'day'), 'day'),
        items: table['d2_PM'],
      },
      {
        key: 'd3_AM',
        label: 'Mer AM',
        isToday: dayjs.utc().isSame(startOfWeek.add(2, 'day'), 'day'),
        items: table['d3_AM'],
      },
      {
        key: 'd3_PM',
        label: 'Mer PM',
        isToday: dayjs.utc().isSame(startOfWeek.add(2, 'day'), 'day'),
        items: table['d3_PM'],
      },
      {
        key: 'd4_AM',
        label: 'Jeu AM',
        isToday: dayjs.utc().isSame(startOfWeek.add(3, 'day'), 'day'),
        items: table['d4_AM'],
      },
      {
        key: 'd4_PM',
        label: 'Jeu PM',
        isToday: dayjs.utc().isSame(startOfWeek.add(3, 'day'), 'day'),
        items: table['d4_PM'],
      },
      {
        key: 'd5_AM',
        label: 'Ven AM',
        isToday: dayjs.utc().isSame(startOfWeek.add(4, 'day'), 'day'),
        items: table['d5_AM'],
      },
      {
        key: 'd5_PM',
        label: 'Ven PM',
        isToday: dayjs.utc().isSame(startOfWeek.add(4, 'day'), 'day'),
        items: table['d5_PM'],
      },
    ];
    return columns;
  }, [obligations, date, categories, category, student]);

export const FormatTable = ({ table, as: Slot, ...props }: any) => {
  const isMobile = useIsMobile();
  if (isMobile) {
    const items = table.map((col: any) => ({
      key: col.key,
      label: col.label,
      children: (
        <div>
          {col.items
            .filter((slot: any) => slot !== 'empty' && slot !== 'divider')
            .map((slot: any, i: any) => (
              <Slot key={slot.id} slot={slot} {...props} />
            ))}
        </div>
      ),
    }));
    return (
      <div className="schedule-small">
        <Collapse accordion items={items} />
      </div>
    );
  }
  return (
    <div className="schedule">
      {table.map((col: any) => (
        <div
          key={col.key}
          className={cx({
            today: col.isToday,
          })}
        >
          <b>{col.label}</b>
          {col.items.map((slot: any, i: any) =>
            slot === 'empty' ? (
              <div className="slot empty" key={i} />
            ) : slot === 'divider' ? (
              <div className="line" key={i} />
            ) : (
              <Slot key={slot.id} slot={slot} {...props} />
            ),
          )}
        </div>
      ))}
    </div>
  );
};
