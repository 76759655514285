import {
  FileDoneOutlined,
  FileOutlined,
  FileSyncOutlined,
  LayoutOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Card, Col, Divider, Row, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';

const PlannerHome = () => {
  return (
    <>
      <Breadcrumb
        items={[{ title: <Link to="/planner">Planification</Link> }]}
      />
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Planification</Typography.Title>
        </Col>
      </Row>
      <Divider />
      <Space wrap>
        <Link to="/planner/step1">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<FileOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Créer un planning"
              description="Ètape 1 : créer un brouillon de planning à vérifier manuellement"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
        <Link to="/planner/step2">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<FileSyncOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Améliorer un planning"
              description="Ètape 2 : améliorer un brouillon de planning"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
        <Link to="/planner/step3">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<FileDoneOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Valider un planning"
              description="Ètape 3 : mettre en ligne un planning vérifié"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
      </Space>
      <Divider />
      <Space wrap>
        <Link to="/planner/templates">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<LayoutOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Canevas réutilisables"
              description="Gérer les canevas existants"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
      </Space>
    </>
  );
};

export default PlannerHome;
