import { SendOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  InputNumber,
  Row,
  Table,
  Transfer,
  Typography,
  Upload,
} from 'antd';
import { saveAs } from 'file-saver';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQueryStudents } from '../../gql/queries/accounts';
import { RAW } from '../../helpers/request';

const difference = (arr1: string[], arr2: string[]) =>
  arr1.filter((x) => !arr2.includes(x));

const PlannerStep1 = () => {
  // List
  const { students } = useQueryStudents();
  // Submit
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const handleSubmit = useCallback(
    async ({ file, nWeeks, studentIds }: any) => {
      try {
        setSubmitLoading(true);
        setSubmitError('');
        const data = new FormData();
        data.append('file', file.file);
        data.append('nWeeks', nWeeks);
        data.append('studentIds', studentIds);
        const blob = await RAW(
          `${process.env.REACT_APP_URL}/api/obligations/step1`,
          data,
          'blob',
        );
        saveAs(blob, 'etape_1.xlsx');
      } catch (err: any) {
        if (err.message.startsWith('Category::')) {
          const [, category] = err.message.split('::');
          setSubmitError(
            `Le canevas fait référence à la catégorie "${category}" qui n'existe pas. Vous devez l'ajouter à l'application ou retirer la ligne du canevas afin de continuer.`,
          );
        } else {
          setSubmitError("Une erreur s'est produite. Veuillez réessayer.");
        }
      } finally {
        setSubmitLoading(false);
      }
    },
    [],
  );
  // Columns
  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'NOMA',
      dataIndex: 'noma',
      key: 'noma',
    },
    {
      title: 'Nom de famille',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Prénom',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Groupe',
      key: 'group',
      render: ({ group }: any) => group?.name || '',
    },
  ];
  return (
    <>
      <Breadcrumb
        items={[
          { title: <Link to="/planner">Planification</Link> },
          { title: <Link to="/planner/step1">Créer un planning</Link> },
        ]}
      />
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Créer un planning</Typography.Title>
        </Col>
      </Row>
      <Divider />
      <Form
        initialValues={{
          file: null,
          nWeeks: 4,
          studentIds: [],
        }}
        layout="vertical"
        onFinish={handleSubmit}
        disabled={submitLoading}
      >
        <Alert
          type="info"
          message="Information"
          description="La première étape de la création d'un planning se base sur un canevas (un document Excel contenant la semaine type) afin de générer un brouillon sur un nombre de semaines donné. Le groupe auquel appartient un étudiant est utilisé afin de déterminer les catégories qu'il peut prendre en charge."
          showIcon
          className="error"
        />
        {submitError && (
          <Alert type="error" message={submitError} banner className="error" />
        )}
        <Form.Item noStyle shouldUpdate>
          {({ setFieldValue }) => (
            <Form.Item
              label="Canevas"
              name="file"
              rules={[
                { required: true, message: 'Veuillez choisir un canevas.' },
              ]}
            >
              <Upload
                accept=".xlsx"
                maxCount={1}
                beforeUpload={(file) => {
                  setFieldValue('file', file);
                  return false;
                }}
              >
                <Button icon={<UploadOutlined />}>
                  Selectionner un canevas
                </Button>
              </Upload>
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item
          name="nWeeks"
          label="Nombre de semaines à générer"
          rules={[
            {
              required: true,
              message: 'Veuillez renseigner un nombre de semaines à générer.',
            },
            {
              type: 'number',
              min: 1,
              max: 52,
              message:
                'Veuillez choisir un nombre de semaines à générer entre 1 et 52.',
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Étudiants à inclure dans le planning"
          name="studentIds"
          valuePropName="targetKeys"
          rules={[
            {
              required: true,
              message: 'Veuillez sélectionner au moins un étudiant.',
            },
          ]}
        >
          <Transfer
            dataSource={students}
            showSearch
            filterOption={(inputValue, student) =>
              student.firstName
                .toLowerCase()
                .includes(inputValue.toLocaleLowerCase()) ||
              student.lastName
                .toLowerCase()
                .includes(inputValue.toLocaleLowerCase()) ||
              student.group?.name
                .toLowerCase()
                .includes(inputValue.toLocaleLowerCase())
            }
          >
            {({
              filteredItems,
              onItemSelectAll,
              onItemSelect,
              selectedKeys: listSelectedKeys,
              disabled: listDisabled,
            }) => {
              const rowSelection = {
                onSelectAll(selected: boolean, selectedRows: any) {
                  const treeSelectedKeys = selectedRows.map(
                    ({ key }: any) => key,
                  );
                  const diffKeys = selected
                    ? difference(treeSelectedKeys, listSelectedKeys)
                    : difference(listSelectedKeys, treeSelectedKeys);
                  onItemSelectAll(diffKeys, selected);
                },
                onSelect({ id }: any, selected: boolean) {
                  onItemSelect(id, selected);
                },
                selectedRowKeys: listSelectedKeys,
              };
              return (
                <Table
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={filteredItems}
                  size="small"
                  style={{ pointerEvents: listDisabled ? 'none' : undefined }}
                  onRow={({ id, disabled: itemDisabled }) => ({
                    onClick: () => {
                      if (itemDisabled || listDisabled) return;
                      onItemSelect(id, !listSelectedKeys.includes(id));
                    },
                  })}
                />
              );
            }}
          </Transfer>
        </Form.Item>
        <Button htmlType="submit" icon={<SendOutlined />} type="primary">
          Envoyer
        </Button>
      </Form>
    </>
  );
};

export default PlannerStep1;
