import { Alert, Button, Form, Input, Result, Typography } from 'antd';
import { useCallback, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useMutationAuthResetPassword } from '../../gql/mutations/auth';

const ResetPassword = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [errorCode, setErrorCode] = useState(undefined);
  const [isDone, setDone] = useState(false);
  const [searchParams] = useSearchParams();
  const mutationAuthResetPassword = useMutationAuthResetPassword();
  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        setSubmitting(true);
        setErrorCode(undefined);
        setDone(false);
        await mutationAuthResetPassword({
          email: searchParams.get('email'),
          recoveryToken: searchParams.get('recoveryToken'),
          ...values,
        });
        setDone(true);
      } catch (err: any) {
        setErrorCode(err.message);
      } finally {
        setSubmitting(false);
      }
    },
    [mutationAuthResetPassword, searchParams],
  );
  return (
    <Form
      className="container-small"
      layout="vertical"
      initialValues={{
        password: '',
      }}
      onFinish={handleSubmit}
      disabled={isSubmitting}
    >
      <Typography.Title className="title">
        Réinitialisation du mot de passe
      </Typography.Title>
      {isDone ? (
        <Result
          status="success"
          title="Mot de passe réinitialisé !"
          subTitle="Votre mot de passe a été réinitialisé avec succès. Vous pouvez maintenant vous connecter à l'aide de celui-ci."
          extra={
            <Link to="/login">
              <Button type="primary">Retourner à la page de connection</Button>
            </Link>
          }
        />
      ) : (
        <>
          {errorCode === '404' ? (
            <Alert
              type="error"
              message="L'adresse email renseignée n'est liée à aucun compte."
              banner
              className="error"
            />
          ) : errorCode === '401' ? (
            <Alert
              type="error"
              message="La durée de réinitialisation du mot de passe a expirée. Veuillez recommencer la procédure."
              banner
              className="error"
            />
          ) : (
            errorCode && (
              <Alert
                type="error"
                message="Une erreur est survenue. Veuillez réessayer."
                banner
                className="error"
              />
            )
          )}
          <Form.Item
            label="Nouveau mot de passe"
            name="password"
            rules={[
              {
                required: true,
                message: 'Veuillez renseigner un nouveau mot de passe.',
              },
            ]}
          >
            <Input type="password" placeholder="Nouveau mot de passe" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Réinitialiser le mot de passe
            </Button>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default ResetPassword;
