import { Breadcrumb, Col, Divider, Row, Typography } from 'antd';
import * as d3 from 'd3';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useQueryObligationsStatisticsTakeovers } from '../../gql/queries/obligations';

const margin = {
  bottom: 100,
  left: 270,
  right: 0,
  top: 200,
};

const titles = {
  request: 'Demandes',
  cancel: 'Annulations',
  accept: 'Acceptations',
  reject: 'Refus',
};

const TakeoversStatistics = () => {
  const { isLoading, obligationStatisticsTakeovers } =
    useQueryObligationsStatisticsTakeovers();
  const ref = useRef(null);
  useEffect(() => {
    if (!ref || !ref.current || isLoading || !obligationStatisticsTakeovers) {
      return;
    }
    const obligationStatistics = obligationStatisticsTakeovers;
    const width =
      Object.entries(titles).length * 30 + margin.left + margin.right;
    const height =
      obligationStatistics.students.length * 30 + margin.top + margin.bottom;
    const maxByCategory = Object.fromEntries(
      Object.entries(titles).map(([id]: any) => [
        id,
        Math.max(
          ...obligationStatistics.takeovers
            .filter((o: any) => o.action === id)
            .map((o: any) => o.count),
        ),
      ]),
    );
    // SVG
    const svg = d3
      .select(ref.current)
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height]);
    svg.selectAll('*').remove();
    // Axis
    const xAxis = d3
      .scaleBand()
      .domain(Object.entries(titles).map(([id]: any) => id))
      .range([margin.left, width - margin.right])
      .paddingInner(0.15);
    const yAxis = d3
      .scaleBand()
      .domain(obligationStatistics.students.map((s: any) => s.id))
      .range([margin.top, height - margin.bottom])
      .paddingInner(0.15);
    // Categories
    svg
      .append('g')
      .attr('transform', `translate(0, ${margin.top}) rotate(-90)`)
      .selectAll('text')
      .data(Object.entries(titles))
      .join('text')
      .attr('class', 'matrix-label')
      .attr('x', 5)
      .attr('y', ([id]: any) => (xAxis(id) || 0) + xAxis.bandwidth() / 2)
      .text(([, name]: any) => name)
      .attr('dy', '0.35em');
    // Students
    svg
      .append('g')
      .selectAll('text')
      .data(obligationStatistics.students)
      .join('text')
      .attr('class', 'matrix-label')
      .attr('x', margin.left - 5)
      .attr('y', (s: any) => (yAxis(s.id) || 0) + yAxis.bandwidth() / 2)
      .text((c: any) => `${c.firstName} ${c.lastName}`)
      .attr('dy', '0.35em')
      .attr('text-anchor', 'end');
    // Dots
    svg
      .append('g')
      .selectAll('g')
      .data(obligationStatistics.takeovers)
      .join('g')
      .each(function makeCell(data) {
        d3.select(this)
          .append('rect')
          .attr('class', 'matrix-cell')
          .attr('x', (o: any) => xAxis(o.action) || 0)
          .attr('y', (o: any) => yAxis(o.accountId) || 0)
          .attr('width', xAxis.bandwidth())
          .attr('height', yAxis.bandwidth())
          .attr('rx', 5)
          .attr('fill', (o: any) =>
            d3.interpolateViridis(o.count / maxByCategory[o.action]),
          );
        d3.select(this)
          .append('text')
          .attr('class', 'matrix-text')
          .attr('x', (o: any) => (xAxis(o.action) || 0) + xAxis.bandwidth() / 2)
          .attr(
            'y',
            (o: any) => (yAxis(o.accountId) || 0) + yAxis.bandwidth() / 2 + 4,
          )
          .attr('text-anchor', 'middle')
          .text((o: any) => o.count);
      });
  }, [ref, ref.current, isLoading, obligationStatisticsTakeovers]);
  return (
    <>
      <Breadcrumb
        items={[
          { title: <Link to="/statistics">Statistiques</Link> },
          { title: <Link to="/statistics/takeovers">Transferts</Link> },
        ]}
      />
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Statistiques sur les transferts</Typography.Title>
        </Col>
      </Row>
      <Divider />
      <div className="matrix">
        <svg ref={ref} />
      </div>
    </>
  );
};

export default TakeoversStatistics;
