import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const MUTATION_OBLIGATION_CREATE = gql`
  mutation ObligationCreate(
    $categoryId: String
    $accountId: String
    $takeoverAccountId: String
    $specifier: String
    $date: String!
    $moment: String!
    $absent: Boolean
  ) {
    obligationCreate(
      categoryId: $categoryId
      accountId: $accountId
      takeoverAccountId: $takeoverAccountId
      specifier: $specifier
      date: $date
      moment: $moment
      absent: $absent
    ) {
      id
    }
  }
`;

export function useMutationObligationCreate(options = {}) {
  const [mutation] = useMutation(MUTATION_OBLIGATION_CREATE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_OBLIGATION_UPDATE = gql`
  mutation ObligationUpdate(
    $id: ID!
    $categoryId: String
    $accountId: String
    $takeoverAccountId: String
    $specifier: String
    $date: String
    $moment: String
    $absent: Boolean
  ) {
    obligationUpdate(
      id: $id
      categoryId: $categoryId
      accountId: $accountId
      takeoverAccountId: $takeoverAccountId
      specifier: $specifier
      date: $date
      moment: $moment
      absent: $absent
    ) {
      id
    }
  }
`;

export function useMutationObligationUpdate(options = {}) {
  const [mutation] = useMutation(MUTATION_OBLIGATION_UPDATE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_OBLIGATION_REROLL = gql`
  mutation ObligationReroll($id: ID!) {
    obligationReroll(id: $id) {
      id
    }
  }
`;

export function useMutationObligationReroll(options = {}) {
  const [mutation] = useMutation(MUTATION_OBLIGATION_REROLL, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_OBLIGATION_DELETE = gql`
  mutation ObligationDelete($id: ID!) {
    obligationDelete(id: $id)
  }
`;

export function useMutationObligationDelete(options = {}) {
  const [mutation] = useMutation(MUTATION_OBLIGATION_DELETE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_OBLIGATION_ABSENT = gql`
  mutation ObligationAbsent($id: ID!) {
    obligationAbsent(id: $id)
  }
`;

export function useMutationObligationAbsent(options = {}) {
  const [mutation] = useMutation(MUTATION_OBLIGATION_ABSENT, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_OBLIGATION_TAKEOVER_REQUEST = gql`
  mutation ObligationTakeoverRequest(
    $id: ID!
    $takeoverAccountId: String!
    $reason: String!
  ) {
    obligationTakeoverRequest(
      id: $id
      takeoverAccountId: $takeoverAccountId
      reason: $reason
    )
  }
`;

export function useMutationObligationTakeoverRequest(options = {}) {
  const [mutation] = useMutation(MUTATION_OBLIGATION_TAKEOVER_REQUEST, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_OBLIGATION_TAKEOVER_ACCEPT = gql`
  mutation ObligationTakeoverAccept($id: ID!) {
    obligationTakeoverAccept(id: $id)
  }
`;

export function useMutationObligationTakeoverAccept(options = {}) {
  const [mutation] = useMutation(MUTATION_OBLIGATION_TAKEOVER_ACCEPT, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_OBLIGATION_TAKEOVER_REJECT = gql`
  mutation ObligationTakeoverReject($id: ID!) {
    obligationTakeoverReject(id: $id)
  }
`;

export function useMutationObligationTakeoverReject(options = {}) {
  const [mutation] = useMutation(MUTATION_OBLIGATION_TAKEOVER_REJECT, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_OBLIGATION_TAKEOVER_CANCEL = gql`
  mutation ObligationTakeoverCancel($id: ID!) {
    obligationTakeoverCancel(id: $id)
  }
`;

export function useMutationObligationTakeoverCancel(options = {}) {
  const [mutation] = useMutation(MUTATION_OBLIGATION_TAKEOVER_CANCEL, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}
