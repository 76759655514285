import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  FRAG_ACCOUNT,
  FRAG_DEPARTMENT,
  FRAG_SHEET,
  FRAG_SHEETTEMPLATE,
} from '../queries/fragments';

const MUTATION_SHEET_CREATE = gql`
  mutation SheetCreate($sheetTemplateId: String!) {
    sheetCreate(sheetTemplateId: $sheetTemplateId) {
      ...SheetData
      account {
        ...AccountData
      }
      assistant {
        ...AccountData
      }
      sheetTemplate {
        ...SheetTemplateData
        department {
          ...DepartmentData
        }
      }
    }
  }
  ${FRAG_SHEET}
  ${FRAG_ACCOUNT}
  ${FRAG_SHEETTEMPLATE}
  ${FRAG_DEPARTMENT}
`;

export function useMutationSheetCreate(options = {}) {
  const [mutation] = useMutation(MUTATION_SHEET_CREATE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_SHEET_UPDATE = gql`
  mutation SheetUpdate(
    $id: ID!
    $assistantId: String
    $status: String
    $reference: String
    $isExam: Boolean
    $values: JSON
    $values2: JSON
  ) {
    sheetUpdate(
      id: $id
      assistantId: $assistantId
      status: $status
      reference: $reference
      isExam: $isExam
      values: $values
      values2: $values2
    ) {
      ...SheetData
      account {
        ...AccountData
      }
      assistant {
        ...AccountData
      }
      sheetTemplate {
        ...SheetTemplateData
        department {
          ...DepartmentData
        }
      }
    }
  }
  ${FRAG_SHEET}
  ${FRAG_ACCOUNT}
  ${FRAG_SHEETTEMPLATE}
  ${FRAG_DEPARTMENT}
`;

export function useMutationSheetUpdate(options = {}) {
  const [mutation] = useMutation(MUTATION_SHEET_UPDATE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_SHEET_DELETE = gql`
  mutation SheetDelete($id: ID!) {
    sheetDelete(id: $id)
  }
`;

export function useMutationSheetDelete(options = {}) {
  const [mutation] = useMutation(MUTATION_SHEET_DELETE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}
