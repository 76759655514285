import { Col, Divider, Row, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import { useQueryObligationTakeovers } from '../../gql/queries/obligationTakeovers';

const TakeoversAdmin = () => {
  // List
  const { isLoading, obligationTakeovers } = useQueryObligationTakeovers();
  // Columns
  const columns = [
    {
      title: '',
      key: 'date',
      render: (o: any) => dayjs(o.createdAt).format('DD MMM YYYY, HH:mm'),
    },
    {
      title: 'Demandeur',
      key: 'from',
      render: (o: any) =>
        o.fromAccount
          ? `${o.fromAccount.firstName} ${o.fromAccount.lastName}`
          : 'VIDE',
    },
    {
      title: 'Receveur',
      key: 'to',
      render: (o: any) =>
        o.toAccount
          ? `${o.toAccount.firstName} ${o.toAccount.lastName}`
          : 'VIDE',
    },
    {
      title: 'Catégorie',
      key: 'category',
      render: (o: any) => o.obligation?.category?.name || 'VIDE',
    },
    {
      title: 'Jour',
      key: 'category',
      render: (o: any) =>
        o.obligation
          ? `${dayjs.utc(o.obligation.date).format('DD MMM YYYY')} ${
              o.obligation.moment
            }`
          : '',
    },
    {
      title: '',
      key: 'action',
      render: (o: any) =>
        o.action === 'request'
          ? 'Demande'
          : o.action === 'cancel'
          ? 'Annulation de la demande'
          : o.action === 'accept'
          ? 'Acceptation'
          : o.action === 'reject'
          ? 'Refus'
          : '',
    },
  ];
  return (
    <>
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Transferts</Typography.Title>
        </Col>
      </Row>
      <Divider />
      <Table
        dataSource={obligationTakeovers}
        columns={columns}
        loading={isLoading}
        rowKey="id"
      />
    </>
  );
};

export default TakeoversAdmin;
