import {
  BookOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  ContainerOutlined,
  DeploymentUnitOutlined,
  FormOutlined,
  LineChartOutlined,
  PieChartOutlined,
  RobotOutlined,
  SwapOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { Card, Col, Divider, Row, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useQueryMe } from '../../gql/queries/me';

const HomeAdmin = () => {
  const { me } = useQueryMe();
  const name = me ? me.firstName : '';
  return (
    <>
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Bonjour {name} !</Typography.Title>
        </Col>
      </Row>
      <Divider />
      <Typography.Title level={2}>Gestion générale</Typography.Title>
      <Space wrap>
        <Link to="/accounts">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<UserOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Utilisateurs"
              description="Gérer les utilisateurs (administateurs, superviseurs et étudiants)"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
      </Space>
      <Typography.Title level={2}>Gestion des obligations</Typography.Title>
      <Space wrap>
        <Link to="/categories">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<BookOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Catégories"
              description="Gérer les catégories des obligation"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
        <Link to="/groups">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={
              <UsergroupAddOutlined style={{ marginTop: 32, fontSize: 40 }} />
            }
          >
            <Card.Meta
              title="Groupes"
              description="Gérer les groupes d'utilisateurs et leurs accès aux obligations"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
        <Link to="/planner">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<CalendarOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Planification"
              description="Créer des brouillons de plannings, les améliorer et les mettre en ligne"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
        <Link to="/obligations">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<CarryOutOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Obligations"
              description="Gérer les obligations"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
        <Link to="/takeovers">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<SwapOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Transferts"
              description="Voir mes demandes de transfert"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
        <Link to="/absences">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<RobotOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Absences"
              description="Gérer les absences"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
      </Space>
      <Typography.Title level={2}>
        Gestion des carnets de stage
      </Typography.Title>
      <Space wrap>
        <Link to="/departments">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={
              <DeploymentUnitOutlined style={{ marginTop: 32, fontSize: 40 }} />
            }
          >
            <Card.Meta
              title="Disciplines"
              description="Gérer les disciplines des stages"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
        <Link to="/sheettemplates">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<FormOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Modèles de fiches"
              description="Gérer les modèles de fiches"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
        <Link to="/sheets">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={
              <ContainerOutlined style={{ marginTop: 32, fontSize: 40 }} />
            }
          >
            <Card.Meta
              title="Fiches"
              description="Gérer les fiches"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
        <Link to="/progress">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={
              <LineChartOutlined style={{ marginTop: 32, fontSize: 40 }} />
            }
          >
            <Card.Meta
              title="Avancement"
              description="Voir l'avancement de mon stage"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
      </Space>
      <Typography.Title level={2}>Autres</Typography.Title>
      <Space wrap>
        <Link to="/statistics">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<PieChartOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta title="Statistiques" style={{ textAlign: 'center' }} />
          </Card>
        </Link>
      </Space>
    </>
  );
};

export default HomeAdmin;
