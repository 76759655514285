import {
  DeleteOutlined,
  DownloadOutlined,
  PlusOutlined,
  SendOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
  Upload,
  message,
} from 'antd';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useMutationTemplateCreate,
  useMutationTemplateDelete,
} from '../../gql/mutations/templates';
import { useQueryTemplates } from '../../gql/queries/templates';
import { RAW } from '../../helpers/request';
import { searchText, sortText } from '../../helpers/table';

const PlannerTemplatesList = () => {
  // List
  const { isLoading, templates, refetch } = useQueryTemplates();
  // Create
  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(undefined);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const handleCreateOpen = useCallback(() => {
    setCreateLoading(false);
    setCreateError(undefined);
    setIsCreateOpen(true);
  }, []);
  const handleCreateClose = useCallback(() => {
    setCreateLoading(false);
    setCreateError(undefined);
    setIsCreateOpen(false);
  }, []);
  const mutationTemplateCreate = useMutationTemplateCreate();
  const handleCreate = useCallback(
    async ({ file, ...values }: any) => {
      try {
        setCreateLoading(true);
        setCreateError(undefined);
        const {
          data: { templateCreate },
        } = await mutationTemplateCreate(values);
        const data = new FormData();
        data.append('file', file.file);
        await RAW(
          `${process.env.REACT_APP_URL}/api/templates/${templateCreate.id}/upload`,
          data,
        );
        await refetch();
        handleCreateClose();
        message.success('Le canevas a été créée.');
      } catch (err: any) {
        setCreateError(err.message);
      } finally {
        setCreateLoading(false);
      }
    },
    [handleCreateClose, mutationTemplateCreate, refetch],
  );
  // Delete
  const mutationTemplateDelete = useMutationTemplateDelete();
  const handleDelete = useCallback(
    async (template: any) => {
      try {
        await mutationTemplateDelete({
          id: template.id,
        });
        await refetch();
        message.success('Le canevas a été supprimé.');
      } catch (err) {
        message.error("Une erreur s'est produite. Veuillez réessayer.");
      }
    },
    [mutationTemplateDelete, refetch],
  );
  // Columns
  const columns = [
    {
      title: 'Nom du canevas',
      dataIndex: 'name',
      key: 'name',
      ...sortText('name'),
      ...searchText('name'),
    },
    {
      title: '',
      key: 'options',
      width: 100,
      render: (template: any) => (
        <Space>
          <Button
            icon={<DownloadOutlined />}
            href={`${process.env.REACT_APP_URL}/api/templates/${
              template.id
            }/download?token=${window.localStorage.getItem('token')}`}
          />
          <Popconfirm
            okText="Oui"
            onConfirm={() => handleDelete(template)}
            title="Dernière chance. Êtes-vous sûr ?"
          >
            <Button icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  // Render
  return (
    <>
      <Breadcrumb
        items={[
          { title: <Link to="/planner">Planification</Link> },
          { title: <Link to="/planner/templates">Canevas</Link> },
        ]}
      />
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Canevas</Typography.Title>
        </Col>
        <Col>
          <Space wrap>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={handleCreateOpen}
            >
              Ajouter un canevas
            </Button>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Table
        dataSource={templates}
        columns={columns}
        loading={isLoading}
        rowKey="id"
      />
      {/* Create */}
      <Modal
        title="Ajouter un canevas"
        open={isCreateOpen}
        destroyOnClose
        footer={null}
        onCancel={handleCreateClose}
        width={800}
      >
        <Form
          initialValues={{
            name: '',
            file: undefined,
          }}
          preserve
          layout="vertical"
          onFinish={handleCreate}
          disabled={createLoading}
        >
          {createError && (
            <Alert
              type="error"
              message="Une erreur est survenue. Veuillez réessayer."
              banner
              className="error"
            />
          )}
          <Form.Item
            label="Nom du canevas"
            name="name"
            rules={[{ required: true, message: 'Veuillez renseigner un nom.' }]}
          >
            <Input placeholder="Canevas Mars 2023" />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ setFieldValue }) => (
              <Form.Item
                label="Fichier"
                name="file"
                rules={[
                  { required: true, message: 'Veuillez choisir un fichier.' },
                ]}
              >
                <Upload
                  accept=".xlsx"
                  maxCount={1}
                  beforeUpload={(file) => {
                    setFieldValue('file', file);
                    return false;
                  }}
                >
                  <Button icon={<UploadOutlined />}>
                    Selectionner un fichier
                  </Button>
                </Upload>
              </Form.Item>
            )}
          </Form.Item>
          <Button htmlType="submit" icon={<SendOutlined />} type="primary">
            Envoyer
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default PlannerTemplatesList;
