import { gql, useQuery } from '@apollo/client';
import { FRAG_CATEGORY, FRAG_GROUP } from './fragments';

const QUERY_GROUPS = gql`
  query groups {
    groups {
      ...GroupData
      categories {
        ...CategoryData
      }
    }
  }
  ${FRAG_GROUP}
  ${FRAG_CATEGORY}
`;

export const useQueryGroups = (options = {}) => {
  const { data, loading, ...props } = useQuery(QUERY_GROUPS, options);
  return {
    groups: data?.groups,
    isLoading: loading || !data,
    ...props,
  };
};

const QUERY_GROUP = gql`
  query group($id: ID!) {
    group(id: $id) {
      ...GroupData
      categories {
        ...CategoryData
      }
    }
  }
  ${FRAG_GROUP}
  ${FRAG_CATEGORY}
`;

export const useQueryGroup = (options = {}) => {
  const { data, loading, ...props } = useQuery(QUERY_GROUP, options);
  return {
    group: data?.group,
    isLoading: loading || !data,
    ...props,
  };
};
