import { Col, Divider, Row, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useQueryAbsences } from '../../gql/queries/absences';
import {
  renderTextChoices,
  searchTextChoices,
  sortDate,
  sortText,
} from '../../helpers/table';

const AbsencesSupervisor = () => {
  // List
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 20,
      showSizeChanger: true,
    },
    filters: {},
    columnKey: 'date',
    order: undefined,
  });
  const handleTableChange = useCallback(
    (pagination: any, filters: any, sorter: any) => {
      setTableParams({
        pagination,
        filters,
        ...sorter,
      });
    },
    [],
  );
  const { isLoading, absences, nAbsences } = useQueryAbsences({
    variables: {
      pageCurrent: tableParams.pagination.current,
      pageSize: tableParams.pagination.pageSize,
      orderBy: tableParams.columnKey,
      orderSort: tableParams.order,
      filters: tableParams.filters,
    },
  });
  // Columns
  const columns = [
    {
      title: 'Qui',
      key: 'student',
      render: (o: any) =>
        o.account ? `${o.account.firstName} ${o.account.lastName}` : 'VIDE',
    },
    {
      title: 'Date',
      key: 'date',
      render: (o: any) =>
        `${dayjs(o.date).format('DD MMM YYYY')} ${o.moment} (${o.timelaps})`,
      ...sortDate('date'),
    },
    {
      title: 'Justification',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Statut',
      dataIndex: 'status',
      key: 'status',
      ...renderTextChoices([
        { text: 'Demandée', value: 'request' },
        { text: 'Acceptée', value: 'accepted' },
        { text: 'Refusée', value: 'rejected' },
      ]),
      ...searchTextChoices('status', [
        { text: 'Demandée', value: 'request' },
        { text: 'Acceptée', value: 'accepted' },
        { text: 'Refusée', value: 'rejected' },
      ]),
      onFilter: undefined,
      ...sortText('status', 1),
    },
  ];
  // Render
  return (
    <>
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Absences</Typography.Title>
        </Col>
      </Row>
      <Divider />
      <Table
        dataSource={absences}
        columns={columns}
        loading={isLoading}
        rowKey="id"
        bordered
        pagination={{
          ...tableParams.pagination,
          total: nAbsences,
        }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default AbsencesSupervisor;
