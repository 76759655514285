import { MailOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, Result, Typography } from 'antd';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutationAuthRecoverPassword } from '../../gql/mutations/auth';

const RecoverPassword = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [errorCode, setErrorCode] = useState(undefined);
  const [isDone, setDone] = useState(false);
  const mutationAuthRecoverPassword = useMutationAuthRecoverPassword();
  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        setSubmitting(true);
        setErrorCode(undefined);
        setDone(false);
        await mutationAuthRecoverPassword(values);
        setDone(true);
      } catch (err: any) {
        setErrorCode(err.message);
      } finally {
        setSubmitting(false);
      }
    },
    [mutationAuthRecoverPassword],
  );
  return (
    <Form
      className="container-small"
      layout="vertical"
      initialValues={{
        email: '',
      }}
      onFinish={handleSubmit}
      disabled={isSubmitting}
    >
      <Typography.Title className="title">Mot de passe oublié</Typography.Title>
      {isDone ? (
        <Result
          icon={<MailOutlined />}
          title="Demande envoyée !"
          subTitle="Nous vous avons envoyé un email contenant un lien de réinitialisation de votre mot de passe. Pensez à regarder vos courriers indésirables si vous ne voyez rien dans votre boite de réception."
          extra={
            <Link to="/login">
              <Button type="primary">Retourner à la page d'accueil</Button>
            </Link>
          }
        />
      ) : (
        <>
          {errorCode === '404' ? (
            <Alert
              type="error"
              message="L'adresse email renseignée n'est liée à aucun compte."
              banner
              className="error"
            />
          ) : (
            errorCode && (
              <Alert
                type="error"
                message="Une erreur est survenue. Veuillez réessayer."
                banner
                className="error"
              />
            )
          )}
          <Form.Item
            label="Adresse email"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Veuillez renseigner une adresse email.',
              },
            ]}
          >
            <Input type="email" placeholder="Adresse email" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Demander la réinitialisation du mot de passe
            </Button>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default RecoverPassword;
