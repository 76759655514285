import { gql, useQuery } from '@apollo/client';
import {
  FRAG_ACCOUNT,
  FRAG_CATEGORY,
  FRAG_OBLIATION,
  FRAG_OBLIATION_TAKEOVER,
} from './fragments';

const QUERY_OBLIGATION_TAKEOVERS = gql`
  query ObligationTakeovers {
    obligationTakeovers {
      ...ObligationTakeoverData
      obligation {
        ...ObligationData
        category {
          ...CategoryData
        }
      }
      fromAccount {
        ...AccountData
      }
      toAccount {
        ...AccountData
      }
    }
  }
  ${FRAG_OBLIATION_TAKEOVER}
  ${FRAG_OBLIATION}
  ${FRAG_ACCOUNT}
  ${FRAG_CATEGORY}
`;

export const useQueryObligationTakeovers = (options = {}) => {
  const { data, loading, ...props } = useQuery(
    QUERY_OBLIGATION_TAKEOVERS,
    options,
  );
  return {
    obligationTakeovers: data?.obligationTakeovers,
    isLoading: loading || !data,
    ...props,
  };
};
