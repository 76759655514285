import {
  CarryOutOutlined,
  ContainerOutlined,
  LineChartOutlined,
  RobotOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { Card, Col, Divider, Row, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useQueryMe } from '../../gql/queries/me';

const HomeStudent = () => {
  const { me } = useQueryMe();
  const name = me ? me.firstName : '';
  return (
    <>
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Bonjour {name} !</Typography.Title>
        </Col>
      </Row>
      <Divider />
      <Typography.Title level={2}>Obligations</Typography.Title>
      <Space wrap>
        <Link to="/obligations">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<CarryOutOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Obligations"
              description="Voir mes obligations"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
        <Link to="/takeovers">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<SwapOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Transferts"
              description="Voir mes demandes de transfert"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
        <Link to="/absences">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={<RobotOutlined style={{ marginTop: 32, fontSize: 40 }} />}
          >
            <Card.Meta
              title="Absences"
              description="Gérer les absences"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
      </Space>
      <Typography.Title level={2}>Carnet de stage</Typography.Title>
      <Space wrap>
        <Link to="/sheets">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={
              <ContainerOutlined style={{ marginTop: 32, fontSize: 40 }} />
            }
          >
            <Card.Meta
              title="Fiches"
              description="Voir mes fiches de stage"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
        <Link to="/progress">
          <Card
            hoverable
            style={{ width: 300 }}
            cover={
              <LineChartOutlined style={{ marginTop: 32, fontSize: 40 }} />
            }
          >
            <Card.Meta
              title="Avancement"
              description="Voir l'avancement de mon stage"
              style={{ textAlign: 'center' }}
            />
          </Card>
        </Link>
      </Space>
    </>
  );
};

export default HomeStudent;
