import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const MUTATION_CATEGORY_CREATE = gql`
  mutation CategoryCreate(
    $name: String!
    $color: String!
    $priority: Int
    $displayOrder: Int
  ) {
    categoryCreate(
      name: $name
      color: $color
      priority: $priority
      displayOrder: $displayOrder
    ) {
      id
    }
  }
`;

export function useMutationCategoryCreate(options = {}) {
  const [mutation] = useMutation(MUTATION_CATEGORY_CREATE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_CATEGORY_UPDATE = gql`
  mutation CategoryUpdate(
    $id: ID!
    $name: String
    $color: String
    $priority: Int
    $displayOrder: Int
  ) {
    categoryUpdate(
      id: $id
      name: $name
      color: $color
      priority: $priority
      displayOrder: $displayOrder
    ) {
      id
    }
  }
`;

export function useMutationCategoryUpdate(options = {}) {
  const [mutation] = useMutation(MUTATION_CATEGORY_UPDATE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

const MUTATION_CATEGORY_DELETE = gql`
  mutation CategoryDelete($id: ID!) {
    categoryDelete(id: $id)
  }
`;

export function useMutationCategoryDelete(options = {}) {
  const [mutation] = useMutation(MUTATION_CATEGORY_DELETE, options);
  return useCallback(
    (variables = {}) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}
