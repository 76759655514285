import {
  HistoryOutlined,
  LeftOutlined,
  RightOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useQueryAbsences } from '../../gql/queries/absences';
import { useQueryStudents } from '../../gql/queries/accounts';
import { useQueryCategories } from '../../gql/queries/categories';
import { useQueryObligations } from '../../gql/queries/obligations';
import History from './History';
import { FormatTable, useFormatTable } from './formatTable';

const Slot = ({ slot, handleHistory }: any) => (
  <div
    style={{
      borderColor: slot.category?.color || 'black',
    }}
    className="slot"
  >
    <p className="slot-category">{slot.category?.name || 'NA'}</p>
    <p className="slot-specifier">{slot.specifier}</p>
    <p className="slot-student">
      {slot.account
        ? `${slot.account.firstName} ${slot.account.lastName}`
        : 'VIDE'}
    </p>
    {slot.absent && <p className="slot-absent">ABSENT</p>}
    {slot.takeoverAccount && (
      <p className="slot-takeover">
        {`Proposé à ${slot.takeoverAccount.firstName} ${slot.takeoverAccount.lastName}`}
      </p>
    )}
    {!slot.isAbsence && (
      <div className="slot-actions">
        <Dropdown
          menu={{
            items: [
              {
                key: 'history',
                label: 'Historique',
                icon: <HistoryOutlined />,
                onClick: () => handleHistory(slot),
              },
            ],
          }}
          placement="bottomRight"
          arrow
        >
          <Button icon={<SettingOutlined />} size="small" />
        </Dropdown>
      </div>
    )}
  </div>
);

const ObligationsViewer = () => {
  // Parameters
  const [date, setDate] = useState<any>(() => dayjs());
  const [category, setCategory] = useState(null);
  const [student, setStudent] = useState(undefined);
  // List
  const { isLoading, obligations } = useQueryObligations({
    variables: {
      date: date.format('YYYY-MM-DD'),
    },
  });
  const { absences } = useQueryAbsences({
    variables: {
      date: date.format('YYYY-MM-DD'),
    },
  });
  const { categories } = useQueryCategories();
  const { students } = useQueryStudents();
  // History
  const [isHistoryOpen, setIsHistoryOpen] = useState<any>(undefined);
  const handleHistoryOpen = useCallback((obligation: any) => {
    setIsHistoryOpen(obligation);
  }, []);
  const handleHistoryClose = useCallback(() => {
    setIsHistoryOpen(undefined);
  }, []);
  // Table
  const table = useFormatTable({
    obligations,
    absences,
    date,
    categories,
    category,
    student,
  });
  // Render
  return (
    <>
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Obligations</Typography.Title>
        </Col>
      </Row>
      <Divider />
      <Space wrap>
        <Button onClick={() => setDate(dayjs())}>Aujourd'hui</Button>
        <Space>
          <Button
            onClick={() => setDate(date.subtract(1, 'week'))}
            icon={<LeftOutlined />}
          />
          <DatePicker
            picker="week"
            value={date}
            onChange={(v) => setDate(v)}
            format={(value) =>
              `${dayjs(value).startOf('week').format('DD MMMM YYYY')} ~ ${dayjs(
                value,
              )
                .endOf('week')
                .format('DD MMMM YYYY')}`
            }
          />
          <Button
            onClick={() => setDate(date.add(1, 'week'))}
            icon={<RightOutlined />}
          />
        </Space>
        <Select
          style={{ width: 200 }}
          allowClear
          options={[
            ...(categories || []).map((category: any) => ({
              label: category.name,
              value: category.id,
            })),
            {
              label: 'ABSENCE',
              value: 'ABSENCE',
            },
          ]}
          value={category}
          onChange={(v) => setCategory(v)}
          placeholder="Filter par catégorie"
        />
        <Select
          style={{ width: 200 }}
          allowClear
          options={[
            { label: 'VIDE', value: null },
            ...(students || []).map((student: any) => ({
              label: `${student.firstName} ${student.lastName}`,
              value: student.id,
            })),
          ]}
          value={student}
          onChange={(v) => setStudent(v)}
          placeholder="Filter par étudiant"
        />
      </Space>
      <Divider />
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <FormatTable
          table={table}
          as={Slot}
          handleHistory={handleHistoryOpen}
        />
      )}
      {/* History */}
      <Modal
        title="Historique"
        open={!!isHistoryOpen}
        destroyOnClose
        footer={null}
        onCancel={handleHistoryClose}
        width={800}
      >
        <History slot={isHistoryOpen} />
      </Modal>
    </>
  );
};

export default ObligationsViewer;
