import { CheckOutlined, StopOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  List,
  Popconfirm,
  Row,
  Typography,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import {
  useMutationObligationTakeoverAccept,
  useMutationObligationTakeoverCancel,
  useMutationObligationTakeoverReject,
} from '../../gql/mutations/obligations';
import { useQueryMe } from '../../gql/queries/me';
import { useQueryObligationsTakeover } from '../../gql/queries/obligations';

const TakeoversStudent = () => {
  // List
  const { me } = useQueryMe();
  const { isLoading, obligationsTakeover, refetch } =
    useQueryObligationsTakeover();
  const i = useMemo(
    () =>
      (obligationsTakeover || []).filter(
        (slot: any) => slot.takeoverAccountId === me?.id,
      ),
    [obligationsTakeover, me],
  );
  const o = useMemo(
    () =>
      (obligationsTakeover || []).filter(
        (slot: any) => slot.accountId === me?.id,
      ),
    [obligationsTakeover, me],
  );
  // Takeover Accept
  const mutationObligationTakeoverAccept =
    useMutationObligationTakeoverAccept();
  const handleTakeoverAccept = useCallback(
    async (obligation: any) => {
      try {
        await mutationObligationTakeoverAccept({
          id: obligation.id,
        });
        await refetch();
        message.success('La demande de transfer a été acceptée.');
      } catch (err) {
        message.error("Une erreur s'est produite. Veuillez réessayer.");
      }
    },
    [mutationObligationTakeoverAccept, refetch],
  );
  // Takeover Reject
  const mutationObligationTakeoverReject =
    useMutationObligationTakeoverReject();
  const handleTakeoverReject = useCallback(
    async (obligation: any) => {
      try {
        await mutationObligationTakeoverReject({
          id: obligation.id,
        });
        await refetch();
        message.success('La demande de transfer a été rejetée.');
      } catch (err) {
        message.error("Une erreur s'est produite. Veuillez réessayer.");
      }
    },
    [mutationObligationTakeoverReject, refetch],
  );
  // Takeover Cancel
  const mutationObligationTakeoverCancel =
    useMutationObligationTakeoverCancel();
  const handleTakeoverCancel = useCallback(
    async (obligation: any) => {
      try {
        await mutationObligationTakeoverCancel({
          id: obligation.id,
        });
        await refetch();
        message.success('La demande de transfer a été supprimée.');
      } catch (err) {
        message.error("Une erreur s'est produite. Veuillez réessayer.");
      }
    },
    [mutationObligationTakeoverCancel, refetch],
  );
  // Render
  return (
    <>
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Transferts</Typography.Title>
        </Col>
      </Row>
      <Divider />
      <Typography.Title level={2}>Demandées envoyées</Typography.Title>
      <List
        loading={isLoading}
        itemLayout="horizontal"
        dataSource={o}
        renderItem={(item: any) => (
          <List.Item
            actions={[
              <Popconfirm
                okText="Oui"
                onConfirm={() => handleTakeoverCancel(item)}
                title="Dernière chance. Êtes-vous sûr ?"
              >
                <Button size="small" icon={<StopOutlined />} danger>
                  Annuler
                </Button>
              </Popconfirm>,
            ]}
          >
            <div>
              Vous avez demandé à{' '}
              <b>
                {item.takeoverAccount?.firstName}{' '}
                {item.takeoverAccount?.lastName}
              </b>{' '}
              de reprendre votre obligation du{' '}
              <b>
                {dayjs(item.date).format('DD MMM YYYY')} {item.moment}
              </b>{' '}
              en <b>{item.category?.name}</b>
            </div>
          </List.Item>
        )}
      />
      <Divider />
      <Typography.Title level={2}>Demandées reçues</Typography.Title>
      <List
        loading={isLoading}
        itemLayout="horizontal"
        dataSource={i}
        renderItem={(item: any) => (
          <List.Item
            actions={[
              <Popconfirm
                okText="Oui"
                onConfirm={() => handleTakeoverAccept(item)}
                title="Dernière chance. Êtes-vous sûr ?"
              >
                <Button size="small" icon={<CheckOutlined />} type="primary">
                  Accepter
                </Button>
              </Popconfirm>,
              <Popconfirm
                okText="Oui"
                onConfirm={() => handleTakeoverReject(item)}
                title="Dernière chance. Êtes-vous sûr ?"
              >
                <Button size="small" icon={<StopOutlined />} danger>
                  Refuser
                </Button>
              </Popconfirm>,
            ]}
          >
            <div>
              <b>
                {item.account?.firstName} {item.account?.lastName}
              </b>{' '}
              vous demande de reprendre son obligation du{' '}
              <b>
                {dayjs(item.date).format('DD MMM YYYY')} {item.moment}
              </b>{' '}
              en <b>{item.category?.name}</b>
            </div>
          </List.Item>
        )}
      />
    </>
  );
};

export default TakeoversStudent;
