import { gql, useQuery } from '@apollo/client';
import { FRAG_CATEGORY } from './fragments';

const QUERY_CATEGORIES = gql`
  query categories {
    categories {
      ...CategoryData
    }
  }
  ${FRAG_CATEGORY}
`;

export const useQueryCategories = (options = {}) => {
  const { data, loading, ...props } = useQuery(QUERY_CATEGORIES, options);
  return {
    categories: data?.categories,
    isLoading: loading || !data,
    ...props,
  };
};

const QUERY_CATEGORY = gql`
  query category($id: ID!) {
    category(id: $id) {
      ...CategoryData
    }
  }
  ${FRAG_CATEGORY}
`;

export const useQueryCategory = (options = {}) => {
  const { data, loading, ...props } = useQuery(QUERY_CATEGORY, options);
  return {
    category: data?.category,
    isLoading: loading || !data,
    ...props,
  };
};
