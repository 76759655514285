import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SendOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from 'antd';
import { useCallback, useState } from 'react';
import FormSheetRenderer2 from '../../components/FormSheetRenderer2';
import {
  useMutationSheetTemplateCreate,
  useMutationSheetTemplateDelete,
  useMutationSheetTemplateUpdate,
} from '../../gql/mutations/sheettemplates';
import { useQueryDepartments } from '../../gql/queries/departments';
import { useQuerySheetTemplates } from '../../gql/queries/sheettemplates';
import { searchText, sortText } from '../../helpers/table';

const Upsert = ({
  sheetTemplate = {},
  departments = [],
  handleSubmit,
  loading,
  error,
}: any) => {
  const [form] = Form.useForm();
  const content = Form.useWatch('content', form);
  const content2 = Form.useWatch('content2', form);
  return (
    <>
      <Form
        form={form}
        initialValues={{
          id: sheetTemplate.id || undefined,
          departmentId: sheetTemplate.departmentId || null,
          name: sheetTemplate.name || '',
          referenceType: sheetTemplate.referenceType || '',
          content: sheetTemplate.content || '',
          content2: sheetTemplate.content2 || '',
          instructions: sheetTemplate.instructions || '',
          displayOrder: sheetTemplate.displayOrder || 0,
          computeGrade: sheetTemplate.computeGrade || false,
          gradeNotes: sheetTemplate.gradeNotes || [18, 14, 10, 6, 2],
        }}
        layout="vertical"
        onFinish={handleSubmit}
        disabled={loading}
      >
        {error && (
          <Alert
            type="error"
            message="Une erreur est survenue. Veuillez réessayer."
            banner
            className="error"
          />
        )}
        <Form.Item noStyle name="id" />
        <Form.Item
          label="Nom du modèle de fiche"
          name="name"
          rules={[{ required: true, message: 'Veuillez renseigner un nom.' }]}
        >
          <Input placeholder="Modèle 1" />
        </Form.Item>
        <Form.Item
          label="Type de référence"
          name="referenceType"
          rules={[
            {
              required: true,
              message: 'Veuillez renseigner un type de référence.',
            },
          ]}
        >
          <Select
            options={[
              { label: 'Aucune', value: 'none' },
              { label: 'Numéro administratif', value: 'numero_admin' },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Discipline à laquelle le modèle de fiche est rattaché"
          name="departmentId"
          rules={[
            { required: true, message: 'Veuillez renseigner une discipline.' },
          ]}
        >
          <Select
            allowClear
            showSearch
            filterOption={(v, opt: any) =>
              opt.label.toLowerCase().includes(v.toLowerCase())
            }
            options={departments.map((department: any) => ({
              label: department.name,
              value: department.id,
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Ordre d'affichage dans l'interface (0 = plus haut)"
          name="displayOrder"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner un ordre d'affichage.",
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Instructions supplémentaires (notation, références, etc.)"
          name="instructions"
        >
          <Input.TextArea autoSize />
        </Form.Item>
        <Form.Item name="computeGrade" valuePropName="checked">
          <Checkbox>Calculer une note automatiquement</Checkbox>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) =>
            getFieldValue('computeGrade') && (
              <Row gutter={8}>
                <Col span={4}>
                  <Form.Item name={['gradeNotes', 0]} label="A">
                    <InputNumber />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={['gradeNotes', 1]} label="B">
                    <InputNumber />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={['gradeNotes', 2]} label="C">
                    <InputNumber />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={['gradeNotes', 3]} label="D">
                    <InputNumber />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={['gradeNotes', 4]} label="E">
                    <InputNumber />
                  </Form.Item>
                </Col>
              </Row>
            )
          }
        </Form.Item>
        <Form.Item
          label="Données à remplir par l'étudiant"
          name="content"
          rules={[
            { required: true, message: 'Veuillez renseigner un contenu.' },
          ]}
        >
          <Input.TextArea autoSize />
        </Form.Item>
        <Form.Item
          label="Données à remplir par l'assistant"
          name="content2"
          rules={[
            { required: true, message: 'Veuillez renseigner un contenu.' },
          ]}
        >
          <Input.TextArea autoSize />
        </Form.Item>
        <Button htmlType="submit" icon={<SendOutlined />} type="primary">
          Envoyer
        </Button>
      </Form>
      <Divider />
      <Form layout="vertical">
        <div className="sheet">
          <Typography.Title level={4}>
            Aperçu de la fiche à remplir par l'étudiant
          </Typography.Title>
          <FormSheetRenderer2 sheetTemplate={content} />
          <Divider />
          <Typography.Title level={4}>
            Aperçu de la fiche à remplir par l'assistant
          </Typography.Title>
          <FormSheetRenderer2 sheetTemplate={content2} />
        </div>
      </Form>
    </>
  );
};

const SheetTemplatesList = () => {
  // List
  const { isLoading, sheetTemplates, refetch } = useQuerySheetTemplates();
  const { departments } = useQueryDepartments();
  // Create
  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(undefined);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const handleCreateOpen = useCallback(() => {
    setCreateLoading(false);
    setCreateError(undefined);
    setIsCreateOpen(true);
  }, []);
  const handleCreateClose = useCallback(() => {
    setCreateLoading(false);
    setCreateError(undefined);
    setIsCreateOpen(false);
  }, []);
  const mutationSheetTemplateCreate = useMutationSheetTemplateCreate();
  const handleCreate = useCallback(
    async (values: any) => {
      try {
        setCreateLoading(true);
        setCreateError(undefined);
        await mutationSheetTemplateCreate(values);
        await refetch();
        handleCreateClose();
        message.success('Le modèle de fiche a été créé.');
      } catch (err: any) {
        setCreateError(err.message);
      } finally {
        setCreateLoading(false);
      }
    },
    [handleCreateClose, mutationSheetTemplateCreate, refetch],
  );
  // Update
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState(undefined);
  const [isUpdateOpen, setIsUpdateOpen] = useState(undefined);
  const handleUpdateOpen = useCallback((sheetTemplate: any) => {
    setUpdateLoading(false);
    setUpdateError(undefined);
    setIsUpdateOpen(sheetTemplate);
  }, []);
  const handleUpdateClose = useCallback(() => {
    setUpdateLoading(false);
    setUpdateError(undefined);
    setIsUpdateOpen(undefined);
  }, []);
  const mutationSheetTemplateUpdate = useMutationSheetTemplateUpdate();
  const handleUpdate = useCallback(
    async (values: any) => {
      try {
        setUpdateLoading(true);
        setUpdateError(undefined);
        await mutationSheetTemplateUpdate(values);
        await refetch();
        handleUpdateClose();
        message.success('Le modèle de fiche a été mis à jour.');
      } catch (err: any) {
        console.log(err);
        setUpdateError(err.message);
      } finally {
        setUpdateLoading(false);
      }
    },
    [handleUpdateClose, mutationSheetTemplateUpdate, refetch],
  );
  // Delete
  const mutationSheetTemplateDelete = useMutationSheetTemplateDelete();
  const handleDelete = useCallback(
    async (sheetTemplate: any) => {
      try {
        await mutationSheetTemplateDelete({
          id: sheetTemplate.id,
        });
        await refetch();
        message.success('Le modèle de fiche a été supprimé.');
      } catch (err) {
        message.error("Une erreur s'est produite. Veuillez réessayer.");
      }
    },
    [mutationSheetTemplateDelete, refetch],
  );
  // Columns
  const columns = [
    {
      title: 'Nom du modèle de fiche',
      dataIndex: 'name',
      key: 'name',
      ...sortText('name'),
      ...searchText('name'),
    },
    {
      title: 'Nom de la discipline rattachée',
      key: 'department',
      render: ({ department }: any) => (
        <Tag color={department.color}>{department.name}</Tag>
      ),
    },
    {
      title: "Ordre d'affichage",
      dataIndex: 'displayOrder',
      key: 'displayOrder',
      ...sortText('displayOrder'),
      ...searchText('displayOrder'),
    },
    {
      title: '',
      key: 'options',
      width: 100,
      render: (sheetTemplate: any) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleUpdateOpen(sheetTemplate)}
          />
          {/* <Popconfirm
            okText="Oui"
            onConfirm={() => handleDelete(sheetTemplate)}
            title="Dernière chance. Êtes-vous sûr ?"
          >
            <Button icon={<DeleteOutlined />} danger />
          </Popconfirm> */}
        </Space>
      ),
    },
  ];
  // Render
  return (
    <>
      <Row gutter={16} align="middle">
        <Col flex="auto">
          <Typography.Title>Modèles de fiches</Typography.Title>
        </Col>
        <Col>
          <Space wrap>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={handleCreateOpen}
            >
              Ajouter un modèle de fiche
            </Button>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Table
        dataSource={sheetTemplates}
        columns={columns}
        loading={isLoading}
        pagination={false}
        rowKey="id"
        bordered
      />
      {/* Create */}
      <Modal
        title="Ajouter un modèle de fiche"
        open={isCreateOpen}
        destroyOnClose
        footer={null}
        onCancel={handleCreateClose}
        width={2000}
      >
        <Upsert
          departments={departments}
          handleSubmit={handleCreate}
          loading={createLoading}
          error={createError}
        />
      </Modal>
      {/* Update */}
      <Modal
        title="Modifier un modèle de fiche"
        open={!!isUpdateOpen}
        destroyOnClose
        footer={null}
        onCancel={handleUpdateClose}
        width={2000}
      >
        <Upsert
          sheetTemplate={isUpdateOpen}
          departments={departments}
          handleSubmit={handleUpdate}
          loading={updateLoading}
          error={updateError}
        />
      </Modal>
    </>
  );
};

export default SheetTemplatesList;
