import { gql, useQuery } from '@apollo/client';
import { FRAG_ACCOUNT, FRAG_CATEGORY, FRAG_OBLIATION } from './fragments';

const QUERY_OBLIGATIONS = gql`
  query Obligations($date: String!) {
    obligations(date: $date) {
      ...ObligationData
      category {
        ...CategoryData
      }
      account {
        ...AccountData
      }
      takeoverAccount {
        ...AccountData
      }
    }
  }
  ${FRAG_OBLIATION}
  ${FRAG_ACCOUNT}
  ${FRAG_CATEGORY}
`;

export const useQueryObligations = (options = {}) => {
  const { data, loading, ...props } = useQuery(QUERY_OBLIGATIONS, options);
  return {
    obligations: data?.obligations,
    isLoading: loading || !data,
    ...props,
  };
};

const QUERY_OBLIGATION = gql`
  query Obligation($id: ID!) {
    obligation(id: $id) {
      ...ObligationData
      category {
        ...CategoryData
      }
      account {
        ...AccountData
      }
      takeoverAccount {
        ...AccountData
      }
    }
  }
  ${FRAG_OBLIATION}
  ${FRAG_ACCOUNT}
  ${FRAG_CATEGORY}
`;

export const useQueryObligation = (options = {}) => {
  const { data, loading, ...props } = useQuery(QUERY_OBLIGATION, options);
  return {
    obligation: data?.obligation,
    isLoading: loading || !data,
    ...props,
  };
};

const QUERY_OBLIGATIONS_TAKEOVER = gql`
  query obligationsTakeover {
    obligationsTakeover {
      ...ObligationData
      category {
        ...CategoryData
      }
      account {
        ...AccountData
      }
      takeoverAccount {
        ...AccountData
      }
    }
  }
  ${FRAG_OBLIATION}
  ${FRAG_ACCOUNT}
  ${FRAG_CATEGORY}
`;

export const useQueryObligationsTakeover = (options = {}) => {
  const { data, loading, ...props } = useQuery(
    QUERY_OBLIGATIONS_TAKEOVER,
    options,
  );
  return {
    obligationsTakeover: data?.obligationsTakeover,
    isLoading: loading || !data,
    ...props,
  };
};

const QUERY_OBLIGATIONS_STATISTICS_ALL = gql`
  {
    obligationStatisticsAll
  }
`;

export const useQueryObligationsStatisticsAll = (options = {}) => {
  const { data, loading, ...props } = useQuery(
    QUERY_OBLIGATIONS_STATISTICS_ALL,
    options,
  );
  return {
    obligationStatisticsAll: data?.obligationStatisticsAll,
    isLoading: loading || !data,
    ...props,
  };
};

const QUERY_OBLIGATIONS_STATISTICS_PAST = gql`
  {
    obligationStatisticsPast
  }
`;

export const useQueryObligationsStatisticsPast = (options = {}) => {
  const { data, loading, ...props } = useQuery(
    QUERY_OBLIGATIONS_STATISTICS_PAST,
    options,
  );
  return {
    obligationStatisticsPast: data?.obligationStatisticsPast,
    isLoading: loading || !data,
    ...props,
  };
};

const QUERY_OBLIGATIONS_STATISTICS_ABSENCE = gql`
  {
    obligationStatisticsAbsence
  }
`;

export const useQueryObligationsStatisticsAbsence = (options = {}) => {
  const { data, loading, ...props } = useQuery(
    QUERY_OBLIGATIONS_STATISTICS_ABSENCE,
    options,
  );
  return {
    obligationStatisticsAbsence: data?.obligationStatisticsAbsence,
    isLoading: loading || !data,
    ...props,
  };
};

const QUERY_OBLIGATIONS_STATISTICS_TAKEOVERS = gql`
  {
    obligationStatisticsTakeovers
  }
`;

export const useQueryObligationsStatisticsTakeovers = (options = {}) => {
  const { data, loading, ...props } = useQuery(
    QUERY_OBLIGATIONS_STATISTICS_TAKEOVERS,
    options,
  );
  return {
    obligationStatisticsTakeovers: data?.obligationStatisticsTakeovers,
    isLoading: loading || !data,
    ...props,
  };
};
